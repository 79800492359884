import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem } from "@material-ui/core";
import { memo } from "react";
import { usePaginationMenuItemStyles } from "./PaginationMenuItem.styles";

const PaginationMenuItem = ({ isSelected, label, onClick }) => {
  const paginationMenuItemClasses = usePaginationMenuItemStyles({
    isSelected,
  });
  return (
    <MenuItem button dense selected={isSelected} onClick={onClick}>
      {isSelected && (
        <FontAwesomeIcon
          fixedWidth
          icon={faCheck}
          className={paginationMenuItemClasses.menuItemIcon}
        />
      )}
      <div className={paginationMenuItemClasses.menuItemLabel}>
        <div className={paginationMenuItemClasses.menuItemLabelTitle}>
          {label} items
        </div>
      </div>
    </MenuItem>
  );
};

export default memo(PaginationMenuItem);
