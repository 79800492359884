import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import { usePopupState } from "material-ui-popup-state/hooks";
import { memo } from "react";
import { useActionsStyles } from "./Actions.styles";

const Actions = ({
  inline = false,
  primaryActionsAsButtons = false,
  actions = [],
  contextActions = [],
}) => {
  const actionsClasses = useActionsStyles();
  const { anchorEl, isOpen, open, close } = usePopupState({
    variant: "popover",
    popupId: "actions-menu",
  });
  const menuOpen = (event) => {
    event.stopPropagation();
    open(event.currentTarget);
  };
  const menuClose = (event) => {
    event.stopPropagation();
    close();
  };
  const menuItemClick = (handle, event) => {
    event.stopPropagation();
    handle();
    close();
  };
  const buttonClick = (handle, event) => {
    event.stopPropagation();
    handle();
  };
  const primaryActions = actions.filter(
    (action) => action.isPrimary && action.isEnabled
  );
  const moreActions = actions.filter(
    (action) => !action.isPrimary && action.isEnabled
  );
  const renderMenuItem = ({ icon, title, handle, isRed }, index) => {
    return (
      <MenuItem
        key={index}
        button
        dense
        className={isRed ? actionsClasses.red : null}
        onClick={menuItemClick.bind(this, handle)}
      >
        {icon}
        <Box ml={1}>{title}</Box>
      </MenuItem>
    );
  };
  return (
    <Box>
      {primaryActions.length > 0 &&
        primaryActions.map((action, index) => {
          const { icon, title, handle, isRed } = action;
          return primaryActionsAsButtons ? (
            <Button
              key={index}
              startIcon={icon}
              color="inherit"
              onClick={buttonClick.bind(this, handle)}
              className={clsx({
                [actionsClasses.inline]: inline,
                [actionsClasses.red]: isRed,
              })}
            >
              {title}
            </Button>
          ) : (
            <Tooltip key={index} title={title} placement="top">
              <IconButton
                color="inherit"
                onClick={buttonClick.bind(this, handle)}
                className={clsx({
                  [actionsClasses.inline]: inline,
                  [actionsClasses.red]: isRed,
                })}
                TouchRippleProps={{
                  center: !inline,
                }}
              >
                {icon}
              </IconButton>
            </Tooltip>
          );
        })}
      {(moreActions.length > 0 || contextActions.length > 0) && (
        <>
          <Tooltip title="More" placement="top">
            <IconButton
              color="inherit"
              onClick={menuOpen}
              className={clsx({ [actionsClasses.inline]: inline })}
              TouchRippleProps={{
                center: !inline,
              }}
            >
              <FontAwesomeIcon fixedWidth icon={faEllipsisV} />
            </IconButton>
          </Tooltip>
          <Popover
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            open={isOpen}
            onClose={menuClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MenuList>{moreActions.map(renderMenuItem)}</MenuList>
            {moreActions.length > 0 && contextActions.length > 0 && <Divider />}
            {contextActions.length > 0 && (
              <MenuList>{contextActions.map(renderMenuItem)}</MenuList>
            )}
          </Popover>
        </>
      )}
    </Box>
  );
};

export default memo(Actions);
