import {
  ApolloWrapper,
  Auth0Wrapper,
  MainWrapper,
  OptimizelyWrapper,
  PagesWrapper,
  RouterWrapper,
  SnackbarWrapper,
  StylesWrapper,
  WorkersWrapper,
} from "wrappers";

const App = () => {
  return (
    <RouterWrapper>
      <MainWrapper>
        <Auth0Wrapper>
          <OptimizelyWrapper>
            <ApolloWrapper>
              <WorkersWrapper>
                <StylesWrapper>
                  <SnackbarWrapper>
                    <PagesWrapper />
                  </SnackbarWrapper>
                </StylesWrapper>
              </WorkersWrapper>
            </ApolloWrapper>
          </OptimizelyWrapper>
        </Auth0Wrapper>
      </MainWrapper>
    </RouterWrapper>
  );
};

export default App;
