import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { DataRoomActions, DataRoomMeta, Name } from "legacy/components";
import { memo } from "react";
import { useBlockStyles } from "styles/block";

const DataRoomBlock = ({
  dataRoom,
  className,
  setCondition = () => {},
  mergeCondition = () => {},
  withMeta = true,
  withTags = true,
}) => {
  const { id, dataRoomId, name } = dataRoom || {};
  const blockClasses = useBlockStyles();
  return (
    <div className={clsx(blockClasses.wrapper, className)}>
      <div className={blockClasses.nameMetaActionsWrapper}>
        <div className={blockClasses.nameActionsWrapper}>
          <Typography
            variant="body1"
            component="div"
            className={blockClasses.nameWrapper}
          >
            <Name
              name={name || "DataRoom"}
              id={dataRoomId}
              href={`/dataRooms/${dataRoomId}`}
            />
          </Typography>
          <div className={blockClasses.actionsWrapper}>
            <DataRoomActions id={id} dataRoomId={dataRoomId} inline />
          </div>
        </div>
        {(withTags || withMeta) && (
          <DataRoomMeta
            dataRoom={dataRoom}
            withTags={withTags}
            withMeta={withMeta}
          />
        )}
      </div>
    </div>
  );
};

export default memo(DataRoomBlock);
