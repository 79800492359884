import { faEnvelopeOpenText } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Slide,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useDialogActionsStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 3, 2, 3),
    justifyContent: "center",
  },
}));

const useButtonStyles = makeStyles((theme) => ({
  root: {
    borderColor: theme.palette.text.disabled,
  },
}));

const useChangePasswordDialogStyles = makeStyles((theme) => ({
  iconTitleWrapper: {
    padding: theme.spacing(3, 3, 2, 3),
  },
  iconWrapper: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  icon: {
    color: grey["400"],
  },
  title: {
    lineHeight: 1.25,
  },
  dialogContent: {
    padding: theme.spacing(0, 3, 0, 3),
  },
}));

const ChangePasswordDialog = ({ open, onClose }) => {
  const changePasswordDialogClasses = useChangePasswordDialogStyles();
  const dialogActionsClasses = useDialogActionsStyles();
  const buttonClasses = useButtonStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Slide}
      TransitionProps={{ direction: "up" }}
    >
      <Box className={changePasswordDialogClasses.iconTitleWrapper}>
        <Box className={changePasswordDialogClasses.iconWrapper}>
          <FontAwesomeIcon
            fixedWidth
            icon={faEnvelopeOpenText}
            size="5x"
            className={changePasswordDialogClasses.icon}
          />
        </Box>
        <Typography
          variant="h6"
          color="textPrimary"
          align="center"
          className={changePasswordDialogClasses.title}
        >
          <strong>Please check your email</strong>
        </Typography>
      </Box>
      <DialogContent className={changePasswordDialogClasses.dialogContent}>
        <Typography variant="body2" align="center" component="div">
          Please check your email address for further
          <br />
          instructions on how to reset your password.
        </Typography>
      </DialogContent>
      <DialogActions classes={dialogActionsClasses}>
        <Button
          classes={buttonClasses}
          color="inherit"
          variant="outlined"
          onClick={onClose}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
