import { useQuery } from "@apollo/client";
import { faSync } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  IconButton,
  Pagination,
  PaginationItem,
  Tooltip,
} from "@material-ui/core";
import { useSelections } from "ahooks";
import {
  DataSearch,
  Loading,
  OrderByMenu,
  PageSizeMenu,
  Select,
} from "legacy/components";
import { useCallback, useEffect, useState } from "react";
import {
  useDataStyles,
  usePaginationItemStyles,
  usePaginationStyles,
} from "./Data.styles";

const Data = ({
  query,
  variables: propVariables = {},
  filterBy = [],
  orderByOptions = [],
  pageSize: pageSizeProp = 10,
  dataKey,
  dataIdKey,
  transformData = (data) => data,
  Actions,
  BulkActions,
  withSearch = false,
  withFilter = false,
  withSelections = false,
  withMultiselections = false,
  withActions = true,
  DataView,
  onSelect = () => {},
  ...rest
}) => {
  const dataContainerClasses = useDataStyles({
    stickyControls: true,
  });
  const paginationClasses = usePaginationStyles();
  const paginationItemClasses = usePaginationItemStyles();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeProp);
  const [orderBy, setOrderBy] = useState(
    orderByOptions.find((orderByOption) => orderByOption.default)?.orderBy ||
      orderByOptions[0]?.orderBy ||
      propVariables.orderBy ||
      []
  );
  const [condition, setCondition] = useState({});
  const mergeCondition = useCallback(
    (newCondition) => setCondition({ ...condition, ...newCondition }),
    [condition]
  );
  // const filter = search
  //   ? {
  //       or: filterBy.map((key) => ({ [key]: { includesInsensitive: search } })),
  //     }
  //   : undefined;
  const offset = (page - 1) * pageSize;
  const { data, loading, refetch, fetchMore } = useQuery(query, {
    variables: {
      ...propVariables,
      condition: {
        ...propVariables.condition,
        ...condition,
      },
      // filter,
      first: pageSize,
      offset,
      orderBy,
    },
  });
  const dataTotalCount = data?.[dataKey]?.totalCount || 0;
  const dataNodesCount = data?.[dataKey]?.nodes?.length || 0;
  const nodes = data?.[dataKey]?.nodes || [];
  const usedSelections = useSelections(
    nodes.filter(Boolean).map((node) => node[dataIdKey])
  );
  const SelectWithUsedSelections =
    withSelections || withMultiselections
      ? (props) => (
          <Select
            withMultiselections={withMultiselections}
            withSelections={withSelections}
            usedSelections={usedSelections}
            {...props}
          />
        )
      : null;
  const { selected } = usedSelections;
  useEffect(() => onSelect(selected), [selected, onSelect]);
  const shouldShowDataControls =
    Actions || BulkActions || withSearch || withFilter;
  const onBulkActionSucceeded = () => console.log("Clear selection");
  const onBulkActionFailed = () => console.log("Show error");
  return (
    <Box className={dataContainerClasses.dataContainer}>
      {shouldShowDataControls ? (
        <Box className={dataContainerClasses.dataControls}>
          {BulkActions && selected.length > 0 ? (
            <Box mr={1} display="flex" alignItems="center">
              <Box mr={1} className={dataContainerClasses.dataActionsAngle}>
                With {selected.length} selected
              </Box>
              <BulkActions
                ids={selected}
                onBulkActionSucceeded={onBulkActionSucceeded}
                onBulkActionFailed={onBulkActionFailed}
              />
            </Box>
          ) : Actions ||
            withFilter ||
            withSearch ||
            orderByOptions.length > 0 ? (
            <Box flex="1" display="flex" justifyContent="space-between">
              {Actions && (
                <Box>
                  <Actions />
                </Box>
              )}
              <Box
                flex="0"
                flexBasis={Actions ? "auto" : "100%"}
                display="flex"
                justifyContent="space-between"
              >
                {withSearch && (
                  <DataSearch
                    value={search}
                    onChange={(search) => setSearch(search)}
                  />
                )}
                {orderByOptions.length > 0 && (
                  <Box className={dataContainerClasses.dataSort}>
                    <OrderByMenu
                      orderBy={orderBy}
                      orderByOptions={orderByOptions}
                      onChange={(value) => setOrderBy(value)}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          ) : null}
        </Box>
      ) : null}
      <Box className={dataContainerClasses.data}>
        <Loading loading={loading} />
        {dataTotalCount > 0 && dataNodesCount > 0 ? (
          <Box className={dataContainerClasses.dataViewport}>
            <DataView
              {...{ [dataKey]: transformData(data)[dataKey] }}
              nodes={transformData(data)[dataKey]?.nodes || []}
              data={transformData(data)}
              dataKey={dataKey}
              dataIdKey={dataIdKey}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              setCondition={setCondition}
              mergeCondition={mergeCondition}
              fetchMore={() =>
                fetchMore({
                  variables: {
                    offset: dataNodesCount,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return {
                      [dataKey]: {
                        ...fetchMoreResult[dataKey],
                        nodes: [
                          ...prev[dataKey].nodes,
                          ...fetchMoreResult[dataKey].nodes,
                        ],
                      },
                    };
                  },
                })
              }
              Select={SelectWithUsedSelections}
              withSelections={withSelections}
              withMultiselections={withMultiselections}
              withActions={withActions}
              {...rest}
            />
            {dataTotalCount > pageSize && selected.length === 0 && (
              <Box className={dataContainerClasses.dataPagination}>
                <Box>
                  <Tooltip title={"Refresh"} placement="top">
                    <IconButton size="small" onClick={() => refetch()}>
                      <FontAwesomeIcon fixedWidth icon={faSync} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box display="flex" alignItems="center">
                    <PageSizeMenu
                      pageSize={pageSize}
                      onChange={(value) => setPageSize(value)}
                    />
                    <strong>
                      ({offset + 1}–{offset + pageSize})
                    </strong>
                    <Box ml={1} mr={1}>
                      of
                    </Box>
                    <strong>{dataTotalCount}</strong>
                  </Box>
                  <Pagination
                    count={Math.ceil(dataTotalCount / pageSize)}
                    page={page}
                    shape="rounded"
                    showFirstButton
                    showLastButton
                    renderItem={(item) => (
                      <PaginationItem
                        classes={paginationItemClasses}
                        {...item}
                      />
                    )}
                    onChange={(event, page) => setPage(page)}
                    classes={paginationClasses}
                  />
                </Box>
              </Box>
            )}
          </Box>
        ) : /* filter === undefined ? null : */ null}
      </Box>
    </Box>
  );
};

export default Data;
