import { makeStyles } from "@material-ui/core/styles";

export const useTableSortLabelStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 500,
    marginRight: theme.spacing(2),
    whiteSpace: "nowrap",
    "&:last-child": {
      marginRight: theme.spacing(-0.5),
    },
  },
  icon: {
    fontSize: "1rem",
  },
}));
