import { Avatar, Box } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { DataRoomActions } from "legacy/components";

const DataRoomsPanels = ({ nodes = [] }) => {
  return (
    <Box>
      {nodes.length > 0
        ? nodes.map(({ name }, index) => (
            <Box
              key={index}
              display="flex"
              borderRadius="4px"
              border="1px solid #bbbbbb"
              padding="4px"
            >
              <Box flex="35%">{name}</Box>
              <Box flex="25%">
                <Avatar
                  alt="Remy Sharp"
                  src="https://next.material-ui.com/static/images/avatar/1.jpg"
                />
              </Box>
              <Box flex="25%">
                <AvatarGroup max={4}>
                  <Avatar
                    alt="Remy Sharp"
                    src="https://next.material-ui.com/static/images/avatar/1.jpg"
                  />
                  <Avatar
                    alt="Travis Howard"
                    src="https://next.material-ui.com/static/images/avatar/2.jpg"
                  />
                  <Avatar
                    alt="Cindy Baker"
                    src="https://next.material-ui.com/static/images/avatar/3.jpg"
                  />
                  <Avatar
                    alt="Agnes Walker"
                    src="https://next.material-ui.com/static/images/avatar/4.jpg"
                  />
                  <Avatar
                    alt="Trevor Henderson"
                    src="https://next.material-ui.com/static/images/avatar/5.jpg"
                  />
                </AvatarGroup>
              </Box>
              <Box
                flex="15%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <DataRoomActions />
              </Box>
            </Box>
          ))
        : null}
    </Box>
  );
};

export default DataRoomsPanels;
