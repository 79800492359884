import { Children, memo } from "react";
import { useMetaGroupStyles } from "./MetaGroup.styles";

const MetaGroup = ({ children }) => {
  const metaGroupClasses = useMetaGroupStyles();
  return Children.count(children) ? (
    <div className={metaGroupClasses.metaGroup}>{children}</div>
  ) : null;
};

export default memo(MetaGroup);
