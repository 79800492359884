import { actionType, queryKind } from "constants/index";
import {
  faFileDownload,
  faFileUpload,
  faPlus,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import { useFormikContext } from "formik";
import { Actions } from "components";

const QueriesActions = ({
  id,
  inline = false,
  actions = [],
  filter = () => true,
  pushNewQuery,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const onExportQueriesClick = () => {
    // Note: Remove lowerPercentileFilter, upperPercentileFilter,
    // and rejectionFeedback for the time being
    const content = values.queries.map((query) => {
      if (query.kind === queryKind.NUMERICAL) {
        delete query["lowerPercentileFilter"];
        delete query["upperPercentileFilter"];
        delete query["rejectionFeedback"];
      }
      return query;
    });
    const file = new File(
      [JSON.stringify(content, null, 2)],
      `${values.name} — Queries.json`,
      {
        type: "application/octet-stream;charset=utf-8",
      }
    );
    saveAs(file);
  };
  const onImportQueriesClick = (event) => {
    const input = document.createElement("input");
    input.type = "file";
    input.onchange = (event) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        try {
          // Note: Add lowerPercentileFilter, upperPercentileFilter,
          // and rejectionFeedback if those are missing
          const content = JSON.parse(event.target.result);
          const queries = content.map((query) => {
            if (query.kind === queryKind.NUMERICAL) {
              return {
                lowerPercentileFilter: 0,
                upperPercentileFilter: 1,
                rejectionFeedback: 0,
                ...query,
              };
            } else {
              return query;
            }
          });
          setFieldValue("queries", queries);
        } catch (error) {
          console.error(error);
        }
      };
      const file = event.target.files[0];
      reader.readAsText(file);
      event.target.value = "";
    };
    input.click();
  };
  const onDeleteAllClick = () => {
    setFieldValue("queries", []);
  };
  const allActions = [
    {
      type: actionType.ADD_NEW,
      icon: <FontAwesomeIcon fixedWidth icon={faPlus} />,
      title: "Add",
      handle: pushNewQuery,
      isPrimary: true,
      isEnabled: true,
    },
    {
      type: actionType.IMPORT_FROM_JSON,
      icon: <FontAwesomeIcon fixedWidth icon={faFileUpload} />,
      title: "Import from JSON",
      handle: onImportQueriesClick,
      isPrimary: true,
      isEnabled: true,
    },
    {
      type: actionType.EXPORT_TO_JSON,
      icon: <FontAwesomeIcon fixedWidth icon={faFileDownload} />,
      title: "Export to JSON",
      handle: onExportQueriesClick,
      isPrimary: true,
      isEnabled: true,
    },
    {
      type: actionType.DELETE_ALL,
      icon: <FontAwesomeIcon fixedWidth icon={faTrashAlt} />,
      title: "Delete all",
      handle: onDeleteAllClick,
      isPrimary: true,
      isEnabled: true,
      isRed: true,
    },
  ]
    .concat(actions)
    .filter(typeof filter === "function" ? filter : () => true);
  return <Actions actions={allActions} inline={inline} />;
};

export default QueriesActions;
