import { faSortAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Menu, MenuItem } from "@material-ui/core";
import isEqual from "lodash/isEqual";
import { usePopupState } from "material-ui-popup-state/hooks";

const OrderByMenu = ({
  orderBy: currentOrderBy,
  orderByOptions = [],
  onChange,
}) => {
  const { anchorEl, isOpen, open, close } = usePopupState({
    variant: "popover",
    popupId: "orderby-menu",
  });
  const menuOpen = (event) => open(event.currentTarget);
  const menuClose = () => close();
  const optionClick = (option) => {
    onChange(option);
    close();
  };
  return orderByOptions.length > 0 ? (
    <>
      <Button onClick={menuOpen}>
        <FontAwesomeIcon fixedWidth icon={faSortAlt} />
        {
          orderByOptions.find(({ orderBy }) => isEqual(currentOrderBy, orderBy))
            ?.label
        }
      </Button>
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={menuClose}
      >
        {orderByOptions.map(({ label, orderBy }) => (
          <MenuItem
            key={label}
            selected={isEqual(currentOrderBy, orderBy)}
            onClick={optionClick.bind(null, orderBy)}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : null;
};

export default OrderByMenu;
