import {
  DataRoomsList,
  DataRoomsPanels,
  DataRoomsTable,
} from "legacy/components";

const DataRooms = ({ as, ...rest }) => {
  switch (as) {
    case "panels":
      return <DataRoomsPanels {...rest} />;
    case "list":
      return <DataRoomsList {...rest} />;
    case "table":
    default:
      return <DataRoomsTable {...rest} />;
  }
};

DataRooms.List = (props) => <DataRoomsList {...props} />;
DataRooms.Panels = (props) => <DataRoomsPanels {...props} />;
DataRooms.Table = (props) => <DataRoomsTable {...props} />;

export default DataRooms;
