import { Table, TableBody, TableContainer, TableHead } from "@material-ui/core";
import { useSelections } from "ahooks";
import { ViewSettingsMenu } from "legacy/components";
import { useCustomTableStyles } from "styles/table";

const DataTable = ({
  orderBy = [],
  setOrderBy = () => {},
  setCondition = () => {},
  mergeCondition = () => {},
  nodes = [],
  dataKey,
  dataIdKey,
  selection,
  views = [],
  columns = [],
  selectedViews,
  selectedColumns,
  Select,
  TableHeadRow = () => null,
  TableBodyRow = () => null,
  withActions = true,
  forceWideCells = false,
  ...rest
}) => {
  const customTableClasses = useCustomTableStyles();
  const viewsWithSelections = useSelections(
    views,
    selectedViews ||
      views.filter((view) => view.isSelected).map((view) => view.name)
  );
  viewsWithSelections.all = views;
  const columnsWithSelections = useSelections(
    columns,
    selectedColumns ||
      columns.filter((column) => column.isSelected).map((column) => column.name)
  );
  columnsWithSelections.all = columns;
  const ViewSettingsMenuWithSelections =
    viewsWithSelections.all.filter(({ isDisabled }) => !isDisabled).length >
      0 ||
    columnsWithSelections.all.filter(({ isDisabled }) => !isDisabled).length > 0
      ? (props) => (
          <ViewSettingsMenu
            viewsWithSelections={viewsWithSelections}
            columnsWithSelections={columnsWithSelections}
          />
        )
      : null;
  return (
    <TableContainer
      component="div"
      className={customTableClasses.MuiTableContainer}
    >
      <Table component="div">
        <TableHead component="div">
          <TableHeadRow
            Select={Select}
            selection={selection}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            ViewSettingsMenu={ViewSettingsMenuWithSelections}
            columnsWithSelections={columnsWithSelections}
            forceWideCells={forceWideCells}
            withActions={withActions}
            {...rest}
          />
        </TableHead>
        <TableBody component="div">
          {nodes.length > 0
            ? nodes.map((node) => (
                <TableBodyRow
                  Select={Select}
                  key={node[dataIdKey]}
                  node={node}
                  selection={selection}
                  setCondition={setCondition}
                  mergeCondition={mergeCondition}
                  columnsWithSelections={columnsWithSelections}
                  viewsWithSelections={viewsWithSelections}
                  forceWideCells={forceWideCells}
                  withActions={withActions}
                  {...rest}
                />
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
