import { actionType } from "constants/index";
import { Box, Grid, InputLabel, makeStyles } from "@material-ui/core";
import { FieldArray, useFormikContext } from "formik";
import { useCallback } from "react";
import { Table, TablesActions } from "components";

const useInputLabelStyles = makeStyles((theme) => ({
  asterisk: {
    fontSize: "1.25em",
    lineHeight: "50%",
    color: "red",
  },
}));

const Tables = ({ id, ingestions, answersState, onImportCsvClick }) => {
  const inputLabelClasses = useInputLabelStyles();
  const {
    status: { isEditing, isOwner },
    values: { tables },
    setFieldValue,
  } = useFormikContext();
  const actionsFilter = useCallback(
    (action) =>
      isEditing
        ? true
        : [...(isOwner ? [actionType.EXPORT_TO_JSON] : [])].includes(
            action.type
          ),
    [isEditing, isOwner]
  );
  const defaultTable = {
    name: "",
    sqlCreateStatement: "",
    dataProviders: [],
  };
  return (
    <FieldArray
      name="tables"
      render={({ push, remove }) => (
        <>
          <Box sx={{ pt: 8, pr: 8, pb: 1, pl: 8 }}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <InputLabel
                    color="secondary"
                    shrink
                    required={isEditing}
                    classes={inputLabelClasses}
                  >
                    Tables ({tables.length})
                  </InputLabel>
                  <Box sx={{ mt: -0.5, mb: -0.5 }}>
                    <TablesActions
                      id={id}
                      inline
                      filter={actionsFilter}
                      pushNewTable={push.bind(this, defaultTable)}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              {(tables || []).map((table, tableIndex) => (
                <Table
                  key={tableIndex}
                  table={table}
                  tableIndex={tableIndex}
                  ingestion={ingestions && ingestions[tableIndex]}
                  onDelete={remove}
                  setFieldValue={setFieldValue}
                  onImportCsvClick={onImportCsvClick}
                />
              ))}
            </Grid>
          </Grid>
        </>
      )}
    />
  );
};

export default Tables;
