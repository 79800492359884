import { DEFAULT_REFERER } from "constants/index";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, useLocation } from "react-router-dom";

const PrivateRoute = ({ element }) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation();
  if (isLoading) {
    return null;
  } else {
    if (isAuthenticated) {
      return <Route element={element} />;
    } else {
      loginWithRedirect({
        appState: { referer: location || DEFAULT_REFERER },
      });
      return null;
    }
  }
};

export default PrivateRoute;
