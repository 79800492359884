import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ClickAwayListener, InputBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Id } from "legacy/components";
import { useEffect, useState } from "react";
import { useNameStyles } from "./Name.styles";

const useInputBaseStyles = makeStyles({
  root: {
    color: "inherit",
    fontWeight: "bold",
    fontSize: "inherit",
    lineHeight: "inherit",
  },
  input: {
    padding: 0,
    height: "1.5em !important",
    lineHeight: "1.5em !important",
  },
});

const useCustomButtonStyles = makeStyles((theme) => ({
  root: {
    minWidth: "auto",
    padding: theme.spacing(0.5),
  },
  label: {
    width: "1rem",
    height: "1rem",
  },
}));

const Name = ({
  id,
  href,
  name,
  onChange: onNameChange,
  className,
  showId = true,
}) => {
  const isReadOnly = !(typeof onNameChange === "function");
  const inputBaseClasses = useInputBaseStyles();
  const customButtonClasses = useCustomButtonStyles();
  const nameClasses = useNameStyles({ isReadOnly });
  const [currentName, setCurrentName] = useState(name);
  useEffect(() => {
    setCurrentName(name);
  }, [name]);
  const [isEditing, setIsEditing] = useState(false);
  const onNameClick = (event) => {
    event.stopPropagation();
    setIsEditing(true);
  };
  const onChange = (event) => {
    setCurrentName(event.target.value);
  };
  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      if (currentName !== name) {
        onNameChange(currentName);
      }
      setIsEditing(false);
    } else if (event.key === "Escape") {
      setCurrentName(name);
      setIsEditing(false);
    }
  };
  const onOkayClick = (event) => {
    event.stopPropagation();
    if (currentName !== name) {
      onNameChange(currentName);
    }
    setIsEditing(false);
  };
  const onCancelClick = (event) => {
    event.stopPropagation();
    setCurrentName(name);
    setIsEditing(false);
  };
  return isEditing ? (
    <ClickAwayListener onClickAway={onCancelClick}>
      <div
        className={clsx(
          nameClasses.wrapperInner,
          nameClasses.wrapperEditing,
          className
        )}
      >
        <InputBase
          classes={inputBaseClasses}
          placeholder={name}
          autoFocus
          value={currentName}
          onChange={onChange}
          onKeyDown={onKeyDown}
          className={nameClasses.nameInput}
        />
        <Button classes={customButtonClasses} onClick={onOkayClick}>
          <FontAwesomeIcon fixedWidth icon={faCheck} />
        </Button>
        <Button classes={customButtonClasses} onClick={onCancelClick}>
          <FontAwesomeIcon fixedWidth icon={faTimes} />
        </Button>
      </div>
    </ClickAwayListener>
  ) : (
    <div className={clsx(nameClasses.wrapperInner, className)}>
      <span
        onClick={!isReadOnly ? onNameClick : undefined}
        className={nameClasses.name}
      >
        {currentName}
      </span>
      {id && showId && <Id id={id} href={href} className={nameClasses.id} />}
    </div>
  );
};

export default Name;
