import { faSearch, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, InputBase } from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import debounce from "lodash/debounce";
import { useCallback, useState } from "react";

const useDataSearchStyles = makeStyles((theme) => ({
  wrapper: {
    display: "inline-flex",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.025),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
  },
  searchIcon: {
    width: theme.spacing(4),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const useInputBaseStyles = makeStyles((theme) => ({
  root: {
    color: "inherit",
  },
  input: {
    padding: theme.spacing(1, 1, 1, 4),
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: "0.875em",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
  },
}));

const useClearButtonStyles = makeStyles((theme) => ({
  root: {
    minWidth: "auto",
    height: theme.spacing(3),
    width: theme.spacing(3),
    margin: theme.spacing(0.5),
  },
}));

const DataSearch = ({ className, value, onChange: onValueChange }) => {
  const dataSearchClasses = useDataSearchStyles();
  const inputBaseClasses = useInputBaseStyles();
  const clearButtonClasses = useClearButtonStyles();
  const [currentValue, setCurrentValue] = useState(value);
  const debouncedValueChange = debounce((value) => onValueChange(value), 500);
  const debouncedValueChangeCallback = useCallback(debouncedValueChange, [
    debouncedValueChange,
  ]);
  const onChange = (event) => {
    setCurrentValue(event.target.value);
    debouncedValueChangeCallback(event.target.value);
  };
  const onCancelClick = () => {
    setCurrentValue("");
    onValueChange("");
  };
  return (
    <Box className={clsx(dataSearchClasses.wrapper, className)}>
      <Box className={dataSearchClasses.searchIcon}>
        <FontAwesomeIcon fixedWidth icon={faSearch} />
      </Box>
      <InputBase
        classes={inputBaseClasses}
        placeholder="Search…"
        value={currentValue}
        onChange={onChange}
      />
      {currentValue !== "" && (
        <Button
          tabIndex={-1}
          classes={clearButtonClasses}
          onClick={onCancelClick}
        >
          <FontAwesomeIcon fixedWidth icon={faTimes} />
        </Button>
      )}
    </Box>
  );
};

export default DataSearch;
