import { DataRooms } from "legacy/components";
import { Data } from "legacy/containers";
import { DATA_ROOMS } from "gqls";

const DataRoomsContainer = ({
  filterBy = ["name"],
  dataIdKey = "dataRoomId",
  dataKey = "dataRooms",
  query = DATA_ROOMS,
  variables = {
    orderBy: ["NATURAL"],
  },
  ...rest
}) => (
  <Data
    DataView={DataRooms}
    filterBy={filterBy}
    dataIdKey={dataIdKey}
    dataKey={dataKey}
    query={query}
    variables={variables}
    {...rest}
  />
);

const DataRoomsContainerList = (props) => (
  <DataRoomsContainer {...props} DataView={DataRooms.List} />
);

const DataRoomsContainerSidebar = (props) => (
  <DataRoomsContainer
    {...props}
    DataView={DataRooms.List}
    views={[
      {
        name: "compact",
        label: "Compact",
        isDisabled: true,
        isSelected: true,
      },
      {
        name: "detailed",
        label: "Detailed",
        info: "Includes meta info and tags",
        isDisabled: true,
        isSelected: false,
      },
    ]}
    columns={[
      {
        name: "dataRoom",
        label: "DataRoom",
        isDisabled: true,
        isSelected: true,
      },
      {
        name: "owner",
        label: "Owner",
        isDisabled: true,
        isSelected: false,
      },
      {
        name: "participants",
        label: "Participants",
        isDisabled: true,
        isSelected: false,
      },
    ]}
    withSelections={false}
    withMultiselections={false}
    withActions={false}
  />
);

DataRoomsContainer.List = DataRoomsContainerList;
DataRoomsContainer.Sidebar = DataRoomsContainerSidebar;

export default DataRoomsContainer;
