import { EventsWorker } from "workers";

const WorkersWrapper = ({ children }) => {
  return (
    <>
      <EventsWorker />
      {children}
    </>
  );
};

export default WorkersWrapper;
