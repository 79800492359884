import { makeStyles, TextField } from "@material-ui/core";
import { FastField, useStatus } from "formik";
import { memo } from "react";

const useInputLabelStyles = makeStyles((theme) => ({
  root: {
    transform: "none",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    display: "inline-flex",
  },
  asterisk: {
    color: "red",
    fontSize: "1.25rem",
  },
}));

const useInputStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 0),
  },
  multiline: {
    padding: theme.spacing(0.5, 0),
  },
  input: {
    fontSize: "1rem",
    lineHeight: "1.25rem !important",
    minHeight: "1.25rem !important",
    padding: 0,
  },
}));

const DescriptionField = memo(({ name, ...props }) => {
  const [{ isEditing }] = useStatus();
  const inputClasses = useInputStyles();
  const inputLabelClasses = useInputLabelStyles();
  return (
    <FastField name={name}>
      {({ field, meta: { error, touched } }) => (
        <TextField
          fullWidth
          variant="standard"
          color="secondary"
          label={isEditing ? "Description" : null}
          InputLabelProps={{
            shrink: true,
            classes: inputLabelClasses,
          }}
          InputProps={{
            classes: inputClasses,
            multiline: true,
            disableUnderline: !isEditing,
            readOnly: !isEditing,
            disabled: !isEditing,
          }}
          error={Boolean(error)}
          helperText={error}
          {...field}
          {...props}
        />
      )}
    </FastField>
  );
});

export default DescriptionField;
