import { makeStyles } from "@material-ui/core";

export const usePaginationMenuItemStyles = makeStyles((theme) => ({
  menuItemIcon: {
    margin: theme.spacing(0.375, 0),
  },
  menuItemLabel: {
    marginLeft: (props) =>
      props.isSelected ? theme.spacing(1) : theme.spacing(3),
  },
  menuItemLabelTitle: {
    fontWeight: (props) => (props.isSelected ? 500 : 400),
  },
}));
