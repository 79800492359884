import { useState } from "react";
import { MainProvider } from "contexts";

const SpineWrapper = ({ children }) => {
  const [prefersColorSchemeDark, setPrefersColorSchemeDark] = useState(false);
  return (
    <MainProvider
      value={{
        prefersColorSchemeDark,
        setPrefersColorSchemeDark,
      }}
    >
      {children}
    </MainProvider>
  );
};

export default SpineWrapper;
