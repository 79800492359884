import { faStar } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import {
  // NotificationsDrawer
  DataRoomActions,
  Id,
  // AccountMenu,
  NavigationDrawer,
} from "legacy/components";

const useAvatarGroupStyles = makeStyles((theme) => ({
  avatar: {
    fontSize: "1em",
    fontWeight: "bold",
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
  },
}));

const MainAppBar = ({ dataRoomId }) => {
  const avatarGroupClasses = useAvatarGroupStyles();
  return (
    <Box m={1} display="flex" justifyContent="space-between">
      <Box display="flex">
        <Box mr={1}>
          <NavigationDrawer />
        </Box>
        <Typography variant="h5">
          <strong>DataRoom</strong> {dataRoomId && <Id id={dataRoomId} />}
        </Typography>
      </Box>
      <Box display="flex">
        <Box mr={0.5}>
          <IconButton color="inherit">
            <FontAwesomeIcon fixedWidth icon={faStar} />
          </IconButton>
        </Box>
        <Box mr={0.5}>
          <AvatarGroup max={4} classes={avatarGroupClasses}>
            <Avatar
              alt="Volodymyr Kushnir"
              src="https://ca.slack-edge.com/T6DHPD7C5-U01B8ERFWMV-3906892bef1f-512"
            />
            <Avatar
              alt="Nikolas Molyndris"
              src="https://ca.slack-edge.com/T6DHPD7C5-UDB4XM59T-80ae5af3af55-512"
            />
            <Avatar
              alt="Melina Dimoula"
              src="https://ca.slack-edge.com/T6DHPD7C5-U018J9C9B4J-2a135c3bbcac-512"
            />
            <Avatar
              alt="David Sturzenegger"
              src="https://ca.slack-edge.com/T6DHPD7C5-UKS3C8K6V-32cd8c082f40-512"
            />
            <Avatar
              alt="Enrico Ghirardi"
              src="https://ca.slack-edge.com/T6DHPD7C5-UQ1QGD5LG-96db4d904497-512"
            />
          </AvatarGroup>
        </Box>
        <DataRoomActions />
      </Box>
      {/*
        <Box>
          <AccountMenu />
          <NotificationsDrawer />
        </Box>
        */}
    </Box>
  );
};

export default MainAppBar;
