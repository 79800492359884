import { makeStyles } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";

const useInputLabelStyles = makeStyles((theme) => ({
  asterisk: {
    fontSize: "1.25em",
    lineHeight: "50%",
    color: "red",
  },
}));

const useInputStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 0),
  },
  multiline: {
    padding: theme.spacing(0.5, 0),
  },
  input: {
    fontSize: "1rem",
    lineHeight: "1.25rem !important",
    minHeight: "1.25rem !important",
    padding: 0,
  },
}));

const DataRoomFormDescriptionField = () => {
  const {
    status: { isEditing },
  } = useFormikContext();
  const inputClasses = useInputStyles();
  const inputLabelClasses = useInputLabelStyles();
  return (
    <Field
      name="description"
      component={TextField}
      variant="standard"
      color="secondary"
      fullWidth
      label={isEditing ? "Description" : null}
      InputLabelProps={{
        shrink: true,
        style: {
          textAlign: "center",
          width: "100%",
          transformOrigin: "top center",
        },
        classes: inputLabelClasses,
      }}
      InputProps={{
        classes: inputClasses,
        multiline: true,
        disableUnderline: !isEditing,
        readOnly: !isEditing,
        disabled: !isEditing,
        style: {
          textAlign: "center",
          fontSize: "1.25rem",
          lineHeight: 1.25,
          opacity: 0.625,
        },
      }}
    />
  );
};

export default DataRoomFormDescriptionField;
