import { faPlay, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import { FastField, useFormikContext, useStatus } from "formik";
import { memo } from "react";
import { ResizableBox } from "react-resizable";
import {
  DataRoomFormDataAnalystsField,
  NameField,
  QueryResult,
  SqlEditorField,
} from "components";

const useAccordionStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 8, 1, 8),
    borderTopWidth: "1px",
    borderColor: theme.palette.action.selected,
    borderBottomWidth: "1px",
    borderRadius: "0 !important",
    borderTopStyle: "solid",
    borderBottomStyle: "solid",
    marginTop: "-1px !important",
    "& .query-actions": ({ isRunAvailable }) => ({
      opacity: isRunAvailable ? 1 : 0,
      visibility: isRunAvailable ? "visible" : "hidden",
    }),
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      "& .query-actions": {
        opacity: 1,
        visibility: "visible",
      },
    },
    "&:before": {
      display: "none",
    },
  },
}));

const useInputLabelStyles = makeStyles((theme) => ({
  asterisk: {
    fontSize: "1.25em",
    lineHeight: "50%",
    color: "red",
  },
}));

const useInputStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 0),
  },
  multiline: {
    padding: theme.spacing(0.5, 0),
  },
  input: {
    fontSize: "1rem",
    lineHeight: "1.25rem !important",
    minHeight: "1.25rem !important",
    padding: 0,
  },
}));

const useInlineIconButtonStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
  },
}));

const Query = memo(
  ({ query, queryIndex, result, onDelete = () => {}, onRunQueryClick }) => {
    const [{ isEditing, hasResult }] = useStatus();
    const { values } = useFormikContext();
    const { loading } = result || {};
    const isRunAvailable = !isEditing && !loading;
    const accordionClasses = useAccordionStyles({
      isRunAvailable,
    });
    const inputLabelClasses = useInputLabelStyles();
    const inputClasses = useInputStyles();
    const inlineIconButtonClasses = useInlineIconButtonStyles();
    const { name, sqlSelectStatement, dataAnalysts } = query;

    return (
      <Accordion
        classes={accordionClasses}
        expanded
        elevation={0}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          onClick={(event) => event.stopPropagation()}
          expandIcon={null}
        >
          <Grid
            container
            justifyContent="center"
            spacing={1}
            onClick={(event) => event.stopPropagation()}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <InputLabel
                  shrink
                  required={isEditing}
                  style={{
                    transform: "none",
                    fontSize: "0.75rem",
                    whiteSpace: "nowrap",
                  }}
                  classes={inputLabelClasses}
                >
                  Query {queryIndex + 1}
                </InputLabel>
              </Box>
              <Box sx={{ position: "relative" }}>
                <NameField
                  name={`queries.${queryIndex}.name`}
                  startAdornment={
                    <Box
                      sx={{
                        position: "absolute",
                        right: "100%",
                        top: 0,
                        marginRight: "0.5rem",
                        height: "100%",
                        padding: "0.25rem 0",
                        lineHeight: "1.25rem",
                      }}
                    >
                      {loading ? (
                        <CircularProgress
                          size={16}
                          color="inherit"
                          thickness={3}
                        />
                      ) : hasResult && result?.error ? (
                        <Tooltip title={result?.error} placement="top">
                          <Box>
                            {hasResult && result?.error && (
                              <FontAwesomeIcon
                                fixedWidth
                                icon={faExclamationTriangle}
                                color={orange["500"]}
                              />
                            )}
                          </Box>
                        </Tooltip>
                      ) : null}
                    </Box>
                  }
                  endAdornment={
                    <Box
                      className="query-actions"
                      sx={{
                        display: "flex",
                        marginTop: -0.5,
                        marginLeft: 0.25,
                        marginBottom: -0.5,
                      }}
                    >
                      {isRunAvailable ? (
                        <Tooltip title="Run" placement="top">
                          <Button
                            color="inherit"
                            size="small"
                            onClick={onRunQueryClick.bind(
                              null,
                              {
                                name,
                                sqlSelectStatement,
                                dataAnalysts,
                              },
                              queryIndex,
                              values.password
                            )}
                            startIcon={
                              <FontAwesomeIcon fixedWidth icon={faPlay} />
                            }
                          >
                            Run
                          </Button>
                        </Tooltip>
                      ) : null}
                      {isEditing && (
                        <Tooltip title="Delete" placement="top">
                          <IconButton
                            classes={inlineIconButtonClasses}
                            style={{ color: "red" }}
                            onClick={onDelete.bind(null, queryIndex)}
                            TouchRippleProps={{
                              center: false,
                            }}
                          >
                            <FontAwesomeIcon fixedWidth icon={faTrashAlt} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{ paddingTop: "0.5rem" }}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12} md={12}>
              <FastField name={`queries.${queryIndex}.sqlSelectStatement`}>
                {({ field: { value, ...rest }, meta: { touched, error } }) => {
                  return (
                    <FormControl fullWidth>
                      <ResizableBox height={200} axis="y">
                        <SqlEditorField
                          fullWidth
                          multiline
                          variant="standard"
                          color="secondary"
                          label="SQL"
                          InputLabelProps={{
                            classes: inputLabelClasses,
                            shrink: true,
                            required: true,
                          }}
                          InputProps={{
                            classes: inputClasses,
                            disableUnderline: !isEditing,
                            readOnly: !isEditing,
                            disabled: false,
                          }}
                          editorOptions={{
                            readOnly: !isEditing,
                          }}
                          value={value}
                          {...rest}
                        />
                      </ResizableBox>
                      {Boolean(error) && (
                        <FormHelperText error={Boolean(error)}>
                          {error}
                        </FormHelperText>
                      )}
                    </FormControl>
                  );
                }}
              </FastField>
            </Grid>
            <Grid item xs={12}>
              <DataRoomFormDataAnalystsField queryIndex={queryIndex} />
            </Grid>
          </Grid>
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <QueryResult
                  queryIndex={queryIndex}
                  kind={query.kind}
                  result={result}
                />
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }
);

export default Query;
