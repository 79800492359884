import { makeStyles } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";

const useInputLabelStyles = makeStyles((theme) => ({
  asterisk: {
    fontSize: "1.25em",
    lineHeight: "50%",
    color: "red",
  },
}));

const DataRoomFormTitleField = () => {
  const {
    status: { isEditing },
  } = useFormikContext();
  const inputLabelClasses = useInputLabelStyles();
  return (
    <Field
      name="name"
      component={TextField}
      variant="standard"
      color="secondary"
      fullWidth
      label={isEditing ? "Title" : null}
      InputLabelProps={{
        required: true,
        shrink: true,
        style: {
          textAlign: "center",
          width: "100%",
          transformOrigin: "top center",
        },
        classes: inputLabelClasses,
      }}
      InputProps={{
        multiline: true,
        disableUnderline: !isEditing,
        readOnly: !isEditing,
        disabled: !isEditing,
        inputProps: {
          style: {
            textAlign: "center",
            fontSize: "2rem",
            fontWeight: 700,
            lineHeight: 1.25,
            letterSpacing: "-0.5px",
          },
        },
      }}
    />
  );
};

export default DataRoomFormTitleField;
