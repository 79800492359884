import { Box, Typography } from "@material-ui/core";

const EmptyCollaborationsDataRoomCard = () => {
  return (
    <Box
      component="div"
      style={{
        marginTop: "24px",
      }}
    >
      <Typography variant="body1" color="textSecondary">
        You are currently not participating in any collaboration
      </Typography>
    </Box>
  );
};

export default EmptyCollaborationsDataRoomCard;
