import { Box, makeStyles, Portal } from "@material-ui/core";
import { darken } from "@material-ui/core/styles";
import { useBoolean } from "ahooks";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";
import { ReactComponent as DecentriqLogo } from "assets/logos/decentriq-platform-symbol-square.svg";
import { UnauthorizedDialog } from "components";
import { Main } from "layouts";

const useAboutPageStyles = makeStyles((theme) => ({
  dimmedBlock: {
    backgroundColor: darken(theme.palette.background.default, 0.025),
  },
}));

const AboutPage = () => {
  const aboutPageClasses = useAboutPageStyles();
  const location = useLocation();
  const searchParams = parse(location.search);
  const { error } = searchParams;
  const [
    isUnauthorizedDialogOpen,
    { setFalse: closeUnauthorizedDialog },
  ] = useBoolean(error === "unauthorized");
  return (
    <Main>
      <Box
        sx={{
          pt: 10,
          pb: 10,
          minHeight: "calc(100vh - 4rem)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className={aboutPageClasses.dimmedBlock}
      >
        <DecentriqLogo style={{ height: "14rem" }} />
      </Box>
      <Portal>
        <UnauthorizedDialog
          open={isUnauthorizedDialogOpen}
          onClose={closeUnauthorizedDialog}
        />
      </Portal>
    </Main>
  );
};

export default AboutPage;
