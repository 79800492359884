import { faSquare } from "@fortawesome/pro-light-svg-icons";
import { faCheckSquare, faMinusSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "@material-ui/core";
import { memo } from "react";
import {
  useCheckboxStyles,
  useFontAwesomeCheckboxStyles,
} from "./FontAwesomeCheckbox.styles";

const FontAwesomeCheckbox = ({
  id,
  select = () => {},
  isSelected,
  isIndeterminate,
  field,
  form,
  ...rest
}) => {
  const checkboxClasses = useCheckboxStyles();
  const fontAwesomeCheckboxClasses = useFontAwesomeCheckboxStyles();
  return (
    <Checkbox
      color="inherit"
      classes={checkboxClasses}
      icon={
        <span className={fontAwesomeCheckboxClasses.label}>
          <FontAwesomeIcon fixedWidth icon={faSquare} />
        </span>
      }
      checked={typeof isSelected === "function" ? isSelected(id) : isSelected}
      checkedIcon={
        <span className={fontAwesomeCheckboxClasses.label}>
          <FontAwesomeIcon fixedWidth icon={faCheckSquare} />
        </span>
      }
      indeterminate={isIndeterminate}
      indeterminateIcon={
        <span className={fontAwesomeCheckboxClasses.label}>
          <FontAwesomeIcon fixedWidth icon={faMinusSquare} />
        </span>
      }
      onChange={select.bind(null, id)}
      {...field}
      {...rest}
    />
  );
};

export default memo(FontAwesomeCheckbox);
