import { faArrowUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Meta, MetaGroup, MetaWrapper } from "legacy/components";
import { memo } from "react";

const DataRoomMeta = ({ dataRoom = {} }) => {
  const { param: { datasetsTotalCount } = {} } = dataRoom;
  return (
    <MetaWrapper>
      <MetaGroup>
        <Meta
          tooltip="Total datasets"
          icon={<FontAwesomeIcon icon={faArrowUp} />}
          value={datasetsTotalCount}
        />
      </MetaGroup>
    </MetaWrapper>
  );
};

export default memo(DataRoomMeta);
