import { dataRoomMode, questionKind } from "constants/index";
import { faCircle, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { teal } from "@material-ui/core/colors";
import { FastField, Field, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import { memo } from "react";
import { DataRoomFormCategoriesField, QuestionResult } from "components";
import FontAwesomeCheckbox from "components/base/Select/FontAwesomeCheckbox";
import FontAwesomeRadiobox from "components/base/Select/FontAwesomeRadiobox";

const useAccordionStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 8, 1, 8),
    borderTopWidth: "1px",
    borderColor: theme.palette.action.selected,
    borderBottomWidth: "1px",
    borderRadius: "0 !important",
    borderTopStyle: "solid",
    borderBottomStyle: "solid",
    marginTop: "-1px !important",
    "& .question-actions": {
      opacity: 0,
      visibility: "hidden",
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      "& .question-actions": {
        opacity: 1,
        visibility: "visible",
      },
    },
    "&:before": {
      display: "none",
    },
  },
}));

const useAccordionSummaryStyles = makeStyles((theme) => ({
  focusVisible: {
    backgroundColor: "transparent !important",
  },
}));

const useInputLabelStyles = makeStyles((theme) => ({
  asterisk: {
    fontSize: "1.25em",
    lineHeight: "50%",
    color: "red",
  },
}));

const useInputStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 0),
  },
  multiline: {
    padding: theme.spacing(0.5, 0),
  },
  input: {
    fontSize: "1rem",
    lineHeight: "1.25rem !important",
    minHeight: "1.25rem !important",
    padding: 0,
  },
}));

const useSelectStyles = makeStyles((theme) => ({
  select: {
    padding: theme.spacing(0.5, 0),
    minHeight: "1rem",
    lineHeight: "1rem",
  },
}));

const useInlineIconButtonStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
  },
}));

const useRadioboxFormControlLabelStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  label: {
    fontSize: "0.75rem",
    lineHeight: 1,
    marginLeft: 0,
  },
}));

const Question = memo(
  ({
    question,
    questionIndex,
    answerState,
    result,
    onDelete = () => {},
    setFieldValue,
  }) => {
    // const aggregationsSelectRef = useRef(null);
    // const rejectionFeedbackSelectRef = useRef(null);
    // const answerSelectRef = useRef(null);
    const accordionClasses = useAccordionStyles();
    const accordionSummaryClasses = useAccordionSummaryStyles();
    const inputLabelClasses = useInputLabelStyles();
    const inputClasses = useInputStyles();
    const inlineIconButtonClasses = useInlineIconButtonStyles();
    const radioboxFormControlLabelClasses = useRadioboxFormControlLabelStyles();
    const selectClasses = useSelectStyles();
    const {
      values,
      errors,
      isValidating,
      status: { mode },
    } = useFormikContext();
    const answer = values.answers[questionIndex];
    const isCategorial = question.kind === questionKind.CATEGORICAL;
    const isNumerical = question.kind === questionKind.NUMERICAL;
    const getQuestionType = (questionIndex) => {
      switch (question.kind) {
        case questionKind.NUMERICAL:
          return "Number input";
        case questionKind.CATEGORICAL:
          return question.allowMultiAnswer
            ? "Multiple choice"
            : "Single choice";
        default:
          return "Single choice";
      }
    };
    const { loading: isAnswerLoading = false, hasSubmitted = false } =
      answerState || {};
    const hasError =
      errors && errors["answers"] && errors["answers"][questionIndex];
    return (
      <Accordion
        classes={accordionClasses}
        expanded
        elevation={0}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          classes={accordionSummaryClasses}
          onClick={(event) => event.stopPropagation()}
          expandIcon={null}
        >
          <Grid
            container
            justifyContent="center"
            spacing={1}
            onClick={(event) => event.stopPropagation()}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <InputLabel
                  shrink
                  required={[dataRoomMode.UPDATE].includes(mode)}
                  style={{
                    transform: "none",
                    fontSize: "0.75rem",
                    whiteSpace: "nowrap",
                  }}
                  classes={inputLabelClasses}
                >
                  Question {questionIndex + 1} — With at least{" "}
                  {question.minimumNumberOfValidSubmissions} submission
                  {question.minimumNumberOfValidSubmissions > 1 ? "s " : " "}
                  {isCategorial ? (
                    <strong>count per choice</strong>
                  ) : isNumerical ? (
                    <strong>{question.aggregations.join(", ")}</strong>
                  ) : null}{" "}
                  will be calculated
                </InputLabel>
              </Box>
              <Box sx={{ position: "relative" }}>
                {[dataRoomMode.PREVIEW, dataRoomMode.SUBMIT].includes(mode) && (
                  <Box
                    sx={{
                      position: "absolute",
                      right: "100%",
                      top: 0,
                      marginRight: "0.5rem",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {isAnswerLoading ? (
                      <CircularProgress
                        size={16}
                        color="inherit"
                        thickness={3}
                      />
                    ) : (
                      <FontAwesomeIcon
                        fixedWidth
                        icon={
                          hasSubmitted ||
                          (!isValidating &&
                            !hasError &&
                            (Array.isArray(answer?.value)
                              ? answer.value.length > 0
                              : Boolean(answer?.value) || answer?.value === 0))
                            ? faCheckCircle
                            : faCircle
                        }
                        color={hasSubmitted ? teal["A400"] : "inherit"}
                      />
                    )}
                  </Box>
                )}
                <Field
                  name={`questions.${questionIndex}.name`}
                  component={TextField}
                  variant="standard"
                  color="secondary"
                  fullWidth
                  multiline
                  InputProps={{
                    classes: inputClasses,
                    style: { fontWeight: "bold" },
                    endAdornment: (
                      <Box
                        className="question-actions"
                        sx={{
                          display: "flex",
                          marginTop: -0.5,
                          marginLeft: 0.25,
                          marginBottom: -0.5,
                        }}
                      >
                        {[dataRoomMode.UPDATE].includes(mode) && (
                          <Tooltip title="Delete" placement="top">
                            <IconButton
                              classes={inlineIconButtonClasses}
                              style={{ color: "red" }}
                              onClick={onDelete.bind(null, questionIndex)}
                              TouchRippleProps={{
                                center: false,
                              }}
                            >
                              <FontAwesomeIcon fixedWidth icon={faTrashAlt} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    ),
                    disableUnderline: false,
                    readOnly: ![dataRoomMode.UPDATE].includes(mode),
                    disabled: false,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{ paddingTop: "0.5rem" }}>
          {([dataRoomMode.UPDATE].includes(mode) ||
            question["description"]) && (
            <Grid container justifyContent="center" spacing={1}>
              <Grid
                item
                xs={12}
                md={![dataRoomMode.UPDATE].includes(mode) ? 12 : 9}
              >
                <FastField
                  component={TextField}
                  name={`questions.${questionIndex}.description`}
                  fullWidth
                  variant="standard"
                  color="secondary"
                  label={
                    [dataRoomMode.UPDATE].includes(mode) ? "Description" : false
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    classes: inputClasses,
                    disableUnderline: ![dataRoomMode.UPDATE].includes(mode),
                    readOnly: ![dataRoomMode.UPDATE].includes(mode),
                    disabled: false,
                  }}
                />
              </Grid>
              {[dataRoomMode.UPDATE].includes(mode) && (
                <>
                  <Grid item xs={12} md={3}>
                    <FastField
                      name={`questions.${questionIndex}.minimumNumberOfValidSubmissions`}
                      component={TextField}
                      fullWidth
                      variant="standard"
                      color="secondary"
                      type="number"
                      autoComplete="off"
                      label="Min. submissions"
                      InputProps={{
                        disabled: false,
                        classes: inputClasses,
                        onWheelCapture: (event) => event.target.blur(),
                      }}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                        classes: inputLabelClasses,
                      }}
                      inputProps={{
                        min: 1,
                        step: 1,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <InputLabel
                        color="secondary"
                        shrink
                        required
                        style={{
                          transform: "none",
                          fontSize: "0.75rem",
                          whiteSpace: "nowrap",
                        }}
                        classes={inputLabelClasses}
                      >
                        Answer {questionIndex + 1}
                      </InputLabel>
                      <Box sx={{ pl: 1.5, pr: 1 }}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ lineHeight: 1 }}
                        >
                          —
                        </Typography>
                      </Box>
                      <RadioGroup
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "-0.25rem",
                          marginBottom: "-0.25rem",
                        }}
                        value={getQuestionType(questionIndex)}
                        onChange={(event) => {
                          const { value } = event.target;
                          const {
                            name,
                            description,
                            minimumNumberOfValidSubmissions,
                            isRequired,
                          } = question;
                          if (value === "Number input") {
                            setFieldValue(`questions.${questionIndex}`, {
                              kind: questionKind.NUMERICAL,
                              name,
                              description,
                              minimumNumberOfValidSubmissions,
                              isRequired,
                              unit: "",
                              minValueValidation: null,
                              maxValueValidation: null,
                              lowerPercentileFilter: 0,
                              upperPercentileFilter: 1,
                              rejectionFeedback: 0,
                              aggregations: [
                                "count",
                                "sum",
                                "avg",
                                "min",
                                "max",
                              ],
                            });
                          } else if (value === "Single choice") {
                            setFieldValue(`questions.${questionIndex}`, {
                              kind: questionKind.CATEGORICAL,
                              name,
                              description,
                              minimumNumberOfValidSubmissions,
                              isRequired,
                              allowMultiAnswer: false,
                              submissionOptions: [],
                            });
                          } else if (value === "Multiple choice") {
                            setFieldValue(`questions.${questionIndex}`, {
                              kind: questionKind.CATEGORICAL,
                              name,
                              description,
                              minimumNumberOfValidSubmissions,
                              isRequired,
                              allowMultiAnswer: true,
                              submissionOptions: [],
                            });
                          }
                        }}
                      >
                        <FormControlLabel
                          classes={radioboxFormControlLabelClasses}
                          control={
                            <FontAwesomeRadiobox
                              style={{
                                fontSize: "0.875rem",
                                padding: "0.25rem",
                                color: "inherit",
                              }}
                            />
                          }
                          value="Number input"
                          label="Number input"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          classes={radioboxFormControlLabelClasses}
                          control={
                            <FontAwesomeRadiobox
                              style={{
                                fontSize: "0.875rem",
                                padding: "0.25rem",
                                color: "inherit",
                              }}
                            />
                          }
                          value="Single choice"
                          label="Single choice"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          classes={radioboxFormControlLabelClasses}
                          control={
                            <FontAwesomeRadiobox
                              style={{
                                fontSize: "0.875rem",
                                padding: "0.25rem",
                                color: "inherit",
                              }}
                            />
                          }
                          value="Multiple choice"
                          label="Multiple choice"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                      <Box sx={{ pl: 0.5, pr: 1 }}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ lineHeight: 1 }}
                        >
                          —
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "-0.25rem",
                          marginBottom: "-0.25rem",
                        }}
                      >
                        <FormControlLabel
                          classes={radioboxFormControlLabelClasses}
                          control={
                            <Field
                              component={FontAwesomeCheckbox}
                              style={{
                                fontSize: "0.875rem",
                                padding: "0.25rem",
                                color: "inherit",
                              }}
                              type="checkbox"
                              name={`questions.${questionIndex}.isRequired`}
                            />
                          }
                          label="Required"
                          labelPlacement="end"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  {isCategorial && (
                    <Grid item xs={12}>
                      <DataRoomFormCategoriesField
                        name={`questions.${questionIndex}.submissionOptions`}
                      />
                    </Grid>
                  )}
                  {isNumerical && (
                    <>
                      <Grid item xs={12} md={2}>
                        <FastField
                          fullWidth
                          component={TextField}
                          name={`questions.${questionIndex}.unit`}
                          variant="standard"
                          color="secondary"
                          label="Unit"
                          InputLabelProps={{
                            shrink: true,
                            classes: inputLabelClasses,
                          }}
                          InputProps={{
                            disabled: false,
                            classes: inputClasses,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FastField
                          name={`questions.${questionIndex}.minValueValidation`}
                          type="number"
                        >
                          {(props) => (
                            <TextField
                              fullWidth
                              variant="standard"
                              color="secondary"
                              type="number"
                              label="Min."
                              InputLabelProps={{
                                shrink: true,
                                classes: inputLabelClasses,
                              }}
                              InputProps={{
                                disabled: false,
                                classes: inputClasses,
                              }}
                              {...props}
                              autoComplete="off"
                              // value={props.field.value || ""}
                            />
                          )}
                        </FastField>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FastField
                          name={`questions.${questionIndex}.maxValueValidation`}
                          type="number"
                        >
                          {(props) => (
                            <TextField
                              fullWidth
                              variant="standard"
                              color="secondary"
                              type="number"
                              label="Max."
                              InputLabelProps={{
                                shrink: true,
                                classes: inputLabelClasses,
                              }}
                              InputProps={{
                                disabled: false,
                                classes: inputClasses,
                              }}
                              {...props}
                              autoComplete="off"
                              // value={props.field.value || ""}
                            />
                          )}
                        </FastField>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FastField
                          name={`questions.${questionIndex}.aggregations`}
                        >
                          {({
                            field: { value, ...rest },
                            meta: { touched, error },
                          }) => (
                            <FormControl fullWidth>
                              <InputLabel
                                color="secondary"
                                shrink
                                required
                                classes={inputLabelClasses}
                                error={touched && Boolean(error)}
                              >
                                Aggregations
                              </InputLabel>
                              <Select
                                color="secondary"
                                multiple
                                value={value || []}
                                classes={selectClasses}
                                // ref={aggregationsSelectRef}
                                // MenuProps={{
                                //   container: () => aggregationsSelectRef.current,
                                // }}
                                {...rest}
                                error={touched && Boolean(error)}
                                helperText={touched && error}
                              >
                                <MenuItem value="count">Count</MenuItem>
                                <MenuItem value="sum">Sum</MenuItem>
                                <MenuItem value="avg">Avg</MenuItem>
                                <MenuItem value="min">Min</MenuItem>
                                <MenuItem value="max">Max</MenuItem>
                              </Select>
                              {touched && Boolean(error) && (
                                <FormHelperText
                                  error={touched && Boolean(error)}
                                >
                                  {error}
                                </FormHelperText>
                              )}
                            </FormControl>
                          )}
                        </FastField>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          )}
          {[dataRoomMode.PREVIEW, dataRoomMode.SUBMIT].includes(mode) && (
            <Grid container justifyContent="center" spacing={1}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <InputLabel
                    color="secondary"
                    shrink
                    required={question.isRequired}
                    style={{ whiteSpace: "nowrap" }}
                    classes={inputLabelClasses}
                  >
                    Answer {questionIndex + 1} —{" "}
                    {isCategorial
                      ? `${
                          question.allowMultiAnswer ? "Multiple" : "Single"
                        } choice`
                      : isNumerical
                      ? [
                          question.unit.trim() || "Number",
                          typeof question.minValueValidation === "number"
                            ? `more than ${question.minValueValidation}`
                            : null,
                          typeof question.maxValueValidation === "number"
                            ? `less than ${question.maxValueValidation}`
                            : null,
                        ]
                          .filter(Boolean)
                          .join(", ")
                      : null}
                  </InputLabel>
                  <InputLabel
                    color="secondary"
                    shrink
                    style={{
                      whiteSpace: "nowrap",
                      transformOrigin: "top right",
                    }}
                    classes={inputLabelClasses}
                  >
                    Results will be visible to you only if you submit your
                    answer
                  </InputLabel>
                </Box>
                {hasSubmitted ? (
                  <Alert severity="success">
                    You have already submitted the data
                  </Alert>
                ) : isNumerical ? (
                  <FormControl fullWidth>
                    <FastField
                      component={TextField}
                      name={`answers.${questionIndex}.value`}
                      variant="standard"
                      color="secondary"
                      type="number"
                      autoComplete="off"
                      InputProps={{
                        onWheelCapture: (event) => event.target.blur(),
                      }}
                    />
                  </FormControl>
                ) : isCategorial ? (
                  <FastField name={`answers.${questionIndex}.value`}>
                    {({
                      field: { value, ...rest },
                      meta: { touched, error },
                    }) => (
                      <FormControl fullWidth>
                        <Select
                          color="secondary"
                          classes={selectClasses}
                          // ref={answerSelectRef}
                          // MenuProps={{
                          //   container: () => answerSelectRef.current,
                          // }}
                          value={value || []}
                          multiple={question.allowMultiAnswer}
                          {...rest}
                          error={touched && Boolean(error)}
                          helperText={touched && error}
                        >
                          {question.submissionOptions.map(
                            (submissionOption, index) => (
                              <MenuItem key={index} value={submissionOption}>
                                {submissionOption}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched && Boolean(error) && (
                          <FormHelperText error={touched && Boolean(error)}>
                            {error}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  </FastField>
                ) : null}
              </Grid>
            </Grid>
          )}
          {mode === dataRoomMode.RESULTS && (
            <Box sx={{ flex: 1 }}>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <QuestionResult
                    questionIndex={questionIndex}
                    kind={question.kind}
                    result={result}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }
);

export default Question;
