import { Schema } from "@decentriq/safequery/lib/safequery-client/src/api";
import * as yup from "yup";

const isSchemaValidationEnabled = false;

export const dataRoomValidationSchema = yup
  .object()
  .noUnknown()
  .shape({
    name: yup.string().required("Must specify dataRoom title"),
    description: yup.string(),
    password: yup.string(),
    tables: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .required(
            "Must specify table name (will extract the name from the SQL statement)"
          ),
        sqlCreateStatement: yup
          .string()
          .required("Must specify schema")
          .test({
            name: "is-valid-schema",
            test: (value, context) => {
              if (isSchemaValidationEnabled) {
                const { createError, path } = context;
                try {
                  new Schema(value);
                  return true;
                } catch (error) {
                  console.error(error.message);
                  return createError({
                    message: error.message,
                    path,
                  });
                }
              } else {
                return true;
              }
            },
          }),
        dataProviders: yup
          .array()
          .min(1, "At least 1 data provider should be added")
          .of(
            yup.string().email(({ path }) => {
              const match = path.match(/dataProviders\[(\d)\]/);
              return [
                "Data provider",
                match && match[1] ? ++match[1] : null,
                "must have a valid email",
              ]
                .filter(Boolean)
                .join(" ");
            })
          )
          .required("Must specify data providers"),
      })
    ),
    queries: yup.array().of(
      yup.object().shape({
        name: yup.string().required("Must specify query name"),
        sqlSelectStatement: yup.string().required("Must specify query"),
        dataAnalysts: yup
          .array()
          .min(1, "At least 1 data analyst should be added")
          .of(
            yup.string().email(({ path }) => {
              const match = path.match(/dataAnalysts\[(\d)\]/);
              return [
                "Data analyst",
                match && match[1] ? ++match[1] : null,
                "must have a valid email",
              ]
                .filter(Boolean)
                .join(" ");
            })
          )
          .required("Must specify data analysts"),
      })
    ),
  });
