import Editor from "@monaco-editor/react";
import { memo } from "react";

const defaultProps = {
  defaultLanguage: "sql",
};

const defaultEditorOptions = {
  minimap: { enabled: false },
};

const SqlEditor = memo(({ editorOptions, ...rest }) => {
  return (
    <Editor
      options={{
        ...defaultEditorOptions,
        ...editorOptions,
      }}
      {...defaultProps}
      {...rest}
    />
  );
});

export const SqlEditorField = memo((props) => (
  <SqlEditor {...props}></SqlEditor>
));

export default SqlEditor;
