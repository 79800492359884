import { faCircle } from "@fortawesome/pro-light-svg-icons";
import { faDotCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, MenuItem, MenuList } from "@material-ui/core";
import { forwardRef } from "react";
import { useViewsMenuItemStyles } from "./ViewsMenu.styles";

const ViewsMenuItem = forwardRef(
  ({ label, info, isDisabled, isSelected, onSelect }, ref) => {
    const viewsMenuItemClasses = useViewsMenuItemStyles({
      isSelected,
    });
    return (
      <MenuItem
        button
        dense
        innerRef={ref}
        disabled={isDisabled}
        className={viewsMenuItemClasses.menuItem}
        onClick={onSelect}
        style={{ alignItems: "flex-start" }}
      >
        <FontAwesomeIcon
          fixedWidth
          icon={isSelected ? faDotCircle : faCircle}
          className={viewsMenuItemClasses.menuItemIcon}
        />
        <Box className={viewsMenuItemClasses.menuItemLabel}>
          <Box className={viewsMenuItemClasses.menuItemLabelTitle}>{label}</Box>
          {info && (
            <Box className={viewsMenuItemClasses.menuItemLabelInfo}>{info}</Box>
          )}
        </Box>
      </MenuItem>
    );
  }
);

const ViewsMenu = ({ viewsWithSelections }) => {
  const { all, isSelected, setSelected } = viewsWithSelections;
  return (
    <MenuList>
      {all.map(({ name, label, info, isDisabled }, index) => (
        <ViewsMenuItem
          key={index}
          name={name}
          label={label}
          info={info}
          isDisabled={isDisabled}
          isSelected={isSelected(name)}
          onSelect={setSelected.bind(null, [name])}
        />
      ))}
    </MenuList>
  );
};

export default ViewsMenu;
