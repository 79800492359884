import {
  faPlus,
  faTrashAlt,
  faUserPlus,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { useRef, useState } from "react";
import {
  dataRoomParticipantInitialValues,
  dataRoomParticipantValidationSchema,
} from "./DataRoomParticipantValidationScheme";

const useInlineIconButtonStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
  },
}));

const DataRoomParticipantsManagerField = ({ name, maxVisible }) => {
  const addParticipantButtonClasses = useInlineIconButtonStyles();
  const [addButtonAnchor, setAddButtonAnchor] = useState(null);
  const inputRef = useRef(null);

  const isPanelOpen = Boolean(addButtonAnchor);

  const getNextFieldValue = (currentValues, nextValues) => {
    const temp = [
      ...new Set(
        [...currentValues, ...nextValues].map((e) => e.trim().toLowerCase())
      ),
    ];

    return temp.sort();
  };

  return (
    <Field name={name}>
      {({ field, meta: { value: fieldValue } }) => {
        const value = fieldValue || [];
        const visibleParticipants = value.slice(0, maxVisible);
        const remainingParticipants = value.slice(maxVisible);
        const hasMoreThanMaxVisible = value.length > maxVisible;
        return (
          <Box
            component="div"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Box component="div" style={{ marrgin: "0 -2px", display: "flex" }}>
              {visibleParticipants.map((p, i) => (
                <ParticipantAvatar
                  fontSize="14px"
                  size="32px"
                  name={p}
                  key={i}
                />
              ))}
              {hasMoreThanMaxVisible && (
                <ParticipantAvatar
                  size="32px"
                  fontSize="14px"
                  tooltip={remainingParticipants.join(", ")}
                  name={`+${value.length - maxVisible}`}
                ></ParticipantAvatar>
              )}
            </Box>
            <Tooltip title="Add participant" placement="top">
              <IconButton
                onClick={(event) =>
                  setAddButtonAnchor(addButtonAnchor ? null : event.target)
                }
                classes={addParticipantButtonClasses}
              >
                <FontAwesomeIcon fixedWidth icon={faUserPlus} />
              </IconButton>
            </Tooltip>
            <Popover
              id={name}
              open={isPanelOpen}
              anchorEl={addButtonAnchor}
              onClose={() => setAddButtonAnchor(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Paper>
                <Formik
                  initialValues={dataRoomParticipantInitialValues}
                  validateOnChange
                  validationSchema={dataRoomParticipantValidationSchema}
                  onSubmit={(values, actions) => {
                    const { email } = values;
                    if (email.trim()) {
                      field.onChange(getNextFieldValue(value, [email]));
                      actions.resetForm(dataRoomParticipantInitialValues);
                      inputRef.current?.focus();
                    }
                    actions.setSubmitting(false);
                  }}
                >
                  {({ isValid }) => (
                    <Form>
                      <Field
                        name="email"
                        component={TextField}
                        autoComplete="off"
                        variant="standard"
                        color="secondary"
                        placeholder="Enter email"
                        inputRef={inputRef}
                        InputProps={{
                          endAdornment: (
                            <Box>
                              <Tooltip title="Add participant" placement="top">
                                <IconButton
                                  type="submit"
                                  disabled={!isValid}
                                  TouchRippleProps={{
                                    center: false,
                                  }}
                                  classes={addParticipantButtonClasses}
                                >
                                  <FontAwesomeIcon fixedWidth icon={faPlus} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          ),
                        }}
                        onPaste={(event) => {
                          const newValues = (
                            event.clipboardData || window.clipboardData
                          )
                            .getData("text")
                            .split("\n")
                            .map((value) => value.split(" "))
                            .flat()
                            .filter(Boolean);

                          if (newValues.length >= 1) {
                            field.onChange(
                              getNextFieldValue(field.value, newValues)
                            );
                            event.preventDefault();
                          }
                        }}
                        style={{
                          minWidth: "350px",
                          margin: "16px",
                          marginBottom: 0,
                        }}
                      />
                    </Form>
                  )}
                </Formik>
                <List dense>
                  {(value || []).map((p, i) => (
                    <ListItem key={i} dense>
                      <ListItemAvatar>
                        <ParticipantAvatar
                          name={p}
                          size="25px"
                          fontSize="10px"
                        ></ParticipantAvatar>
                      </ListItemAvatar>
                      <ListItemText>{p}</ListItemText>
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={(event) => {
                            field.onChange([
                              ...new Set(
                                [
                                  ...field.value.slice(0, i),
                                  ...field.value.slice(i + 1),
                                ].sort()
                              ),
                            ]);
                          }}
                          classes={addParticipantButtonClasses}
                        >
                          <FontAwesomeIcon
                            color="red"
                            fixedWidth
                            icon={faTrashAlt}
                          />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Popover>
          </Box>
        );
      }}
    </Field>
  );
};

DataRoomParticipantsManagerField.defaultProps = {
  maxVisible: 3,
};

const ParticipantAvatar = ({ name, size, tooltip, fontSize }) => (
  <Avatar
    style={{
      backgroundColor: "white",
      height: size,
      color: grey["400"],
      width: size,
      margin: "0 2px",
      border: `1px solid ${grey["300"]}`,
    }}
  >
    <Tooltip title={tooltip || name} placement="top">
      <Typography fontSize={fontSize} variant="body2">
        {name.substr(0, 2).toUpperCase()}
      </Typography>
    </Tooltip>
  </Avatar>
);

export default DataRoomParticipantsManagerField;
