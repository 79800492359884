import { faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { FastField, useStatus } from "formik";
import ChipInput from "material-ui-chip-input";
import { memo, useState } from "react";

const useChipInputStyles = makeStyles((theme) => ({
  chipContainer: {
    minHeight: "auto",
    marginBottom: 0,
  },
}));

const useChipStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(3),
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: 0,
  },
  label: {
    padding: theme.spacing(0, 1, 0, 1),
    fontSize: "0.875rem",
    lineHeight: "1rem",
  },
}));

const useInputLabelStyles = makeStyles((theme) => ({
  asterisk: {
    fontSize: "1.25em",
    lineHeight: "50%",
    color: "red",
  },
}));

const useInputStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 0),
    "&:not(:first-child)": {
      marginTop: theme.spacing(0.25),
    },
  },
  multiline: {
    padding: theme.spacing(0.5, 0),
  },
  input: {
    fontSize: "1rem",
    lineHeight: "1.25rem !important",
    minHeight: "1.25rem !important",
    padding: 0,
  },
}));

const useInlineIconButtonStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
  },
}));

const DataRoomFormDataAnalystsField = memo(({ tableIndex }) => {
  const chipInputClasses = useChipInputStyles();
  const chipClasses = useChipStyles();
  const inputLabelClasses = useInputLabelStyles();
  const inputClasses = useInputStyles();
  const inlineIconButtonClasses = useInlineIconButtonStyles();
  const [{ isEditing }] = useStatus();
  const [isEmpty, setIsEmpty] = useState(true);
  const [inputRef, setInputRef] = useState(null);
  const getHelperTextFromErrors = (errors) =>
    (errors ? (Array.isArray(errors) ? errors : [errors]) : [])
      .filter(Boolean)
      .join(", ");
  const getNextFieldValue = (currentValues, nextValues) => {
    const temp = [
      ...new Set(
        [...currentValues, ...nextValues].map((e) => e.trim().toLowerCase())
      ),
    ];
    return temp.sort();
  };
  return (
    <FastField name={`tables.${tableIndex}.dataProviders`}>
      {({ field, meta: { error, touched, value } }) => {
        const providersCount = value?.length || 0;
        return (
          <ChipInput
            inputRef={setInputRef}
            blurBehavior="ignore"
            clearInputValueOnChange
            chipRenderer={(
              {
                value,
                isFocused,
                isDisabled,
                isReadOnly,
                handleClick,
                handleDelete,
                className,
              },
              key
            ) => (
              <Chip
                key={key}
                className={className}
                classes={chipClasses}
                variant="outlined"
                style={{
                  pointerEvents: isDisabled || isReadOnly ? "none" : undefined,
                  backgroundColor: isFocused ? "lightgrey" : "transparent",
                }}
                icon={
                  <FontAwesomeIcon
                    fixedWidth
                    icon={faUserCircle}
                    style={{ fontSize: "1rem" }}
                  />
                }
                deleteIcon={
                  <FontAwesomeIcon
                    fixedWidth
                    icon={faTimes}
                    style={{ fontSize: "1rem" }}
                  />
                }
                clickable={false}
                onClick={!isEditing ? null : handleClick}
                onDelete={isEditing ? handleDelete : null}
                label={value}
              />
            )}
            label={`Data providers (${providersCount}) — When the data room gets published, the data providers will be invited by email.`}
            placeholder="Type an email address and press Enter or Space to add it, or paste a list of email addresses..."
            alwaysShowPlaceholder
            fullWidth
            fullWidthInput
            classes={chipInputClasses}
            disableUnderline
            InputProps={{
              classes: inputClasses,
              className: "MuiInput-underline",
              color: "secondary",
              style: {
                minWidth: "100%",
                display: isEditing ? "inline-flex" : "none",
              },
              disableUnderline: !isEditing,
              readOnly: !isEditing,
              disabled: !isEditing,
              error: touched && Boolean(error),
              onPaste: (event) => {
                const newValues = (event.clipboardData || window.clipboardData)
                  .getData("text")
                  .split("\n")
                  .map((value) => value.split(" "))
                  .flat();
                if (newValues.length > 1) {
                  field.onChange(getNextFieldValue(field.value, newValues));
                  setIsEmpty(true);
                  event.preventDefault();
                }
              },
              endAdornment: (
                <Box
                  sx={{
                    display: "flex",
                    marginTop: -0.5,
                    marginLeft: 0.25,
                    marginBottom: -0.5,
                  }}
                >
                  {isEditing && !isEmpty && (
                    <Tooltip
                      title={
                        <Box>
                          <Box>Add email to the</Box>
                          <Box>participants list</Box>
                          <Box style={{ opacity: 0.5 }}>Return / Space</Box>
                        </Box>
                      }
                      placement="top"
                    >
                      <IconButton
                        color="inherit"
                        classes={inlineIconButtonClasses}
                        TouchRippleProps={{
                          center: false,
                        }}
                        onClick={() => {
                          field.onChange(
                            getNextFieldValue(field.value, [inputRef.value])
                          );
                          setIsEmpty(true);
                        }}
                      >
                        <FontAwesomeIcon fixedWidth icon={faPlus} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              ),
            }}
            InputLabelProps={{
              color: "secondary",
              shrink: true,
              required: isEditing,
              disabled: !isEditing,
              classes: inputLabelClasses,
              error: touched && Boolean(error),
            }}
            helperText={touched && getHelperTextFromErrors(error)}
            FormHelperTextProps={{
              error: touched && Boolean(error),
              style: { marginBottom: 0 },
            }}
            newChipKeyCodes={[13, 32]}
            newChipKeys={["Enter", " "]}
            onAdd={(newValue, index) => {
              field.onChange(getNextFieldValue(field.value, [newValue]));
              setIsEmpty(true);
            }}
            onDelete={(newValue, index) => {
              field.onChange([
                ...new Set(
                  [
                    ...field.value.slice(0, index),
                    ...field.value.slice(index + 1),
                  ].sort()
                ),
              ]);
            }}
            onUpdateInput={(event) => setIsEmpty(!event.target.value?.trim())}
            {...field}
          />
        );
      }}
    </FastField>
  );
});

export default DataRoomFormDataAnalystsField;
