import { gql } from "@apollo/client";

export const DELETE_DATA_ROOM_BY_ID = gql`
  mutation deleteDataRoomById($input: DeleteDataRoomByIdInput!) {
    deleteDataRoomById(input: $input) {
      dataRoom {
        id
        dataRoomId
      }
      clientMutationId
    }
  }
`;
