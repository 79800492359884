import { makeStyles } from "@material-ui/core/styles";

export const useViewSettingsStyles = makeStyles((theme) => ({
  groupName: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    lineHeight: 1,
    fontWeight: 500,
    fontSize: "small",
  },
}));
