import { faEnvelopeOpenText } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Slide,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useDialogActionsStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 3, 2, 3),
    justifyContent: "center",
  },
}));

const useButtonStyles = makeStyles((theme) => ({
  root: {
    borderColor: theme.palette.text.disabled,
  },
}));

const useUnauthorizedDialogStyles = makeStyles((theme) => ({
  iconTitleWrapper: {
    padding: theme.spacing(3, 3, 2, 3),
  },
  iconWrapper: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  icon: {
    color: grey["400"],
  },
  title: {
    lineHeight: 1.25,
  },
  dialogContent: {
    padding: theme.spacing(0, 3, 0, 3),
  },
}));

const UnauthorizedDialog = ({ open, onClose }) => {
  const unauthorizedDialogClasses = useUnauthorizedDialogStyles();
  const dialogActionsClasses = useDialogActionsStyles();
  const buttonClasses = useButtonStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Slide}
      TransitionProps={{ direction: "up" }}
    >
      <Box className={unauthorizedDialogClasses.iconTitleWrapper}>
        <Box className={unauthorizedDialogClasses.iconWrapper}>
          <FontAwesomeIcon
            fixedWidth
            icon={faEnvelopeOpenText}
            size="5x"
            className={unauthorizedDialogClasses.icon}
          />
        </Box>
        <Typography
          variant="h6"
          color="textPrimary"
          align="center"
          className={unauthorizedDialogClasses.title}
        >
          <strong>Please verify your email first</strong>
        </Typography>
      </Box>
      <DialogContent className={unauthorizedDialogClasses.dialogContent}>
        <Typography variant="body2" align="center" component="div">
          We must be sure that the email address you specified during the sign
          up process really belongs to you, therefore we've sent you a
          verification link. Please follow that link first and then come back
          and try to sign in again.
        </Typography>
      </DialogContent>
      <DialogActions classes={dialogActionsClasses}>
        <Button
          classes={buttonClasses}
          color="inherit"
          variant="outlined"
          onClick={onClose}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnauthorizedDialog;
