import { makeStyles } from "@material-ui/core/styles";

export const useAccountMenuStyles = makeStyles((theme) => ({
  accountButton: {
    marginRight: theme.spacing(1),
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 2, 0, 2),
  },
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
  },
}));
