import { dataRoomMode, questionKind } from "constants/index";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { FieldArray, useFormikContext } from "formik";
import { useState } from "react";
import { Question } from "components";

const useInputStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 0),
  },
  multiline: {
    padding: theme.spacing(0.5, 0),
  },
  input: {
    fontSize: "1rem",
    lineHeight: "1.25rem !important",
    minHeight: "1.25rem !important",
    padding: 0,
  },
}));

const useInlineIconButtonStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
  },
}));

const Questions = ({ results, answersState }) => {
  const inputClasses = useInputStyles();
  const inlineIconButtonClasses = useInlineIconButtonStyles();
  const {
    status: { mode },
    values: { questions },
    setFieldValue,
  } = useFormikContext();
  const defaultQuestion = {
    kind: questionKind.NUMERICAL,
    name: "",
    description: "",
    minimumNumberOfValidSubmissions: 1,
    isRequired: false,
    unit: "",
    minValueValidation: null,
    maxValueValidation: null,
    lowerPercentileFilter: 0,
    upperPercentileFilter: 1,
    rejectionFeedback: 0,
    aggregations: ["count", "sum", "avg", "min", "max"],
  };
  const [questionInputValue, setQuestionInputValue] = useState("");
  return (
    <FieldArray
      name="questions"
      render={({ push, remove }) => (
        <Box>
          {(questions || []).map((question, questionIndex) => (
            <Question
              key={questionIndex}
              question={question}
              questionIndex={questionIndex}
              result={results && results[questionIndex]}
              answerState={answersState && answersState[questionIndex]}
              onDelete={remove}
              setFieldValue={setFieldValue}
            />
          ))}
          {[dataRoomMode.UPDATE].includes(mode) && (
            <Box sx={{ pt: 1, pr: 8, pb: 1, pl: 8 }}>
              <TextField
                variant="standard"
                color="secondary"
                placeholder="Type a question and press Enter to add it, or paste a list of questions..."
                fullWidth
                onKeyDown={(event) => {
                  if (
                    event.key === "Enter" &&
                    event.target.value.trim() !== ""
                  ) {
                    push({
                      ...defaultQuestion,
                      name: event.target.value.trim(),
                    });
                    setQuestionInputValue("");
                  }
                }}
                InputProps={{
                  classes: inputClasses,
                  value: questionInputValue,
                  onKeyDown: (event) => {
                    if (event.key === " " && !event.target.value) {
                      event.preventDefault();
                    }
                  },
                  onPaste: (event) => {
                    const newValues = (
                      event.clipboardData || window.clipboardData
                    )
                      .getData("text")
                      .split("\n");
                    if (newValues.length > 1) {
                      newValues.map((value) =>
                        push({
                          ...defaultQuestion,
                          name: value.trim(),
                        })
                      );
                      event.preventDefault();
                    }
                  },
                  onChange: (event) => {
                    setQuestionInputValue(event.target.value);
                  },
                  endAdornment: (
                    <Box
                      sx={{
                        display: "flex",
                        marginTop: -0.5,
                        marginLeft: 0.25,
                        marginBottom: -0.5,
                      }}
                    >
                      {[dataRoomMode.UPDATE].includes(mode) &&
                        questionInputValue.trim() && (
                          <Tooltip
                            title={
                              <Box>
                                <Box>Add question</Box>
                                <Box style={{ opacity: 0.5 }}>Return</Box>
                              </Box>
                            }
                            placement="top"
                          >
                            <IconButton
                              color="inherit"
                              classes={inlineIconButtonClasses}
                              onClick={() => {
                                push({
                                  ...defaultQuestion,
                                  name: questionInputValue.trim(),
                                });
                                setQuestionInputValue("");
                              }}
                              TouchRippleProps={{
                                center: false,
                              }}
                            >
                              <FontAwesomeIcon fixedWidth icon={faPlus} />
                            </IconButton>
                          </Tooltip>
                        )}
                    </Box>
                  ),
                }}
              />
            </Box>
          )}
        </Box>
      )}
    />
  );
};

export default Questions;
