"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Endpoints = exports.API = void 0;
const axios = __importStar(require("axios"));
const API_PREFIX = "/api/core";
var Endpoints;
(function (Endpoints) {
    // System
    Endpoints["SYSTEM_ENCLAVE_IDENTIFIERS"] = "/system/enclave-identifiers";
    Endpoints["SYSTEM_CERTIFICATE_AUTHORITY"] = "/system/certificate-authority";
    // Session
    Endpoints["SESSIONS"] = "/sessions";
    Endpoints["SESSION"] = "/session/:sessionId";
    Endpoints["SESSION_FATQUOTE"] = "/session/:sessionId/fatquote";
    Endpoints["SESSION_MESSAGES"] = "/session/:sessionId/messages";
    // User
    Endpoints["USERS_COLLECTION"] = "/users";
    Endpoints["USER"] = "/user/:userId";
    Endpoints["USER_FILES_COLLECTION"] = "/user/:userId/files";
    Endpoints["USER_FILE"] = "/user/:userId/file/:fileId";
    Endpoints["USER_FILE_CHUNK"] = "/user/:userId/file/:fileId/chunk/:chunkHash";
    Endpoints["USER_CERTIFICATE"] = "/user/:userId/certificate";
})(Endpoints || (Endpoints = {}));
exports.Endpoints = Endpoints;
class API {
    constructor(token, clientId, host, port, useTLS) {
        const protocol = useTLS ? "https" : "http";
        const session = axios.default.create({
            baseURL: `${protocol}://${host}:${port}${API_PREFIX}`,
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            headers: {
                "Authorization": `Bearer ${token.value}`,
                "Authorization-Type": token.type,
                "Authorization-Client": clientId
            }
        });
        this.session = session;
    }
    post(url, reqBody, headers = {}, responseType = "json") {
        return __awaiter(this, void 0, void 0, function* () {
            return this.session.post(url, reqBody, {
                headers,
                responseType,
            });
        });
    }
    put(url, reqBody, headers = {}, responseType = "json") {
        return __awaiter(this, void 0, void 0, function* () {
            return this.session.put(url, reqBody, {
                headers,
                responseType,
            });
        });
    }
    patch(url, reqBody, headers = {}, responseType = "json") {
        return __awaiter(this, void 0, void 0, function* () {
            return this.session.patch(url, reqBody, {
                headers,
                responseType,
            });
        });
    }
    get(url, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.session.get(url, options);
        });
    }
    head(url, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.session.head(url, options);
        });
    }
    delete(url, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.session.delete(url, options);
        });
    }
}
exports.API = API;
