import { Grid } from "@material-ui/core";
import { DataRoomCard } from "components";

export const DataRoomCardGridWrapper = ({ children }) => (
  <Grid item xs={12} sm={6} md={4}>
    {children}
  </Grid>
);

const DataRoomsCards = ({ loading, nodes = [], StartingCard }) => (
  <Grid container style={{ marginTop: "0" }} spacing={3}>
    {!loading && StartingCard ? (
      <DataRoomCardGridWrapper key={-1}>{StartingCard}</DataRoomCardGridWrapper>
    ) : undefined}
    {(nodes.length === 0 ? [...Array(3).keys()] : nodes).map(
      (dataRoom, index) => (
        <DataRoomCardGridWrapper key={index}>
          <DataRoomCard loading={loading} dataRoom={dataRoom} />
        </DataRoomCardGridWrapper>
      )
    )}
  </Grid>
);

export default DataRoomsCards;
