"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BitFlags = void 0;
class BitFlags {
    constructor(s, size) {
        this.flags = s;
        this.size = size;
    }
    static fromBigInt(i, size) {
        // get binary representation
        let s = i.toString(2);
        // padding
        while (s.length < (size || 2)) {
            s = "0" + s;
        }
        // to little endian
        s = s
            .split("")
            .reverse()
            .join("");
        return new BitFlags(s, size);
    }
    contains(flag) {
        const char = this.flags[flag];
        return char === "0" ? false : true;
    }
}
exports.BitFlags = BitFlags;
