import { makeStyles } from "@material-ui/core";

export const usePaginationMenuStyles = makeStyles((theme) => ({
  moreButton: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
  },
  groupName: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    lineHeight: 1,
    fontWeight: 500,
    fontSize: "small",
  },
}));
