import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { DataRoomActions, DataRoomMeta, Name } from "legacy/components";
import { memo } from "react";
import { useBlockStyles } from "styles/block";

const QueryBlock = ({
  query,
  className,
  setCondition = () => {},
  mergeCondition = () => {},
  withMeta = true,
  withTags = true,
}) => {
  const { id, queryId, sql } = query || {};
  const blockClasses = useBlockStyles();
  return (
    <div className={clsx(blockClasses.wrapper, className)}>
      <div className={blockClasses.nameMetaActionsWrapper}>
        <div className={blockClasses.nameActionsWrapper}>
          <Typography
            variant="body1"
            component="div"
            className={blockClasses.nameWrapper}
          >
            <Name name={sql} id={queryId} href={`/datarooms/${queryId}`} />
          </Typography>
          <div className={blockClasses.actionsWrapper}>
            <DataRoomActions id={id} queryId={queryId} inline />
          </div>
        </div>
        {(withTags || withMeta) && (
          <DataRoomMeta
            dataRoom={query}
            withTags={withTags}
            withMeta={withMeta}
          />
        )}
      </div>
    </div>
  );
};

export default memo(QueryBlock);
