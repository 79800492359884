import { faFileContract, faFileCsv } from "@fortawesome/pro-duotone-svg-icons";
import {
  faExclamationCircle,
  faLockAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { useBoolean } from "ahooks";
import { saveAs } from "file-saver";
import { Form, useFormikContext } from "formik";
import { memo } from "react";
import {
  DataRoomActions,
  DataRoomFormPasswordField,
  DataRoomPublishDialog,
  DescriptionField,
  Queries,
  Status,
  Tables,
  TitleField,
} from "components";

const usePaperClasses = makeStyles((theme) => ({
  root: {
    // border: "1px solid transparent",
    // transitionProperty: "all",
    // "&:not(:hover)": {
    boxShadow: "none",
    border: "1px solid rgba(0,0,0,0.12)",
    // },
  },
}));

const useButtonStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
  },
  label: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: 700,
    letterSpacing: "-0.25px",
    "& > svg": {
      marginRight: theme.spacing(0.5),
      fontSize: "1.5em",
    },
    "& .arrow": {
      opacity: 0.25,
      display: "flex",
      position: "absolute",
      top: "50%",
      pointerEvents: "none",
      transition: "all 0.2s linear",
      transformOrigin: "center",
    },
    "& .arrow:last-child": {
      left: "100%",
      transform: `translate(${theme.spacing(1)}, -50%)`,
    },
    "&:hover .arrow:last-child": {
      opacity: 1,
    },
    "& .arrow:first-child": {
      right: "100%",
      transform: `translate(-${theme.spacing(1)}, -50%)`,
    },
    "&:hover .arrow:first-child": {
      opacity: 1,
    },
  },
}));

const DataRoomForm = memo(
  ({
    hasSubmitted,
    dataRoom,
    isOwner,
    ingestions,
    results,
    answersState,
    onImportCsvClick,
    onRunQueryClick,
    children,
  }) => {
    const paperClasses = usePaperClasses();
    const buttonClasses = useButtonStyles();
    const {
      values,
      errors,
      submitForm,
      isValid,
      isValidating,
      isSubmitting,
      status: { isEditing },
    } = useFormikContext();
    const { name, tables, queries } = values;
    const isPosting = !isValidating && isSubmitting;
    const { id, isPublished, updatedAt, hasPassword } = dataRoom;
    const [
      isPublishDialogOpen,
      { setTrue: openPublishDialog, setFalse: closePublishDialog },
    ] = useBoolean(false);
    const onPublishClick = () => {
      closePublishDialog();
      submitForm();
    };
    const downloadAsCsvClick = () => {
      results.forEach((result, index) => {
        const file = new File(
          [result.data],
          `${values.name} — Results — ${index + 1}.csv`,
          {
            type: "application/octet-stream;charset=utf-8",
          }
        );
        saveAs(file);
      });
    };
    return (
      <Form>
        <Container
          maxWidth="md"
          style={{ paddingTop: "64px", paddingBottom: "64px" }}
        >
          <Paper elevation={24} classes={paperClasses}>
            <Box sx={{ pt: 8, pr: 8, pb: 1, pl: 8 }}>
              <Box
                sx={{
                  display: "flex",
                  margin: "-4px -4px 32px -4px",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box style={{ mr: 0.5 }}>
                    <Status
                      strong
                      isPublished={isPublished}
                      updatedAt={updatedAt}
                    />
                  </Box>
                </Box>
                <DataRoomActions name={name} id={id} inline isOwner={isOwner} />
              </Box>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                  <TitleField />
                </Grid>
                <Grid item xs={12}>
                  <DescriptionField name="description" />
                </Grid>
                {!(isPublished && !hasPassword) && (
                  <Grid item xs={12}>
                    <DataRoomFormPasswordField isRequired={hasPassword} />
                  </Grid>
                )}
              </Grid>
            </Box>
            <Tables
              id={id}
              ingestions={ingestions}
              answersState={answersState}
              onImportCsvClick={onImportCsvClick}
            />
            <Queries
              id={id}
              results={results}
              answersState={answersState}
              onRunQueryClick={onRunQueryClick}
            />
            {isEditing && (
              <AppBar
                color="transparent"
                position="sticky"
                style={{
                  top: "auto",
                  bottom: 0,
                  paddingTop: "4rem",
                }}
                elevation={0}
              >
                <Divider />
                <Box
                  sx={{
                    paddingTop: 2,
                    paddingRight: 8,
                    paddingBottom: 2,
                    paddingLeft: 8,
                    backdropFilter: "blur(6px)",
                    display: "flex",
                    justifyContent: isValid ? "flex-end" : "flex-start",
                  }}
                >
                  {isValidating ? null : isValid ? (
                    <Box>
                      <DataRoomPublishDialog
                        name={name}
                        open={isPublishDialogOpen}
                        onCancel={closePublishDialog}
                        onConfirm={onPublishClick}
                      />
                      <Button
                        classes={buttonClasses}
                        type="button"
                        variant="text"
                        color="inherit"
                        onClick={openPublishDialog}
                        disabled={
                          !isValid ||
                          isValidating ||
                          isSubmitting ||
                          tables.length === 0 ||
                          queries.length === 0 ||
                          isPosting
                        }
                      >
                        {!isValidating && isSubmitting ? (
                          <CircularProgress
                            size={24}
                            color="inherit"
                            thickness={3}
                            style={{ marginRight: 4 }}
                          />
                        ) : (
                          <FontAwesomeIcon icon={faFileContract} />
                        )}
                        Encrypt and publish
                        <Box className="arrow">
                          <FontAwesomeIcon fixedWidth icon={faLockAlt} />
                        </Box>
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        height: "2rem",
                        overflow: "auto",
                        display: "flex",
                        alignItems: "center",
                        color: "#f44336",
                      }}
                    >
                      <FontAwesomeIcon
                        fixedWidth
                        icon={faExclamationCircle}
                        style={{ fontSize: "1.25rem", marginRight: "0.25em" }}
                      />
                      There{" "}
                      {Object.keys(errors).length === 1
                        ? `is 1 error`
                        : `are ${Object.keys(errors).length} errors`}{" "}
                      in the form
                    </Box>
                  )}
                </Box>
              </AppBar>
            )}
            {!isEditing && (
              <AppBar
                color="transparent"
                position="sticky"
                style={{
                  top: "auto",
                  bottom: 0,
                  paddingTop: "4rem",
                }}
                elevation={0}
              >
                <Divider />
                {results ? (
                  <Box
                    sx={{
                      paddingTop: 2,
                      paddingRight: 8,
                      paddingBottom: 2,
                      paddingLeft: 8,
                      backdropFilter: "blur(6px)",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      color="inherit"
                      type="button"
                      variant="text"
                      classes={buttonClasses}
                      disabled={!isValid}
                      onClick={downloadAsCsvClick}
                    >
                      <FontAwesomeIcon icon={faFileCsv} />
                      Download results as CSV
                    </Button>
                  </Box>
                ) : null}
              </AppBar>
            )}
            {children}
          </Paper>
        </Container>
      </Form>
    );
  }
);

export default DataRoomForm;
