import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, MenuList, Popover, Tooltip } from "@material-ui/core";
import { usePopupState } from "material-ui-popup-state/hooks";
import { memo } from "react";
import { PaginationMenuItem } from "components";
import { usePaginationMenuStyles } from "./PaginationMenu.styles";

const PAGE_SIZE_OPTIONS = [12, 24, 48, 96];

const PaginationMenu = ({ pageSize, onChange }) => {
  const paginationMenuClasses = usePaginationMenuStyles();
  const { anchorEl, isOpen, open, close } = usePopupState({
    variant: "popover",
    popupId: "actions-menu",
  });
  const menuOpen = (event) => {
    event.stopPropagation();
    open(event.currentTarget);
  };
  const menuClose = (event) => {
    event.stopPropagation();
    close();
  };
  const menuItemClick = (pageSizeOption, event) => {
    event.stopPropagation();
    onChange(pageSizeOption);
    close();
  };
  return (
    <>
      <Tooltip title="More" placement="top">
        <IconButton
          color="inherit"
          onClick={menuOpen}
          className={paginationMenuClasses.moreButton}
          TouchRippleProps={{
            center: false,
          }}
        >
          <FontAwesomeIcon fixedWidth icon={faEllipsisV} />
        </IconButton>
      </Tooltip>
      <Popover
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={menuClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div className={paginationMenuClasses.groupName}>Show up to</div>
        <MenuList>
          {PAGE_SIZE_OPTIONS.map((pageSizeOption) => (
            <PaginationMenuItem
              key={pageSizeOption}
              isSelected={pageSize === pageSizeOption}
              label={pageSizeOption}
              onClick={menuItemClick.bind(null, pageSizeOption)}
            />
          ))}
        </MenuList>
      </Popover>
    </>
  );
};

export default memo(PaginationMenu);
