import { TableCell, TableRow } from "@material-ui/core";
import clsx from "clsx";
import { TableSortLabelWithOrderBy } from "legacy/components";
import { useDataRoomsTableCellStyles } from "legacy/components/dataRooms/DataRoomsTable/DataRoomsTable.styles";
import { useCustomTableStyles } from "styles/table";

const EmptyTableCell = (
  <TableCell component="div" padding="checkbox" style={{ width: "2rem" }} />
);

const QueriesTableHeadRow = ({
  orderBy,
  setOrderBy,
  Select,
  ViewSettingsMenu,
  columnsWithSelections,
}) => {
  const customTableClasses = useCustomTableStyles();
  const dataRoomsTableCellClasses = useDataRoomsTableCellStyles();
  const { isSelected } = columnsWithSelections;
  return (
    <TableRow component="div">
      {Select ? (
        <TableCell component="div" padding="checkbox">
          <Select all />
        </TableCell>
      ) : null}
      <TableCell component="div" padding="none">
        {isSelected("dataRoom") && (
          <TableCell
            component="div"
            className={clsx(
              dataRoomsTableCellClasses.dataRoomWrapper,
              customTableClasses.MuiTableCell,
              customTableClasses.MuiTableCellAsEntity
            )}
          >
            <TableSortLabelWithOrderBy
              {...{
                label: "Query",
                orderByAsc: ["SQL_ASC"],
                orderByDesc: ["SQL_DESC"],
                orderBy,
                setOrderBy,
              }}
            />
          </TableCell>
        )}
        {isSelected("owner") && (
          <TableCell
            component="div"
            className={clsx(
              dataRoomsTableCellClasses.contactWrapper,
              customTableClasses.MuiTableCell,
              customTableClasses.MuiTableCellAsEntity
            )}
          >
            Owner
          </TableCell>
        )}
        {isSelected("participants") && (
          <TableCell
            component="div"
            className={clsx(
              dataRoomsTableCellClasses.contactWrapper,
              customTableClasses.MuiTableCell,
              customTableClasses.MuiTableCellAsEntity
            )}
          >
            Participants
          </TableCell>
        )}
      </TableCell>
      {ViewSettingsMenu ? (
        <TableCell component="div" padding="checkbox">
          <ViewSettingsMenu />
        </TableCell>
      ) : (
        EmptyTableCell
      )}
    </TableRow>
  );
};

export default QueriesTableHeadRow;
