import { gql } from "@apollo/client";
import { DATA_ROOM_EVERYTHING } from "gqls";

export const DATA_ROOM = gql`
  query dataRoom($dataRoomId: UUID!) {
    dataRoom(dataRoomId: $dataRoomId) {
      ...DataRoomEverything
    }
  }
  ${DATA_ROOM_EVERYTHING}
`;
