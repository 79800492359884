import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Link, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useCopyToClipboard } from "react-use";
import { useIdStyles } from "./Id.styles";

const Id = ({ id, href, className }) => {
  const idClasses = useIdStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [{ value: copiedToClipboard }, copyToClipboard] = useCopyToClipboard();
  useEffect(() => {
    if (copiedToClipboard) enqueueSnackbar("Copied to clipboard");
  }, [enqueueSnackbar, copiedToClipboard]);
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={
        <span className={idClasses.tooltipInnerWrapper}>
          <span className={idClasses.tooltipId}>{id}</span>
          <IconButton
            color="inherit"
            onClick={() => copyToClipboard(id)}
            className={idClasses.tooltipCopyId}
            TouchRippleProps={{
              center: false,
            }}
          >
            <FontAwesomeIcon fixedWidth icon={faCopy} />
          </IconButton>
        </span>
      }
      placement="top"
      enterDelay={1500}
      enterNextDelay={250}
    >
      {href ? (
        <Link color="textPrimary" href={href}>
          <Typography
            noWrap
            color="textPrimary"
            variant="body2"
            component="span"
            className={clsx(className, idClasses.id)}
          >
            {id?.substring(0, 8)}
          </Typography>
        </Link>
      ) : (
        <Typography
          noWrap
          color="textPrimary"
          variant="body2"
          component="span"
          className={clsx(className, idClasses.id)}
        >
          {id?.substring(0, 8)}
        </Typography>
      )}
    </Tooltip>
  );
};

export default Id;
