import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { useBoolean } from "ahooks";
import { FastField } from "formik";

const useInlineIconButtonStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
  },
}));

const useInputLabelStyles = makeStyles((theme) => ({
  asterisk: {
    fontSize: "1.25em",
    lineHeight: "50%",
    color: "red",
  },
}));

const DataRoomFormPasswordField = ({ isRequired }) => {
  const inlineIconButtonClasses = useInlineIconButtonStyles();
  const inputLabelClasses = useInputLabelStyles();
  const [showPassword, { toggle: toggleShowPassword }] = useBoolean(false);
  return (
    <FastField name="password">
      {({ field }) => (
        <TextField
          variant="standard"
          color="secondary"
          fullWidth
          required={isRequired}
          label="Password"
          InputLabelProps={{
            classes: inputLabelClasses,
          }}
          helperText={
            isRequired
              ? "This data room protected by the password, please provide it to Connect Data or Run query"
              : undefined
          }
          InputProps={{
            placeholder: "Type a password to protect data room",
            type: showPassword ? "text" : "password",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  classes={inlineIconButtonClasses}
                  TouchRippleProps={{
                    center: false,
                  }}
                  onClick={() => toggleShowPassword()}
                  onMouseDown={(event) => event.preventDefault()}
                >
                  <FontAwesomeIcon
                    fixedWidth
                    icon={showPassword ? faEye : faEyeSlash}
                    style={{ fontSize: "1rem" }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...field}
        />
      )}
    </FastField>
  );
};

export default DataRoomFormPasswordField;
