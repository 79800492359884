"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Quote = exports.Verification = exports.IASAttributeFlags = void 0;
const forge = __importStar(require("node-forge"));
const Intel_SGX_Attestation_RootCA_1 = require("./Intel_SGX_Attestation_RootCA");
const bitflags_1 = require("./bitflags");
function getU8Slice(dataView, offset, length) {
    let buffer = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        buffer[i] = dataView.getUint8(offset + i);
    }
    return buffer;
}
class Quote {
    constructor(base64Src) {
        let data = forge.util.binary.base64.decode(base64Src);
        const bytesBuffer = data.buffer.slice(data.byteOffset, data.byteOffset + data.byteLength);
        let dataView = new DataView(bytesBuffer);
        this.quoteVersion = dataView.getUint16(0, true);
        this.quoteSignType = dataView.getUint16(2, true);
        this.quoteEpidGroupId = getU8Slice(dataView, 4, 4);
        this.quoteQeSvn = dataView.getUint16(8, true);
        this.quotePceSvn = dataView.getUint16(10, true);
        this.quoteXeid = dataView.getUint32(12, true);
        this.quoteBasename = getU8Slice(dataView, 16, 32);
        let reportBytesBuffer = data.buffer.slice(data.byteOffset + 48, data.byteOffset + 48 + 384);
        let reportDataview = new DataView(reportBytesBuffer);
        this.reportCpusvn = getU8Slice(reportDataview, 0, 16);
        this.reportFlags = reportDataview.getUint32(48, true);
        this.reportMrenclave = getU8Slice(reportDataview, 64, 32);
        this.reportMrsigner = getU8Slice(reportDataview, 128, 32);
        this.reportIsvprodid = reportDataview.getUint16(256, true);
        this.reportIsvsvn = reportDataview.getUint16(258, true);
        this.reportData = getU8Slice(reportDataview, 320, 64);
    }
}
exports.Quote = Quote;
class Verification {
    constructor() {
        this.acceptDebug = false;
        this.acceptGroupOutOfDate = false;
        this.acceptConfigurationNeeded = false;
        this.rootCaPem = Intel_SGX_Attestation_RootCA_1.rootCA;
    }
    withExpectedMeasurement(measurement) {
        this.expectedMeasurement = measurement;
        return this;
    }
    withAcceptDebug(value) {
        this.acceptDebug = value;
        return this;
    }
    withAcceptGroupOutOfDate(value) {
        this.acceptGroupOutOfDate = value;
        return this;
    }
    withAcceptConfigurationNeeded(value) {
        this.acceptConfigurationNeeded = value;
        return this;
    }
    withRootCaPem(value) {
        this.rootCaPem = value;
        return this;
    }
    /**
     * Throws if verification fails
     * @param  {string} certificate
     * @param  {string} iasOkResp
     * @param  {string} iasOkSig
     * @returns Quote
     */
    verify(certificate, iasOkResp, iasOkSig) {
        const rootCA = this.rootCaPem;
        // Verify certificate
        const caStore = forge.pki.createCaStore();
        caStore.addCertificate(rootCA);
        const chain = forge.pki.certificateFromPem(certificate);
        const chainIsVerified = forge.pki.verifyCertificateChain(caStore, [chain]);
        if (!chainIsVerified) {
            throw new Error("Could not verify certificate");
        }
        // Verify signature
        const publicKey = chain.publicKey;
        const md = forge.md.sha256.create();
        md.update(iasOkResp);
        const sigIsVerified = publicKey.verify(md.digest().bytes(), forge.util.binary.raw.encode(iasOkSig));
        if (!sigIsVerified) {
            throw new Error("Could not verify signature");
        }
        const body = JSON.parse(iasOkResp);
        const quoteBody = new Quote(body.isvEnclaveQuoteBody);
        const isvEnclaveQuoteStatus = body.isvEnclaveQuoteStatus;
        switch (isvEnclaveQuoteStatus) {
            case "OK":
                // all good. Only accept this status.
                break;
            case "CONFIGURATION_NEEDED":
                if (!this.acceptConfigurationNeeded) {
                    throw new Error("Status CONFIGURATION_NEEDED not accepted");
                }
                break;
            case "GROUP_OUT_OF_DATE":
                if (!this.acceptGroupOutOfDate) {
                    throw new Error("Status GROUP_OUT_OF_DATE not accepted");
                }
                break;
            default: {
                throw new Error(`Status ${isvEnclaveQuoteStatus} not accepted. Check: https://api.trustedservices.intel.com/documents/sgx-attestation-api-spec.pdf`);
            }
        }
        const flags = bitflags_1.BitFlags.fromBigInt(quoteBody.reportFlags, 8);
        if (!flags.contains(IASAttributeFlags.INIT)) {
            throw new Error("INIT flat not set in report");
        }
        if (!flags.contains(IASAttributeFlags.MODE64BIT)) {
            throw new Error("32bit report not accepted");
        }
        if (flags.contains(IASAttributeFlags.DEBUG)) {
            if (this.acceptDebug) {
                console.warn("DEBUG quote is being accepted, quote is NOT to be trusted");
            }
            else {
                throw new Error("DEBUG flag set in report. Not accepted");
            }
        }
        if (this.expectedMeasurement !== undefined) {
            const measurement = forge.util.binary.hex.encode(quoteBody.reportMrenclave);
            if (measurement !== this.expectedMeasurement) {
                throw new Error(`Wrong measurement, expected measurement: ${this.expectedMeasurement}, actual measurement: ${measurement}`);
            }
        }
        return quoteBody;
    }
}
exports.Verification = Verification;
var IASAttributeFlags;
(function (IASAttributeFlags) {
    IASAttributeFlags[IASAttributeFlags["INIT"] = 0] = "INIT";
    IASAttributeFlags[IASAttributeFlags["DEBUG"] = 1] = "DEBUG";
    IASAttributeFlags[IASAttributeFlags["MODE64BIT"] = 2] = "MODE64BIT";
    IASAttributeFlags[IASAttributeFlags["PROVISIONKEY"] = 3] = "PROVISIONKEY";
    IASAttributeFlags[IASAttributeFlags["EINITTOKENKEY"] = 4] = "EINITTOKENKEY";
})(IASAttributeFlags || (IASAttributeFlags = {}));
exports.IASAttributeFlags = IASAttributeFlags;
