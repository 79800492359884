import { TableCell, TableRow } from "@material-ui/core";
import clsx from "clsx";
import {
  ContactBlock,
  DataRoomActions,
  DataRoomBlock,
} from "legacy/components";
import { useDataRoomsTableCellStyles } from "legacy/components/dataRooms/DataRoomsTable/DataRoomsTable.styles";
import { useCustomTableStyles } from "styles/table";

const DataRoomTableBodyRow = ({
  Select,
  node: dataRoom,
  node: { id, dataRoomId, param: { datasetsTotalCount } = {} },
  columnsWithSelections,
  viewsWithSelections,
  setCondition = () => {},
  mergeCondition = () => {},
  style,
  forceWideCells = false,
  withActions = true,
}) => {
  const customTableClasses = useCustomTableStyles({ forceWideCells });
  const dataRoomsTableCellClasses = useDataRoomsTableCellStyles();
  const { contact } = dataRoom || {};
  const onClickStopPropagation = (event) => event.stopPropagation();
  const { isSelected } = columnsWithSelections;
  const { selected } = viewsWithSelections;
  const withMeta = selected.includes("detailed");
  return (
    <TableRow component="div" onClick={onClickStopPropagation}>
      {Select && (
        <TableCell component="div" padding="checkbox">
          <Select id={dataRoomId} />
        </TableCell>
      )}
      <TableCell component="div" padding="none">
        {isSelected("dataRoom") && (
          <TableCell
            component="div"
            className={clsx(
              dataRoomsTableCellClasses.dataRoomWrapper,
              customTableClasses.MuiTableCell,
              customTableClasses.MuiTableCellAsEntity
            )}
          >
            <DataRoomBlock
              dataRoom={dataRoom}
              withMeta={withMeta}
              withTags={withMeta}
            />
          </TableCell>
        )}
        {isSelected("owner") && (
          <TableCell
            component="div"
            className={clsx(
              dataRoomsTableCellClasses.contactWrapper,
              customTableClasses.MuiTableCell,
              customTableClasses.MuiTableCellAsEntity
            )}
          >
            <ContactBlock
              contact={contact}
              withMeta={withMeta}
              withTags={withMeta}
            />
          </TableCell>
        )}
        {isSelected("participants") && (
          <TableCell
            component="div"
            className={clsx(
              dataRoomsTableCellClasses.contactWrapper,
              customTableClasses.MuiTableCell,
              customTableClasses.MuiTableCellAsEntity
            )}
          >
            <ContactBlock
              contact={contact}
              withMeta={withMeta}
              withTags={withMeta}
            />
          </TableCell>
        )}
      </TableCell>
      {withActions && (
        <TableCell component="div" padding="checkbox">
          <DataRoomActions id={id} dataRoomId={dataRoomId} />
        </TableCell>
      )}
    </TableRow>
  );
};

export default DataRoomTableBodyRow;
