import { gql } from "@apollo/client";

export const DATA_ROOM_EVERYTHING = gql`
  fragment DataRoomEverything on DataRoom {
    id
    dataRoomId
    name
    description
    tables
    queries
    isPublished
    isOwner
    isParticipant
    hasPassword
    updatedAt
    createdAt
    ownerEmail
    dataRoomShares(orderBy: [USER_EMAIL_ASC]) {
      nodes {
        dataRoomShareId
        userEmail
        hasSubmitted
      }
    }
    dataRoomHash
  }
`;
