import { makeStyles } from "@material-ui/core/styles";

export const useIdStyles = makeStyles((theme) => ({
  id: {
    fontFamily: "SFMono-Normal, Consolas, Liberation Mono, Menlo, monospace",
    fontSize: "0.75em",
    letterSpacing: "-0.05em !important",
    lineHeight: 1,
    borderRadius: theme.shape.borderRadius,
    opacity: 0.5,
    transition: "all 0.2s linear",
    "&:hover": {
      opacity: 1,
    },
  },
  tooltipInnerWrapper: {
    fontFamily: "SFMono-Normal, Consolas, Liberation Mono, Menlo, monospace",
    lineHeight: 1.3333,
    display: "flex",
    alignItems: "center",
  },
  tooltipId: {
    whiteSpace: "nowrap",
  },
  tooltipCopyId: {
    fontSize: "0.875rem",
    padding: theme.spacing(0.5),
    borderTopLeftRadius: 0,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: 0,
    minWidth: 0,
    margin: theme.spacing(-0.5, -1, -0.5, 0.5),
  },
}));
