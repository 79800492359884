import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import { SnackbarProvider } from "notistack";

const SnackbarWrapper = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      iconVariant={{
        error: (
          <Box marginRight="8px">
            <FontAwesomeIcon
              size="lg"
              icon={faCircleExclamation}
            ></FontAwesomeIcon>
          </Box>
        ),
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default SnackbarWrapper;
