import { useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";
import { useSelections } from "ahooks";
import { useCallback, useEffect, useState } from "react";
import { DataSearch, OrderByMenu, PaginationBlock, Select } from "components";
import { useDataStyles } from "./Data.styles";

const Data = ({
  query,
  variables: propVariables = {},
  filterBy = [],
  orderByOptions = [],
  pageSize: pageSizeProp = 12,
  dataKey,
  dataIdKey,
  Actions,
  BulkActions,
  withSearch = false,
  withFilter = false,
  withSelections = false,
  withMultiselections = false,
  DataView,
  EmptyDataView,
  onSelect = () => {},
  ...rest
}) => {
  const dataContainerClasses = useDataStyles({
    stickyControls: true,
  });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeProp);
  const [orderBy, setOrderBy] = useState(
    orderByOptions.find((orderByOption) => orderByOption.default)?.orderBy ||
      orderByOptions[0]?.orderBy ||
      propVariables.orderBy ||
      []
  );
  const [condition, setCondition] = useState({});
  const mergeCondition = useCallback(
    (newCondition) => setCondition({ ...condition, ...newCondition }),
    [condition]
  );
  const filter = search
    ? {
        or: filterBy.map((key) => ({ [key]: { includesInsensitive: search } })),
      }
    : undefined;
  const offset = (page - 1) * pageSize;
  const { data, loading, refetch, fetchMore } = useQuery(query, {
    variables: {
      ...propVariables,
      condition: {
        ...propVariables.condition,
        ...condition,
      },
      filter:
        filter || propVariables?.filter
          ? { ...filter, ...propVariables.filter }
          : undefined,
      first: pageSize,
      offset,
      orderBy,
    },
  });
  const dataTotalCount = data?.[dataKey]?.totalCount || 0;
  const dataNodesCount = data?.[dataKey]?.nodes?.length || 0;
  const nodes = data?.[dataKey]?.nodes || [];
  const usedSelections = useSelections(
    nodes.filter(Boolean).map((node) => node[dataIdKey])
  );
  const SelectWithUsedSelections = (props) => (
    <Select
      withMultiselections={withMultiselections}
      withSelections={withSelections}
      usedSelections={usedSelections}
      {...props}
    />
  );
  const { selected } = usedSelections;
  useEffect(() => onSelect(selected), [selected, onSelect]);
  const showEmptyData = dataTotalCount === 0 && !loading;
  const shouldShowDataControls =
    Actions || BulkActions || withSearch || withFilter;
  const onBulkActionSucceeded = () => console.log("Clear selection");
  const onBulkActionFailed = () => console.log("Show error");
  const showActionAndSearch =
    Actions || withFilter || withSearch || orderByOptions.length > 0;
  return (
    <Box className={dataContainerClasses.dataContainer}>
      {showEmptyData ? (
        EmptyDataView
      ) : (
        <>
          {shouldShowDataControls ? (
            <Box className={dataContainerClasses.dataControls}>
              {BulkActions && selected.length > 0 ? (
                <Box
                  sx={{
                    mr: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ mr: 1 }}
                    className={dataContainerClasses.dataActionsAngle}
                  >
                    With {selected.length} selected
                  </Box>
                  <BulkActions
                    ids={selected}
                    onBulkActionSucceeded={onBulkActionSucceeded}
                    onBulkActionFailed={onBulkActionFailed}
                  />
                </Box>
              ) : showActionAndSearch ? (
                <Box
                  sx={{
                    flex: "1",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {Actions && (
                    <Box>
                      <Actions />
                    </Box>
                  )}
                  <Box
                    flexBasis={Actions ? "auto" : "100%"}
                    sx={{
                      flex: "0",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {withSearch && (
                      <DataSearch
                        value={search}
                        onChange={(search) => setSearch(search)}
                      />
                    )}
                    {orderByOptions.length > 0 && (
                      <Box className={dataContainerClasses.dataSort}>
                        <OrderByMenu
                          orderBy={orderBy}
                          orderByOptions={orderByOptions}
                          onChange={(value) => setOrderBy(value)}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              ) : null}
            </Box>
          ) : null}
          <Box className={dataContainerClasses.data}>
            <Box className={dataContainerClasses.dataViewport}>
              <DataView
                loading={loading}
                nodes={(data && data[dataKey]?.nodes) || []}
                data={data}
                dataKey={dataKey}
                dataIdKey={dataIdKey}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                setCondition={setCondition}
                mergeCondition={mergeCondition}
                fetchMore={() =>
                  fetchMore({
                    variables: {
                      offset: dataNodesCount,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      return {
                        [dataKey]: {
                          ...fetchMoreResult[dataKey],
                          nodes: [
                            ...prev[dataKey].nodes,
                            ...fetchMoreResult[dataKey].nodes,
                          ],
                        },
                      };
                    },
                  })
                }
                Select={SelectWithUsedSelections}
                {...rest}
              />
              {dataTotalCount > pageSize && selected.length === 0 && (
                <Box sx={{ mt: 3 }}>
                  <PaginationBlock
                    dataTotalCount={dataTotalCount}
                    offset={offset}
                    page={page}
                    pageSize={pageSize}
                    refetch={refetch}
                    setPage={setPage}
                    setPageSize={setPageSize}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Data;
