import { faFileContract as faFileContractDuotone } from "@fortawesome/pro-duotone-svg-icons";
import { faFileContract } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";

const useDialogActionsStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 3, 2, 3),
    justifyContent: "space-between",
  },
}));

const useLoadingButtonClasses = makeStyles((theme) => ({
  root: {
    fontSize: "1rem",
    fontWeight: 700,
    letterSpacing: "-0.25px",
    lineHeight: 1.5,
    borderColor: theme.palette.text.disabled,
    padding: theme.spacing(0.375, 0.75),
    minWidth: "auto",
    "& .MuiButton-startIcon": {
      marginLeft: 0,
      marginRight: theme.spacing(0.5),
      "& > *:first-child": {
        fontSize: "1.125rem",
      },
    },
  },
  pendingIndicatorStart: {
    left: theme.spacing(1),
  },
}));

const useButtonClasses = makeStyles((theme) => ({
  root: {
    fontSize: "1rem",
    lineHeight: 1.5,
    borderColor: theme.palette.text.disabled,
    padding: theme.spacing(0.375, 0.75),
    minWidth: "auto",
  },
}));

const DataRoomPublishDialog = ({
  name,
  open,
  loading,
  onCancel,
  onConfirm,
}) => {
  const dialogActionsClasses = useDialogActionsStyles();
  const buttonClasses = useButtonClasses();
  const loadingButtonClasses = useLoadingButtonClasses();
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogContent>
        <Box sx={{ mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon fixedWidth icon={faFileContractDuotone} size="3x" />
        </Box>
        <Typography
          variant="h6"
          color="textPrimary"
          align="center"
          gutterBottom
          style={{ lineHeight: 1.25 }}
        >
          <strong>
            Are you sure you want to publish
            <br />
            {name}?
          </strong>
        </Typography>
        <Typography variant="body2" align="center">
          If you're happy with how the dataRoom looks like then publish it,
          <br />
          but keep in mind that{" "}
          <span style={{ fontWeight: 600 }}>
            you can't change anything
            <br />
            after the data room is encrypted and published
          </span>
          .
        </Typography>
      </DialogContent>
      <DialogActions classes={dialogActionsClasses}>
        <Button
          classes={buttonClasses}
          color="inherit"
          variant="outlined"
          onClick={onCancel}
          disabled={loading}
        >
          Cancel
        </Button>
        <LoadingButton
          classes={loadingButtonClasses}
          color="inherit"
          variant="outlined"
          onClick={onConfirm}
          pending={loading}
          pendingPosition="start"
          startIcon={<FontAwesomeIcon icon={faFileContract} />}
        >
          Encrypt and publish
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DataRoomPublishDialog;
