import { dataRoomMode, dataRoomParticipation } from "constants/index";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Field, useFormikContext } from "formik";

const useSelectStyles = makeStyles((theme) => ({
  select: {
    padding: theme.spacing(0.5, 0),
    minHeight: "1.5rem",
    lineHeight: "1.5rem",
  },
}));

const useInputLabelStyles = makeStyles((theme) => ({
  asterisk: {
    fontSize: "1.25em",
    lineHeight: "50%",
    color: "red",
  },
}));

const DataRoomFormDataRoomAccessedByField = () => {
  const {
    status: { mode },
  } = useFormikContext();
  const inputLabelClasses = useInputLabelStyles();
  const selectClasses = useSelectStyles();
  return (
    <FormControl fullWidth>
      <InputLabel
        htmlFor="dataRoom-accessed-by"
        color="secondary"
        shrink
        required={[dataRoomMode.UPDATE].includes(mode)}
        disabled={![dataRoomMode.UPDATE].includes(mode)}
        style={{ whiteSpace: "nowrap" }}
        classes={inputLabelClasses}
      >
        Participants
      </InputLabel>
      <Field id="dataRoom-accessed-by" name="dataRoomAccessedBy">
        {({ field }) => (
          <Select
            SelectDisplayProps={{ style: { minHeight: "1.1428575em" } }}
            color="secondary"
            // ref={compontentRef}
            // MenuProps={{
            //   container: () => compontentRef.current,
            // }}
            disableUnderline={![dataRoomMode.UPDATE].includes(mode)}
            readOnly={![dataRoomMode.UPDATE].includes(mode)}
            // disabled={![dataRoomMode.UPDATE].includes(mode)}
            disabled
            classes={selectClasses}
            {...field}
          >
            <MenuItem value={dataRoomParticipation.ALL}>
              Unsealed (not enforced by enclave)
            </MenuItem>
            <MenuItem value={dataRoomParticipation.AUTHENTICATED}>
              Invited only (enforced by enclave)
            </MenuItem>
          </Select>
        )}
      </Field>
    </FormControl>
  );
};

export default DataRoomFormDataRoomAccessedByField;
