import { useMutation } from "@apollo/client";
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FastField, Formik } from "formik";
import { CustomInput } from "legacy/components";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { CREATE_DATA_ROOM } from "gqls";

const useDialogContentStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    "&:first-child": {
      paddingTop: theme.spacing(1),
    },
  },
}));

const useDialogActionsStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
  },
}));

const DataRoomsNewDialog = ({ open, onClose }) => {
  const dialogContentClasses = useDialogContentStyles();
  const dialogActionsClasses = useDialogActionsStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [createDataRoom, { loading }] = useMutation(CREATE_DATA_ROOM, {
    onCompleted: () => {
      enqueueSnackbar("New dataroom created");
      onClose();
    },
    onError: () =>
      enqueueSnackbar("Can't create new dataroom", { variant: "error" }),
    refetchQueries: ["dataRooms"],
  });
  const onDataRoomsNewFormSubmit = ({ name, description }) => {
    createDataRoom({
      variables: {
        input: {
          dataRoom: {
            name,
            description,
          },
        },
      },
    });
  };
  const initialValues = {
    name: "",
    description: "",
  };
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required("Must specify name")
      .min(1, "Minimum 1 character"),
  });
  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" component="div">
            <strong>New dataroom</strong>
          </Typography>
          <Box>
            <IconButton color="inherit" onClick={!loading ? onClose : null}>
              <FontAwesomeIcon fixedWidth icon={faTimes} />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={onDataRoomsNewFormSubmit}
      >
        {({ values, handleReset, handleSubmit, isValid, isSubmitting }) => (
          <form onReset={handleReset} onSubmit={handleSubmit}>
            <DialogContent classes={dialogContentClasses}>
              <Box>
                <Grid
                  container
                  spacing={1}
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs>
                    <strong>Name</strong>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs>
                    <FastField
                      name="name"
                      as={CustomInput}
                      type="text"
                      placeholder="e.g. Claim analytics"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs>
                    <strong>Description</strong>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs>
                    <FastField
                      name="description"
                      as={CustomInput}
                      type="text"
                      placeholder="e.g. Claim analytics for this specific situation that happened maybe a week ago"
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <Divider />
            <DialogActions classes={dialogActionsClasses}>
              <Box>
                <Button disabled={isSubmitting} onClick={onClose}>
                  Cancel
                </Button>
                <Button disabled={isSubmitting} type="reset">
                  Reset
                </Button>
              </Box>
              <Button
                disabled={!isValid || isSubmitting}
                type="submit"
                variant="outlined"
              >
                <FontAwesomeIcon fixedWidth icon={faCheck} />
                <Box ml={0.5}>Create</Box>
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default DataRoomsNewDialog;
