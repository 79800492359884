import { useSubscription } from "@apollo/client";
import throttle from "lodash/throttle";
import { EVENTS, EVENTS_SUBSCRIPTION } from "gqls";

let buffer = [];

const writeQueryAndClearBuffer = throttle(
  (client) => {
    let prevData = {
      events: { nodes: [], __typename: "EventsPayload" },
    };
    try {
      prevData = client.readQuery({ query: EVENTS }) || prevData;
    } catch {
      // do nothing
    } finally {
      client.writeQuery({
        query: EVENTS,
        data: {
          ...prevData,
          events: {
            ...prevData?.events,
            nodes: [...prevData?.events?.nodes, ...buffer],
          },
        },
      });
      buffer = [];
      client.reFetchObservableQueries(true);
    }
  },
  100,
  { leading: false }
);

const EventsWorker = () => {
  useSubscription(EVENTS_SUBSCRIPTION, {
    onSubscriptionData: ({ client, subscriptionData: { data } }) => {
      if (Array.isArray(data?.events?.nodes) && data.events.nodes.length > 0) {
        buffer = buffer.concat(data.events.nodes);
        writeQueryAndClearBuffer(client);
      }
    },
  });
  return null;
};

export default EventsWorker;
