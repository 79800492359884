import { dataRoomComputing, dataRoomMode } from "constants/index";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Field, useFormikContext } from "formik";

const useInputLabelStyles = makeStyles((theme) => ({
  asterisk: {
    fontSize: "1.25em",
    lineHeight: "50%",
    color: "red",
  },
}));

const DataRoomFormResultsComputedAfterField = () => {
  const {
    status: { mode },
  } = useFormikContext();
  const inputLabelClasses = useInputLabelStyles();
  return (
    <FormControl fullWidth>
      <InputLabel
        htmlFor="results-computed-after"
        color="secondary"
        shrink
        required
        style={{ whiteSpace: "nowrap" }}
        disabled={![dataRoomMode.UPDATE].includes(mode)}
        classes={inputLabelClasses}
      >
        Process responses and results
      </InputLabel>
      <Field name={`resultsComputedAfter`}>
        {({ field }) => (
          <Select
            SelectDisplayProps={{
              style: { minHeight: "1.1428575em" },
            }}
            color="secondary"
            // ref={compontentRef}
            // MenuProps={{
            //   container: () => compontentRef.current,
            // }}
            disableUnderline={![dataRoomMode.UPDATE].includes(mode)}
            readOnly={![dataRoomMode.UPDATE].includes(mode)}
            disabled={![dataRoomMode.UPDATE].includes(mode)}
            {...field}
          >
            <MenuItem
              value={dataRoomComputing.DATA_ROOM_MINIMUM_RESPONSES_REACHED}
            >
              When minimum submissions is reached
            </MenuItem>
            <MenuItem value={dataRoomComputing.SUBMISSION_END_REACHED}>
              When dataRoom date expire
            </MenuItem>
          </Select>
        )}
      </Field>
    </FormControl>
  );
};

export default DataRoomFormResultsComputedAfterField;
