import { makeStyles, TextField } from "@material-ui/core";
import { FastField, useStatus } from "formik";
import { memo } from "react";

const useInputLabelStyles = makeStyles((theme) => ({
  root: {
    transform: "none",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    display: "inline-flex",
  },
  asterisk: {
    color: "red",
    fontSize: "1.25rem",
  },
}));

const TitleField = memo((props) => {
  const [{ isEditing }] = useStatus();
  const inputLabelClasses = useInputLabelStyles();
  return (
    <FastField name="name">
      {({ field, meta: { error, touched } }) => (
        <TextField
          fullWidth
          variant="standard"
          color="secondary"
          label={isEditing ? "Title" : null}
          InputLabelProps={{
            required: true,
            shrink: true,
            style: {
              justifyContent: "center",
              width: "100%",
              transformOrigin: "top center",
            },
            classes: inputLabelClasses,
          }}
          InputProps={{
            multiline: true,
            disableUnderline: !isEditing,
            readOnly: !isEditing,
            disabled: !isEditing,
            inputProps: {
              style: {
                textAlign: "center",
                fontSize: "2rem",
                fontWeight: 900,
                lineHeight: 1.25,
                letterSpacing: "-0.5px",
              },
            },
          }}
          error={Boolean(error)}
          helperText={error}
          {...field}
          {...props}
        />
      )}
    </FastField>
  );
});

export default TitleField;
