import { Box, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { memo } from "react";
import { NavLink } from "react-router-dom";
import { useMetaStyles } from "./Meta.styles";

const Meta = ({
  tooltip,
  icon,
  label,
  value,
  to,
  pill,
  className,
  color,
  ...rest
}) => {
  const metaClasses = useMetaStyles();
  const Wrapper = to ? NavLink : Box;
  const MetaInner = () => (
    <span className={clsx(metaClasses.text, { [metaClasses.pill]: pill })}>
      {icon && <span className={metaClasses.iconWrapper}>{icon}</span>}
      <span>
        {label ? `${label}${value ? ":" : ""}` : ""}
        {value && <span className={metaClasses.value}>{value}</span>}
      </span>
    </span>
  );
  return (
    <Wrapper
      className={clsx(metaClasses.meta, className)}
      {...(to ? { to } : {})}
      {...rest}
    >
      {tooltip ? (
        <Tooltip
          title={tooltip}
          placement="top"
          enterDelay={1000}
          enterNextDelay={250}
        >
          {MetaInner()}
        </Tooltip>
      ) : (
        <MetaInner />
      )}
    </Wrapper>
  );
};

export default memo(Meta);
