import { Children, memo } from "react";
import { useMetaWrapperStyles } from "./MetaWrapper.styles";

const MetaWrapper = ({ children }) => {
  const metaWrapperClasses = useMetaWrapperStyles();
  return Children.count(children) ? (
    <div className={metaWrapperClasses.metaWrapper}>{children}</div>
  ) : null;
};

export default memo(MetaWrapper);
