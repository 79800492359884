import { useMutation } from "@apollo/client";
import {
  faArrowToBottom,
  faMapPin,
  faRecycle,
  faTags,
  faWrench,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Actions } from "legacy/components";
import { useSnackbar } from "notistack";
import { DELETE_DATA_ROOM_BY_ID } from "gqls";

const DataRoomActions = ({ id, dataRoomId, inline = false }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDataRoomById] = useMutation(DELETE_DATA_ROOM_BY_ID, {
    onCompleted: () => enqueueSnackbar("DataRoom deleted"),
    onError: () =>
      enqueueSnackbar("Can't delete dataRoom", { variant: "error" }),
  });
  const onDeleteDataRoomClick = () => {
    deleteDataRoomById({
      variables: {
        input: {
          id,
        },
      },
    });
  };
  const actions = [
    {
      icon: <FontAwesomeIcon fixedWidth icon={faWrench} />,
      title: "Do this",
      handle: () => alert("Not implemented yet"),
      isPrimary: false,
      isEnabled: true,
    },
    {
      icon: <FontAwesomeIcon fixedWidth icon={faArrowToBottom} />,
      title: "Do that",
      handle: () => alert("Not implemented yet"),
      isPrimary: false,
      isEnabled: true,
    },
    {
      icon: <FontAwesomeIcon fixedWidth icon={faMapPin} />,
      title: "Do that too",
      handle: () => alert("Not implemented yet"),
      isPrimary: false,
      isEnabled: true,
    },
    {
      icon: <FontAwesomeIcon fixedWidth icon={faTags} />,
      title: "Do this as well",
      handle: () => alert("Not implemented yet"),
      isPrimary: false,
      isEnabled: true,
    },
    {
      icon: <FontAwesomeIcon fixedWidth icon={faRecycle} />,
      title: "Delete dataroom",
      handle: onDeleteDataRoomClick,
      isPrimary: false,
      isEnabled: true,
      isRed: true,
    },
  ];
  return <Actions actions={actions} inline={inline} />;
};

export default DataRoomActions;
