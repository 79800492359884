import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, InputBase } from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useInputBaseStyles = makeStyles((theme) => ({
  root: {
    color: "inherit",
    flex: 1,
  },
  input: {
    padding: (props) => theme.spacing(1, 1, 1, props.hasIcon ? 4 : 1),
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: "0.875em",
  },
}));

const useCustomInputStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    display: "inline-flex",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.025),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
  },
  icon: {
    width: theme.spacing(4),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const useClearButtonStyles = makeStyles((theme) => ({
  root: {
    minWidth: "auto",
    height: theme.spacing(3),
    width: theme.spacing(3),
    margin: theme.spacing(0.5),
  },
}));

const CustomInput = ({
  icon,
  className,
  name,
  value,
  onChange: onChangeProp,
  onBlur: onBlurProp,
  ...rest
}) => {
  const inputBaseClasses = useInputBaseStyles();
  const customInputClasses = useCustomInputStyles({ hasIcon: Boolean(icon) });
  const clearButtonClasses = useClearButtonStyles();
  const onChange = (event) => {
    if (event.target.value.startsWith(" ")) {
      return;
    }
    onChangeProp(event);
  };
  const onBlur = (event) => {
    event.target.value = event.target.value.trim();
    onChangeProp(event);
    onBlurProp(event);
  };
  const onCancelClick = (event) => {
    event.target.name = name;
    event.target.value = "";
    onChangeProp(event);
  };
  return (
    <Box className={clsx(customInputClasses.wrapper, className)}>
      {icon && (
        <Box className={customInputClasses.icon}>
          <FontAwesomeIcon fixedWidth icon={icon} />
        </Box>
      )}
      <InputBase
        classes={inputBaseClasses}
        inputComponent="input"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...rest}
      />
      {value !== "" && (
        <Button
          tabIndex={-1}
          classes={clearButtonClasses}
          onClick={onCancelClick}
        >
          <FontAwesomeIcon fixedWidth icon={faTimes} />
        </Button>
      )}
    </Box>
  );
};

export default CustomInput;
