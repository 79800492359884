import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Actions } from "legacy/components";
import { memo } from "react";

const ContactActions = ({
  id,
  contactId,
  inline = false,
  contextActions = [],
}) => {
  const actions = [
    {
      icon: <FontAwesomeIcon fixedWidth icon={faTrashAlt} />,
      title: "Delete contact",
      handle: () => alert("Should delete contact"),
      isPrimary: false,
      isEnabled: true,
      isRed: true,
    },
  ];
  return (
    <Actions
      inline={inline}
      actions={actions}
      contextActions={contextActions}
    />
  );
};

export default memo(ContactActions);
