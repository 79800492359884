import { dataRoomMode, dataRoomResultVisibility } from "constants/index";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Field, useFormikContext } from "formik";

const useSelectStyles = makeStyles((theme) => ({
  select: {
    padding: theme.spacing(0.5, 0),
    minHeight: "1.5rem",
    lineHeight: "1.5rem",
  },
}));

const useInputLabelStyles = makeStyles((theme) => ({
  asterisk: {
    fontSize: "1.25em",
    lineHeight: "50%",
    color: "red",
  },
}));

const DataRoomFormResultsAccessedByField = () => {
  const {
    status: { mode },
  } = useFormikContext();
  const inputLabelClasses = useInputLabelStyles();
  const selectClasses = useSelectStyles();
  return (
    <FormControl fullWidth>
      <InputLabel
        htmlFor="dataRoom-accessed-by"
        color="secondary"
        shrink
        required={[dataRoomMode.UPDATE].includes(mode)}
        style={{ whiteSpace: "nowrap" }}
        disabled={![dataRoomMode.UPDATE].includes(mode)}
        classes={inputLabelClasses}
      >
        Who can view the results of this dataRoom?
      </InputLabel>
      <Field name={`resultsAccessedBy`}>
        {({ field }) => (
          <Select
            SelectDisplayProps={{ style: { minHeight: "1.1428575em" } }}
            color="secondary"
            // ref={compontentRef}
            // MenuProps={{
            //   container: () => compontentRef.current,
            // }}
            disableUnderline={![dataRoomMode.UPDATE].includes(mode)}
            readOnly={![dataRoomMode.UPDATE].includes(mode)}
            disabled={![dataRoomMode.UPDATE].includes(mode)}
            classes={selectClasses}
            {...field}
          >
            {false && (
              <MenuItem value={dataRoomResultVisibility.ALL} disabled>
                Public results (not enforced by enclave)
              </MenuItem>
            )}
            <MenuItem value={dataRoomResultVisibility.OWNER}>
              DataRoom creator
            </MenuItem>
            <MenuItem value={dataRoomResultVisibility.OWNER_AND_PARTICIPANTS}>
              DataRoom creator & participants
            </MenuItem>
            {false && (
              <MenuItem value={dataRoomResultVisibility.PARTICIPANTS} disabled>
                Participants
              </MenuItem>
            )}
          </Select>
        )}
      </Field>
    </FormControl>
  );
};

export default DataRoomFormResultsAccessedByField;
