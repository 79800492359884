import {
  DataRoomCardGridWrapper,
  DataRooms,
  EmptyCollaborationsDataRoomCard,
  NewDataRoomCard,
} from "components";
import { Data } from "containers";
import { DATA_ROOMS } from "gqls";

export const dataRoomOwnershipFilter = {
  OWNER: "owner",
  CONTRIBUTOR: "contributor",
};

const dataRoomOwnershipFilterVariables = new Map([
  [dataRoomOwnershipFilter.OWNER, { filter: { isOwner: { equalTo: true } } }],
  [
    dataRoomOwnershipFilter.CONTRIBUTOR,
    {
      filter: {
        isParticipant: { equalTo: true },
      },
    },
  ],
]);

const DataRoomsContainer = ({
  filterBy = ["name"],
  dataIdKey = "dataRoomId",
  dataKey = "dataRooms",
  query = DATA_ROOMS,
  filterByOwnership,
  variables,
  ...rest
}) => (
  <Data
    DataView={DataRooms}
    filterBy={filterBy}
    dataIdKey={dataIdKey}
    dataKey={dataKey}
    query={query}
    variables={{
      orderBy: ["UPDATED_AT_DESC"],
      ...dataRoomOwnershipFilterVariables.get(filterByOwnership),
    }}
    {...rest}
    StartingCard={
      filterByOwnership === dataRoomOwnershipFilter.OWNER ? (
        <NewDataRoomCard />
      ) : undefined
    }
    EmptyDataView={
      filterByOwnership === dataRoomOwnershipFilter.OWNER ? (
        <DataRoomCardGridWrapper>
          <NewDataRoomCard />
        </DataRoomCardGridWrapper>
      ) : (
        <EmptyCollaborationsDataRoomCard></EmptyCollaborationsDataRoomCard>
      )
    }
  />
);

export default DataRoomsContainer;
