import { createContext, useContext } from "react";

export const MainContext = createContext({
  prefersColorSchemeDark: false,
  setPrefersColorSchemeDark: () => {},
});

export const MainProvider = MainContext.Provider;

export const useMain = () => useContext(MainContext);

export default useMain;
