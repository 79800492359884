import {
  faEnvelope,
  faPhone,
  faUserCircle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  ContactActions,
  Meta,
  MetaGroup,
  MetaWrapper,
  Name,
} from "legacy/components";
import { memo } from "react";
import { useBlockStyles } from "styles/block";
import { useContactBlockStyles } from "./ContactBlock.styles";

const ContactBlock = ({
  contact,
  className,
  withAvatar = false,
  withMeta = true,
  withTags = true,
  contextActions = [],
}) => {
  const blockClasses = useBlockStyles();
  const contactBlockClasses = useContactBlockStyles();
  const { id, contactId, name, phone, email } = contact || {};
  return (
    <div className={clsx(contactBlockClasses.wrapper, className)}>
      {withAvatar && (
        <Avatar className={contactBlockClasses.avatar}>
          <FontAwesomeIcon fixedWidth icon={faUserCircle} />
        </Avatar>
      )}
      <div className={contactBlockClasses.info}>
        <div className={clsx(blockClasses.wrapper)}>
          <div className={blockClasses.nameMetaActionsWrapper}>
            <div className={blockClasses.nameActionsWrapper}>
              <Typography
                variant="body1"
                component="div"
                className={blockClasses.nameWrapper}
              >
                <Name name={name || ""} id={contactId} />
              </Typography>
              <div className={blockClasses.actionsWrapper}>
                <ContactActions
                  inline
                  id={id}
                  contactId={contactId}
                  contextActions={contextActions}
                />
              </div>
            </div>
            {(withMeta || withTags) && (
              <MetaWrapper>
                <MetaGroup>
                  {phone && (
                    <Meta
                      icon={<FontAwesomeIcon icon={faPhone} />}
                      value={phone}
                    />
                  )}
                  {email && (
                    <Meta
                      icon={<FontAwesomeIcon icon={faEnvelope} />}
                      value={email}
                    />
                  )}
                  <Meta
                    pill
                    value="Administrator"
                    onClick={() => alert("Not implemented yet")}
                  />
                </MetaGroup>
              </MetaWrapper>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ContactBlock);
