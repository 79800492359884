import { makeStyles } from "@material-ui/core";

export const useDataStyles = makeStyles((theme) => ({
  dataContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "inherit",
  },
  dataControls: {
    display: "flex",
    alignItems: "center",
    minHeight: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  dataActionsAngle: {
    paddingLeft: "24px",
    marginLeft: "16px",
    position: "relative",
    "&::before": {
      content: "''",
      display: "block",
      height: "30px",
      width: "18px",
      borderTop: "1px solid #747474",
      borderLeft: "1px solid #747474",
      position: "absolute",
      left: 0,
      top: "50%",
    },
  },
  dataSort: {
    alignSelf: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  data: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "inherit",
  },
  dataViewport: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "inherit",
  },
  dataPagination: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1.5),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
