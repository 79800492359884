import { makeStyles } from "@material-ui/core/styles";

export const useActionsStyles = makeStyles((theme) => ({
  red: {
    color: "red",
  },
  inline: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
  },
}));
