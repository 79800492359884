import { gql } from "@apollo/client";
import { DATA_ROOM_EVERYTHING } from "gqls";

export const UPDATE_DATA_ROOM = gql`
  mutation updateDataRoom($input: UpdateDataRoomInput!) {
    updateDataRoom(input: $input) {
      clientMutationId
      dataRoom {
        ...DataRoomEverything
      }
    }
  }
  ${DATA_ROOM_EVERYTHING}
`;
