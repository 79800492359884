import { faCircle } from "@fortawesome/pro-light-svg-icons";
import { faDotCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Radio } from "@material-ui/core";
import { memo } from "react";
import {
  useFontAwesomeRadioboxStyles,
  useRadioboxStyles,
} from "./FontAwesomeRadiobox.styles";

const FontAwesomeRadiobox = ({
  id,
  isSelected,
  select = () => {},
  field,
  form,
  checked,
  onChange,
  ...rest
}) => {
  const checkboxClasses = useRadioboxStyles();
  const fontAwesomeRadioboxClasses = useFontAwesomeRadioboxStyles();
  return (
    <Radio
      color="inherit"
      classes={checkboxClasses}
      icon={
        <span className={fontAwesomeRadioboxClasses.label}>
          <FontAwesomeIcon fixedWidth icon={faCircle} />
        </span>
      }
      // checked={
      //   checked ||
      //   (typeof isSelected === "function" ? isSelected(id) : isSelected)
      // }
      checkedIcon={
        <span className={fontAwesomeRadioboxClasses.label}>
          <FontAwesomeIcon fixedWidth icon={faDotCircle} />
        </span>
      }
      // onChange={onChange || select.bind(null, id)}
      // {...field}
      // {...form}
      {...rest}
    />
  );
};

export default memo(FontAwesomeRadiobox);
