import { faSync } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  IconButton,
  Pagination,
  PaginationItem,
  Tooltip,
} from "@material-ui/core";
import { memo } from "react";
import { PaginationMenu } from "components";
import {
  usePaginationBlockStyles,
  usePaginationItemStyles,
  usePaginationStyles,
} from "./PaginationBlock.styles";

const PaginationBlock = ({
  offset,
  page,
  pageSize,
  dataTotalCount,
  refetch,
  setPage,
  setPageSize,
}) => {
  const paginationBlockClasses = usePaginationBlockStyles();
  const paginationClasses = usePaginationStyles();
  const paginationItemClasses = usePaginationItemStyles();
  return (
    <div className={paginationBlockClasses.wrapper}>
      <div className={paginationBlockClasses.paginationControls}>
        <Tooltip title={"Refetch"} placement="top">
          <IconButton
            color="inherit"
            onClick={() => refetch()}
            className={paginationBlockClasses.refetchButton}
            TouchRippleProps={{
              center: false,
            }}
          >
            <FontAwesomeIcon fixedWidth icon={faSync} />
          </IconButton>
        </Tooltip>
        <Box
          sx={{
            pl: 0.5,
            pr: 0.5,
            display: "flex",
          }}
        >
          <strong>
            {offset + 1}–{Math.min(offset + pageSize, dataTotalCount)}
          </strong>
          <Box
            sx={{
              ml: 0.5,
              mr: 0.5,
            }}
          >
            of
          </Box>
          <strong>{dataTotalCount}</strong>
        </Box>
      </div>
      <div className={paginationBlockClasses.paginationControls}>
        <Pagination
          count={Math.ceil(dataTotalCount / pageSize)}
          page={page}
          size="small"
          shape="rounded"
          showFirstButton
          showLastButton
          renderItem={(item) => (
            <PaginationItem classes={paginationItemClasses} {...item} />
          )}
          onChange={(event, page) => setPage(page)}
          classes={paginationClasses}
        />
        <PaginationMenu
          pageSize={pageSize}
          onChange={(value) => setPageSize(value)}
        />
      </div>
    </div>
  );
};

export default memo(PaginationBlock);
