import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { useAuth0 } from "@auth0/auth0-react";
import { relayGraphqlUrl, relayWebsocketUrl } from "configs";

const ApolloWrapper = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const httpLink = new HttpLink({
    uri: relayGraphqlUrl?.replace(/\/$/, ""),
    credentials: "include",
  });
  const webSocketLink = new WebSocketLink({
    uri: relayWebsocketUrl?.replace(/\/$/, ""),
    options: {
      reconnect: true,
      lazy: true,
    },
  });
  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    webSocketLink,
    httpLink
  );
  const authLink = setContext(async (_, { headers, ...context }) => {
    const accessToken = await getAccessTokenSilently();
    return {
      headers: {
        ...headers,
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      },
      ...context,
    };
  });
  const client = new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache(),
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
