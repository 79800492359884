import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { DataRooms } from "containers";
import { dataRoomOwnershipFilter } from "containers/DataRooms/DataRooms";
import { Main } from "layouts";

const HeaderTooltip = ({ tooltip }) => (
  <Tooltip title={tooltip} placement="top">
    <div
      style={{ display: "inline-flex", fontSize: "15px", marginLeft: "5px" }}
    >
      <FontAwesomeIcon fixedWidth icon={faCircleInfo}></FontAwesomeIcon>
    </div>
  </Tooltip>
);

const DataRoomsPage = () => {
  return (
    <Main>
      <Box sx={{ p: 3 }}>
        <Typography variant="h5" component="h1">
          My data rooms
          <HeaderTooltip tooltip="The data rooms you have created appear here"></HeaderTooltip>
        </Typography>
        <DataRooms filterByOwnership={dataRoomOwnershipFilter.OWNER} />
      </Box>
      <Box sx={{ p: 3 }}>
        <Typography variant="h5" component="h1">
          My collaborations
          <HeaderTooltip tooltip="The data rooms you participate in appear here"></HeaderTooltip>
        </Typography>
        <DataRooms filterByOwnership={dataRoomOwnershipFilter.CONTRIBUTOR} />
      </Box>
    </Main>
  );
};

export default DataRoomsPage;
