import { gql } from "@apollo/client";
import { DATA_ROOM_EVERYTHING } from "gqls";

export const DATA_ROOMS = gql`
  query dataRooms(
    $filter: DataRoomFilter
    $first: Int
    $offset: Int
    $orderBy: [DataRoomsOrderBy!]
  ) {
    dataRooms(
      first: $first
      offset: $offset
      orderBy: $orderBy
      filter: $filter
    ) {
      totalCount
      nodes {
        ...DataRoomEverything
      }
    }
  }
  ${DATA_ROOM_EVERYTHING}
`;
