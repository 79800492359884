import { makeStyles } from "@material-ui/core/styles";

export const useBlockStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    flexDirection: "row",
    alignItems: "flex-start",
    position: "relative",
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.2s linear",
    "&:hover": {
      background: "rgba(0,0,0,0.05)",
      "& $actionsWrapper": {
        opacity: 1,
      },
    },
  },
  statusWrapper: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: theme.spacing(4),
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    width: theme.spacing(4),
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(-0.5),
  },
  nameMetaActionsWrapper: {
    width: 0,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
  },
  nameActionsWrapper: {
    display: "flex",
    width: "100%",
  },
  nameWrapper: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    width: 0,
  },
  actionsWrapper: {
    opacity: 0,
    transition: "all 0.2s linear",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "auto",
    marginLeft: theme.spacing(1),
  },
}));
