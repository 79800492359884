import { useAuth0 } from "@auth0/auth0-react";
import {
  createInstance,
  OptimizelyProvider,
  withOptimizely,
} from "@optimizely/react-sdk";
import { useState } from "react";
import { optimizelySdkKey } from "configs";

const optimizely = createInstance({
  sdkKey: optimizelySdkKey,
  logLevel: 4,
});

const OptimizelyReady = withOptimizely(({ optimizely, children }) => {
  const [isReady, setIsReady] = useState(false);
  optimizely.onReady().then(() => setIsReady(true));
  return isReady ? children : null;
});

const OptimizelyWrapper = ({ children }) => {
  const { user: auth0User } = useAuth0();
  const user = auth0User ? { id: auth0User.email } : { id: "anonymous" };
  return (
    <OptimizelyProvider optimizely={optimizely} user={user} timeout={500}>
      <OptimizelyReady>{children}</OptimizelyReady>
    </OptimizelyProvider>
  );
};

export default OptimizelyWrapper;
