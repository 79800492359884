import { dataRoomMode } from "constants/index";
import { faCalendarDay } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, TextField } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/lab";
import { Field, useFormikContext } from "formik";

const useInputLabelStyles = makeStyles((theme) => ({
  asterisk: {
    fontSize: "1.25em",
    lineHeight: "50%",
    color: "red",
  },
}));

const useInlineIconButtonStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
  },
}));

const DataRoomFormSubmissionEndField = () => {
  const {
    status: { mode },
  } = useFormikContext();
  const inlineIconButtonClasses = useInlineIconButtonStyles();
  const inputLabelClasses = useInputLabelStyles();
  return (
    <Field name="submissionEnd">
      {({ field, meta: { touched, error } }) => (
        <DateTimePicker
          ampm={false}
          hideTabs={true}
          renderInput={(props) => (
            <TextField
              {...props}
              variant="standard"
              color="secondary"
              fullWidth
              label="Submissions accepted to"
              InputLabelProps={{
                htmlFor: "submission-end",
                required: [dataRoomMode.UPDATE].includes(mode),
                shrink: true,
                style: { whiteSpace: "nowrap" },
                classes: inputLabelClasses,
              }}
              InputProps={{
                ...props.InputProps,
                disableUnderline: ![dataRoomMode.UPDATE].includes(mode),
              }}
              readOnly={![dataRoomMode.UPDATE].includes(mode)}
              disabled={![dataRoomMode.UPDATE].includes(mode)}
              error={touched && Boolean(error)}
              helperText={touched && error}
            />
          )}
          openPickerIcon={<FontAwesomeIcon fixedWidth icon={faCalendarDay} />}
          OpenPickerButtonProps={{
            classes: inlineIconButtonClasses,
            TouchRippleProps: {
              center: false,
            },
          }}
          InputAdornmentProps={{
            style: {
              width: "24px",
            },
          }}
          readOnly={![dataRoomMode.UPDATE].includes(mode)}
          disabled={![dataRoomMode.UPDATE].includes(mode)}
          {...field}
        />
      )}
    </Field>
  );
};

export default DataRoomFormSubmissionEndField;
