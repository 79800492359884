import { useAuth0 } from "@auth0/auth0-react";
import { Box, Typography } from "@material-ui/core";
import { ReactComponent as DecentriqPlatformLogo } from "assets/logos/decentriq-platform-logo.svg";
import { ReactComponent as SwissReLogo } from "assets/logos/swiss-re-logo.svg";

const CUSTOM_LOGO_ENABLED = false;

const customBrandingLogos = {
  "swissre.com": <SwissReLogo style={{ height: "2.5rem" }} />,
};

const CustomBrandingLogo = () => {
  const { isAuthenticated, user } = useAuth0();
  const standartLogo = (
    <DecentriqPlatformLogo
      viewBox="-4 -4 428 48"
      style={{ height: "1.2rem", strokeWidth: "4px" }}
    />
  );
  if (!CUSTOM_LOGO_ENABLED) {
    return standartLogo;
  }
  if (!isAuthenticated) {
    return standartLogo;
  }
  const domain = user.email.slice(user.email.indexOf("@") + 1).toLowerCase();
  const hasCustomBranding = !!customBrandingLogos[domain];
  if (hasCustomBranding) {
    return (
      <Box style={{ display: "flex", alignItems: "center" }}>
        {customBrandingLogos[domain]}
        <Box
          style={{
            display: "inline-flex",
            flexDirection: "column",
            marginLeft: "1rem",
          }}
        >
          <Typography variant="caption">powered by</Typography>
          <DecentriqPlatformLogo
            viewBox="-4 -4 428 48"
            style={{ height: ".7rem", strokeWidth: "4px" }}
          />
        </Box>
      </Box>
    );
  }
  return standartLogo;
};

export default CustomBrandingLogo;
