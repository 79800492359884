"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateCsrPem = exports.generateKey = exports.Sigma = exports.Auth = void 0;
const forge = __importStar(require("node-forge"));
var KeyType;
(function (KeyType) {
    KeyType[KeyType["RSA"] = 0] = "RSA";
})(KeyType || (KeyType = {}));
class Auth {
    constructor(certChain, keypair, userId, accessToken) {
        this.certChain = certChain;
        this.keypair = keypair;
        this.userId = userId;
        this.accessToken = accessToken;
    }
    getCertificateChainPem() {
        return this.certChain;
    }
    getUserId() {
        return this.userId;
    }
    getAccessToken() {
        return this.accessToken;
    }
    sign(data) {
        const digest = forge.md.sha512.create();
        digest.update(forge.util.binary.raw.encode(data));
        const signature = this.keypair.privateKey.sign(digest);
        return forge.util.binary.raw.decode(signature);
    }
}
exports.Auth = Auth;
class Sigma {
    constructor(signature, macTag, authPki) {
        this.signature = signature;
        this.macTag = macTag;
        this.authPki = authPki;
    }
    getSignature() {
        return this.signature;
    }
    getMacTag() {
        return this.macTag;
    }
    getCertChain() {
        return this.authPki.getCertificateChainPem();
    }
}
exports.Sigma = Sigma;
function generateKey(keyType = KeyType.RSA, bitSize = 4096) {
    return __awaiter(this, void 0, void 0, function* () {
        switch (keyType) {
            case KeyType.RSA: {
                const key = yield (new Promise((resolve, reject) => {
                    forge.pki.rsa.generateKeyPair({ bits: bitSize }, (err, keypair) => {
                        if (err) {
                            reject(err);
                        }
                        resolve(keypair);
                    });
                }));
                return key;
            }
        }
    });
}
exports.generateKey = generateKey;
function generateCsrPem(userEmail, key) {
    const csr = forge.pki.createCertificationRequest();
    csr.setSubject([{
            name: 'commonName',
            valueTagClass: forge.asn1.Type.UTF8,
            value: userEmail
        }]);
    csr.setAttributes([{
            name: 'extensionRequest',
            extensions: [{
                    name: 'basicConstraints',
                    cA: false
                }, {
                    name: 'keyUsage',
                    digitalSignature: true,
                    nonRepudiation: true,
                    keyEncipherment: true,
                    dataEncipherment: false,
                    keyCertSign: false,
                }]
        }]);
    csr.publicKey = key.publicKey;
    csr.sign(key.privateKey, forge.md.sha512.create());
    return forge.pki.certificationRequestToPem(csr);
}
exports.generateCsrPem = generateCsrPem;
