import { faListAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, IconButton, Popover, Tooltip } from "@material-ui/core";
import { ShowColumnsMenu, ViewsMenu } from "legacy/components";
import { usePopupState } from "material-ui-popup-state/hooks";
import { useViewSettingsStyles } from "./ViewSettingsMenu.styles";

const ViewSettingsMenu = ({ viewsWithSelections, columnsWithSelections }) => {
  const viewSettingsClasses = useViewSettingsStyles();
  const { anchorEl, isOpen, open, close } = usePopupState({
    variant: "popover",
    popupId: "actions-menu",
  });
  const menuOpen = (event) => {
    event.stopPropagation();
    open(event.currentTarget);
  };
  const menuClose = (event) => {
    event.stopPropagation();
    close();
  };
  return (
    <>
      <Tooltip title="Configure" placement="top">
        <IconButton onClick={menuOpen}>
          <FontAwesomeIcon fixedWidth icon={faListAlt} />
        </IconButton>
      </Tooltip>
      <Popover
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={menuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box className={viewSettingsClasses.groupName}>View density</Box>
        <ViewsMenu viewsWithSelections={viewsWithSelections} />
        <Divider />
        <Box className={viewSettingsClasses.groupName}>Show columns</Box>
        <ShowColumnsMenu columnsWithSelections={columnsWithSelections} />
      </Popover>
    </>
  );
};

export default ViewSettingsMenu;
