import { DataRoomsCards } from "components";

const DataRooms = ({ as, ...rest }) => {
  switch (as) {
    case "cards":
    default:
      return <DataRoomsCards {...rest} />;
  }
};

DataRooms.Cards = (props) => <DataRoomsCards {...props} />;

export default DataRooms;
