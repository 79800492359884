import { Container, Typography } from "@material-ui/core";
import { Main } from "layouts";

const FourOFourPage = () => {
  return (
    <Main>
      <Container maxWidth={false}>
        <Typography variant="h6">
          <strong>404</strong>
        </Typography>
      </Container>
    </Main>
  );
};

export default FourOFourPage;
