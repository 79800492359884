import { alpha, makeStyles } from "@material-ui/core/styles";

export const useNameStyles = makeStyles((theme) => ({
  wrapperInner: {
    display: "flex",
    alignItems: "baseline",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    margin: theme.spacing(-0.5),
    transition: "all 0.2s linear",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
  },
  wrapperEditing: {
    display: "flex",
    alignItems: "center",
    backgroundColor: alpha(theme.palette.common.black, 0.025),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
  },
  name: {
    fontWeight: "bold",
    cursor: (props) => (props.isReadOnly ? "pointer" : "text"),
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  nameInput: {
    flex: 1,
  },
  id: {
    marginLeft: 6,
  },
}));
