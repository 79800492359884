import { faFileUpload } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  Slide,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useButtonStyles = makeStyles((theme) => ({
  root: {
    borderColor: theme.palette.text.disabled,
  },
}));

const useUnauthorizedDialogStyles = makeStyles((theme) => ({
  iconTitleWrapper: {
    padding: theme.spacing(3, 3, 2, 3),
  },
  iconWrapper: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  icon: {
    color: grey["400"],
  },
  title: {
    lineHeight: 1.25,
  },
  dialogContent: {
    padding: theme.spacing(0, 3, 0, 3),
  },
}));

const DataRoomUploadDataDialog = ({ open, onSelect, onClose }) => {
  const unauthorizedDialogClasses = useUnauthorizedDialogStyles();
  const buttonClasses = useButtonStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Slide}
      TransitionProps={{ direction: "up" }}
    >
      <Box className={unauthorizedDialogClasses.iconTitleWrapper}>
        <Box className={unauthorizedDialogClasses.iconWrapper}>
          <FontAwesomeIcon
            fixedWidth
            icon={faFileUpload}
            size="5x"
            className={unauthorizedDialogClasses.icon}
          />
        </Box>
        <Typography
          variant="h6"
          color="textPrimary"
          align="center"
          className={unauthorizedDialogClasses.title}
        >
          <strong>Connect data</strong>
        </Typography>
      </Box>
      <DialogContent className={unauthorizedDialogClasses.dialogContent}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gridGap: "1rem",
            padding: "1rem",
          }}
        >
          <div>
            <Button
              fullWidth
              classes={buttonClasses}
              color="inherit"
              variant="outlined"
              onClick={onClose}
            >
              Amazon S3
            </Button>
          </div>
          <div>
            <Button
              fullWidth
              classes={buttonClasses}
              color="inherit"
              variant="outlined"
              onClick={onClose}
            >
              GCP
            </Button>
          </div>
          <div>
            <Button
              fullWidth
              classes={buttonClasses}
              color="inherit"
              variant="outlined"
              onClick={onClose}
            >
              Azure DB
            </Button>
          </div>
          <div>
            <Button
              fullWidth
              classes={buttonClasses}
              color="inherit"
              variant="outlined"
              onClick={onClose}
            >
              Oracle
            </Button>
          </div>
          <div>
            <Button
              fullWidth
              classes={buttonClasses}
              color="inherit"
              variant="outlined"
              onClick={onSelect}
            >
              Local file system
            </Button>
          </div>
          <div>
            <Button
              fullWidth
              classes={buttonClasses}
              color="inherit"
              variant="outlined"
              onClick={onClose}
            >
              Others
            </Button>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DataRoomUploadDataDialog;
