import { useMutation } from "@apollo/client";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CircularProgress,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { CREATE_DATA_ROOM } from "gqls";

const useCircularProgressStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
  },
}));

const NewDataRoomButton = (props) => {
  const circularProgressClasses = useCircularProgressStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // Create dataRoom mutation
  const [createDataRoom, { loading: isCreateDataRoomLoading }] = useMutation(
    CREATE_DATA_ROOM,
    {
      onCompleted: (data) => {
        const { dataRoom } = data.createDataRoom;
        const { dataRoomId } = dataRoom;
        navigate(`/datarooms/${dataRoomId}`);
      },
      onError: (error) => {
        console.error("Something went wrong 😔", error);
        enqueueSnackbar("Can't create new dataRoom", { variant: "error" });
      },
      refetchQueries: ["dataRooms"],
    }
  );
  // Create dataRoom
  const onIconButtonClick = () => {
    const dataRoom = {
      name: "Untitled",
      description: "",
      tables: [],
      queries: [],
    };
    return createDataRoom({
      variables: {
        input: {
          dataRoom,
        },
      },
    });
  };
  return isCreateDataRoomLoading ? (
    <CircularProgress
      color="inherit"
      thickness={2}
      size="2rem"
      classes={circularProgressClasses}
      {...props}
    />
  ) : (
    <Tooltip title="Create a new data room" placement="bottom">
      <IconButton
        color="inherit"
        to="/datarooms/new"
        onClick={onIconButtonClick}
        {...props}
      >
        <FontAwesomeIcon fixedWidth icon={faPlus} />
      </IconButton>
    </Tooltip>
  );
};

export default NewDataRoomButton;
