import { makeStyles } from "@material-ui/core/styles";

export const useCustomTableStyles = makeStyles((theme) => ({
  MuiTableContainer: {
    "&.MuiTableContainer-root": {
      [theme.breakpoints.down("md")]: {
        "& $MuiTableCell": {
          flexBasis: "100%",
        },
      },
    },
  },
  MuiTableCell: {
    "&.MuiTableCell-root": {
      flexBasis: (props) => (props.forceWideCells ? "100%" : undefined),
    },
  },
  MuiTableCellAsEntity: {
    "&.MuiTableCell-root": {
      display: "block",
      padding: theme.spacing(0.25),
      border: "none",
      "&:not(:first-child)": {
        [theme.breakpoints.down("md")]: {
          marginTop: theme.spacing(-1.5),
        },
        marginTop: (props) =>
          props.forceWideCells ? theme.spacing(-1.5) : undefined,
      },
    },
  },
  columnLabel: {
    marginRight: theme.spacing(4),
  },
}));
