import { QueriesList, QueriesTable } from "legacy/components";

const Queries = ({ as, ...rest }) => {
  switch (as) {
    case "list":
      return <QueriesList {...rest} />;
    case "table":
    default:
      return <QueriesTable {...rest} />;
  }
};

Queries.List = (props) => <QueriesList {...props} />;
Queries.Table = (props) => <QueriesTable {...props} />;

export default Queries;
