import { makeStyles } from "@material-ui/core/styles";

export const useRadioboxStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

export const useFontAwesomeRadioboxStyles = makeStyles({
  label: {
    width: "1rem",
    height: "1rem",
    display: "flex",
    alignItems: "inherit",
    justifyContent: "inherit",
  },
});
