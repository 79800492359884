import { memo } from "react";
import FontAwesomeCheckbox from "./FontAwesomeCheckbox";
import FontAwesomeRadiobox from "./FontAwesomeRadiobox";

const Select = ({
  all,
  usedSelections,
  withMultiselections,
  withSelections,
  ...rest
}) => {
  const {
    toggle,
    isSelected,
    setSelected,
    allSelected,
    partiallySelected,
    toggleAll,
  } = usedSelections;
  return all ? (
    withMultiselections ? (
      <FontAwesomeCheckbox
        isSelected={allSelected}
        isIndeterminate={partiallySelected}
        select={toggleAll}
        {...rest}
      />
    ) : null
  ) : withMultiselections ? (
    <FontAwesomeCheckbox
      isSelected={isSelected}
      select={(id) => toggle(id)}
      {...rest}
    />
  ) : withSelections ? (
    <FontAwesomeRadiobox
      isSelected={isSelected}
      select={(id) => setSelected([id])}
      {...rest}
    />
  ) : null;
};

export default memo(Select);
