import {
  Box,
  Card,
  CardContent,
  Link,
  makeStyles,
  Skeleton,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { DataRoomActions, Status } from "components";

export const useCardClasses = makeStyles((theme) => ({
  root: {
    height: "100%",
    border: "1px solid transparent",
    transitionProperty: "all",
    "&:not(:hover)": {
      boxShadow: "none",
      border: "1px solid rgba(0,0,0,0.12)",
    },
  },
}));

const useLinkClasses = makeStyles((theme) => ({
  root: {
    transition: "all 0.2s ease-in-out",
    "&:not(:hover)": {
      color: "inherit",
    },
  },
}));

const useSkeletonStyles = makeStyles((theme) => ({
  rectangular: {
    borderRadius: theme.shape.borderRadius,
  },
}));

const DataRoomCard = ({ loading = false, dataRoom = {} }) => {
  const cardClasses = useCardClasses();
  const linkClasses = useLinkClasses();
  const skeletonClasses = useSkeletonStyles();
  const { id, dataRoomId, name, description, isPublished, updatedAt, isOwner } =
    dataRoom || {};
  return loading ? (
    <Card elevation={12} classes={cardClasses}>
      <CardContent style={{ height: "100%" }}>
        <Box sx={{ marginBottom: "5.6px" }}>
          <Skeleton
            classes={skeletonClasses}
            variant="rectangular"
            height={25.25}
            width="100%"
          />
        </Box>
        <Box sx={{ marginBottom: "8.4px" }}>
          <Skeleton
            classes={skeletonClasses}
            variant="rectangular"
            height={32}
            width="100%"
          />
        </Box>
        <Box sx={{ marginTop: "4.9px" }}>
          <Skeleton
            classes={skeletonClasses}
            variant="rectangular"
            height={20}
            width="100%"
          />
        </Box>
      </CardContent>
    </Card>
  ) : (
    <Card elevation={12} classes={cardClasses}>
      <CardContent>
        <Box sx={{ marginBottom: "0.35em" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Status isPublished={isPublished} updatedAt={updatedAt} />
            <DataRoomActions name={name} inline id={id} isOwner={isOwner} />
          </Box>
        </Box>
        <Typography variant="h6" component="h2" gutterBottom>
          <Link
            component={RouterLink}
            color="secondary"
            classes={linkClasses}
            to={`/datarooms/${dataRoomId}`}
            underline="none"
            style={{
              fontWeight: 700,
              letterSpacing: "-0.5px",
            }}
          >
            {name}
          </Link>
        </Typography>
        <Typography variant="subtitle2" component="div" gutterBottom>
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DataRoomCard;
