import { useMutation } from "@apollo/client";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Portal } from "@material-ui/core";
import { useBoolean } from "ahooks";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Actions, DataRoomDeleteDialog } from "components";
import { DELETE_DATA_ROOM_BY_ID } from "gqls";

const DataRoomActions = ({
  name,
  id,
  inline = false,
  filter = ({ isAvailable }) => isAvailable,
  isOwner,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  // Delete
  const [
    isDeleteDialogOpen,
    { setTrue: openDeleteDialog, setFalse: closeDeleteDialog },
  ] = useBoolean(false);
  const [
    deleteDataRoomById,
    { loading: isDeleteDataRoomByIdLoading },
  ] = useMutation(DELETE_DATA_ROOM_BY_ID, {
    variables: {
      input: {
        id,
      },
    },
    update: (cache) => {
      cache.modify({
        fields: {
          dataRooms: (existingDataRooms = { nodes: [] }) => ({
            nodes: existingDataRooms.nodes.filter(
              (dataRoom) => dataRoom.id !== id
            ),
          }),
        },
      });
    },
    onCompleted: () => {
      closeDeleteDialog();
      navigate("/datarooms");
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar("Can't delete dataRoom 😔", { variant: "error" });
    },
    refetchQueries: ["dataRooms"],
  });
  const actions = [
    {
      icon: <FontAwesomeIcon fixedWidth icon={faTrashAlt} />,
      title: "Delete this data room",
      handle: openDeleteDialog,
      isPrimary: true,
      isEnabled: true,
      isRed: true,
      isAvailable: isOwner,
    },
  ].filter(filter);
  return (
    <Actions actions={actions} inline={inline}>
      <Portal>
        <DataRoomDeleteDialog
          name={name}
          open={isDeleteDialogOpen}
          loading={isDeleteDataRoomByIdLoading}
          onCancel={closeDeleteDialog}
          onConfirm={deleteDataRoomById}
        />
      </Portal>
    </Actions>
  );
};

export default DataRoomActions;
