import {
  DataRoomsTableHeadRow,
  DataRoomTableBodyRow,
  DataTable,
} from "legacy/components";

const views = [
  {
    name: "compact",
    label: "Compact",
    isDisabled: false,
  },
  {
    name: "detailed",
    label: "Detailed",
    info: "Includes meta info and tags",
    isDisabled: false,
    isSelected: true,
  },
];

const columns = [
  {
    name: "dataRoom",
    label: "DataRoom",
    isDisabled: true,
    isSelected: true,
  },
  {
    name: "owner",
    label: "Owner",
    isDisabled: false,
    isSelected: true,
  },
  {
    name: "participants",
    label: "Participants",
    isDisabled: false,
    isSelected: true,
  },
];

const DataRoomsTable = (props) => (
  <DataTable
    views={views}
    columns={columns}
    TableHeadRow={DataRoomsTableHeadRow}
    TableBodyRow={DataRoomTableBodyRow}
    {...props}
  />
);

export default DataRoomsTable;
