import { TableCell, TableRow } from "@material-ui/core";
import clsx from "clsx";
import { ContactBlock, DataRoomActions, QueryBlock } from "legacy/components";
import { useDataRoomsTableCellStyles } from "legacy/components/dataRooms/DataRoomsTable/DataRoomsTable.styles";
import { useCustomTableStyles } from "styles/table";

const QueryTableBodyRow = ({
  Select,
  node: query,
  node: { id, queryId, sql },
  columnsWithSelections,
  viewsWithSelections,
  setCondition = () => {},
  mergeCondition = () => {},
  style,
  forceWideCells = false,
  withActions = true,
}) => {
  const customTableClasses = useCustomTableStyles({ forceWideCells });
  const dataRoomsTableCellClasses = useDataRoomsTableCellStyles();
  const { contact } = query || {};
  const onClickStopPropagation = (event) => event.stopPropagation();
  const { isSelected } = columnsWithSelections;
  const { selected } = viewsWithSelections;
  const withMeta = selected.includes("detailed");
  return (
    <TableRow component="div" onClick={onClickStopPropagation}>
      {Select && (
        <TableCell component="div" padding="checkbox">
          <Select id={queryId} />
        </TableCell>
      )}
      <TableCell component="div" padding="none">
        {isSelected("dataRoom") && (
          <TableCell
            component="div"
            className={clsx(
              dataRoomsTableCellClasses.dataRoomWrapper,
              customTableClasses.MuiTableCell,
              customTableClasses.MuiTableCellAsEntity
            )}
          >
            <QueryBlock query={query} withMeta={withMeta} withTags={withMeta} />
          </TableCell>
        )}
        {isSelected("owner") && (
          <TableCell
            component="div"
            className={clsx(
              dataRoomsTableCellClasses.contactWrapper,
              customTableClasses.MuiTableCell,
              customTableClasses.MuiTableCellAsEntity
            )}
          >
            <ContactBlock
              contact={contact}
              withMeta={withMeta}
              withTags={withMeta}
            />
          </TableCell>
        )}
        {isSelected("participants") && (
          <TableCell
            component="div"
            className={clsx(
              dataRoomsTableCellClasses.contactWrapper,
              customTableClasses.MuiTableCell,
              customTableClasses.MuiTableCellAsEntity
            )}
          >
            <ContactBlock
              contact={contact}
              withMeta={withMeta}
              withTags={withMeta}
            />
          </TableCell>
        )}
      </TableCell>
      {withActions && (
        <TableCell component="div" padding="checkbox">
          <DataRoomActions id={id} queryId={queryId} />
        </TableCell>
      )}
    </TableRow>
  );
};

export default QueryTableBodyRow;
