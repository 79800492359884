import { makeStyles } from "@material-ui/core";

export const usePaginationStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(0.25),
  },
}));

export const usePaginationItemStyles = makeStyles((theme) => ({
  sizeSmall: {
    height: theme.spacing(3),
    minWidth: theme.spacing(3),
    marginLeft: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
  },
}));

export const usePaginationBlockStyles = makeStyles((theme) => ({
  wrapper: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  paginationControls: {
    display: "flex",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "auto",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.2s linear",
    "&:hover": {
      background: "rgba(0,0,0,0.05)",
      "& $actionsWrapper": {
        opacity: 1,
      },
    },
  },
  refetchButton: {
    fontSize: "0.875rem",
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
  },
}));
