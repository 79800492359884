import { dataRoomMode } from "constants/index";
import { Formik } from "formik";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { DataRoomForm, DataRoomFormAutoSave } from "components";
import { dataRoomInitialValues } from "containers/DataRoom/DataRoomInitialValues";

const defaultFormOptions = {
  isEditing: false,
  isViewing: false,
  canSubmit: false,
  hasResult: false,
};

const useFormOptions = (mode = dataRoomMode.UPDATE, hasSubmitted, isOwner) => {
  const getFormOptions = useCallback(
    (mode) => ({
      ...defaultFormOptions,
      isEditing:
        mode === dataRoomMode.UPDATE ||
        !Object.values(dataRoomMode).includes(mode),
      canSubmit: mode === dataRoomMode.SUBMIT && !hasSubmitted,
      hasResult: mode === dataRoomMode.RESULTS,
      isOwner,
    }),
    [hasSubmitted, isOwner]
  );
  const [formOptions, setFormOptions] = useState(
    getFormOptions(mode, hasSubmitted)
  );
  useEffect(() => {
    setFormOptions(getFormOptions(mode, hasSubmitted));
  }, [mode, hasSubmitted, getFormOptions]);
  return formOptions;
};

const DataRoomFormik = memo(
  ({
    mode = dataRoomMode.UPDATE,
    dataRoom = {},
    answersState,
    ingestions,
    results,
    hasSubmitted,
    isOwner,
    validationSchema,
    onChange,
    onSubmit,
    //
    onImportCsvClick,
    onRunQueryClick,
  }) => {
    const { name = "", description = "", tables = [], queries = [] } =
      dataRoom || dataRoomInitialValues;
    // Initial status
    const initialStatus = useFormOptions(mode, hasSubmitted, isOwner);
    const { isEditing } = initialStatus;
    // Initial values
    const initialValues = useMemo(
      () => ({
        name,
        description,
        tables,
        queries,
        password: "",
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );
    return (
      <Formik
        initialStatus={initialStatus}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        validateOnMount={mode === dataRoomMode.UPDATE}
        onSubmit={onSubmit}
      >
        <DataRoomForm
          dataRoom={dataRoom}
          isOwner={isOwner}
          hasSubmitted={hasSubmitted}
          ingestions={ingestions}
          results={results}
          answersState={answersState}
          onImportCsvClick={onImportCsvClick}
          onRunQueryClick={onRunQueryClick}
        >
          {isEditing && typeof onChange === "function" ? (
            <DataRoomFormAutoSave onChange={onChange} />
          ) : null}
        </DataRoomForm>
      </Formik>
    );
  }
);

export default DataRoomFormik;
