import { dataRoomMode } from "constants/index";
import { makeStyles } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";

const useInputLabelStyles = makeStyles((theme) => ({
  asterisk: {
    fontSize: "1.25em",
    lineHeight: "50%",
    color: "red",
  },
}));

const useInputStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 0),
  },
  multiline: {
    padding: theme.spacing(0.5, 0),
  },
  input: {
    fontSize: "1rem",
    lineHeight: "1.25rem !important",
    minHeight: "1.25rem !important",
    padding: 0,
  },
}));

const DataRoomFormDataRoomMinimumResponsesField = () => {
  const {
    status: { mode },
  } = useFormikContext();
  const inputLabelClasses = useInputLabelStyles();
  const inputClasses = useInputStyles();
  return (
    <Field
      id="dataRoom-minimum-responses"
      name={`dataRoomMinimumResponses`}
      component={TextField}
      variant="standard"
      color="secondary"
      fullWidth
      label="Min. submissions"
      InputLabelProps={{
        htmlFor: "dataRoom-minimum-responses",
        required: [dataRoomMode.UPDATE].includes(mode),
        shrink: true,
        style: { whiteSpace: "nowrap" },
        disabled: ![dataRoomMode.UPDATE].includes(mode),
        classes: inputLabelClasses,
      }}
      InputProps={{
        type: "number",
        disableUnderline: ![dataRoomMode.UPDATE].includes(mode),
        readOnly: ![dataRoomMode.UPDATE].includes(mode),
        // disabled: ![dataRoomMode.UPDATE].includes(mode),
        disabled: true,
        classes: inputClasses,
      }}
    />
  );
};

export default DataRoomFormDataRoomMinimumResponsesField;
