import { gql } from "@apollo/client";

export const QUERIES = gql`
  query queries(
    $filter: QueryFilter
    $first: Int
    $offset: Int
    $orderBy: [QueriesOrderBy!]
  ) {
    queries(
      first: $first
      offset: $offset
      orderBy: $orderBy
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        queryId
        dataRoomId
        sql
        updatedAt
        createdAt
        ownerId
      }
    }
  }
`;
