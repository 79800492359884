import { gql } from "@apollo/client";
import { DATA_ROOM_EVERYTHING } from "gqls";

export const CREATE_DATA_ROOM = gql`
  mutation createDataRoom($input: CreateDataRoomInput!) {
    createDataRoom(input: $input) {
      clientMutationId
      dataRoom {
        ...DataRoomEverything
      }
    }
  }
  ${DATA_ROOM_EVERYTHING}
`;
