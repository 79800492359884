import { useMutation } from "@apollo/client";
import { faCirclePlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardContent,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { CREATE_DATA_ROOM } from "gqls";
import { useCardClasses } from "../DataRoomCard/DataRoomCard";

const useCircularProgressStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
  },
}));

const NewDataRoomCard = () => {
  const cardClasses = useCardClasses();
  const circularProgressClasses = useCircularProgressStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // Create dataRoom mutation
  const [createDataRoom, { loading: isCreateDataRoomLoading }] = useMutation(
    CREATE_DATA_ROOM,
    {
      onCompleted: (data) => {
        const { dataRoom } = data.createDataRoom;
        const { dataRoomId } = dataRoom;
        navigate(`/datarooms/${dataRoomId}`);
      },
      onError: (error) => {
        console.error("Something went wrong 😔", error);
        enqueueSnackbar("Can't create new dataRoom", { variant: "error" });
      },
      refetchQueries: ["dataRooms"],
    }
  );

  const onCardClick = () => {
    const dataRoom = {
      name: "Untitled",
      description: "",
      tables: [],
      queries: [],
    };
    return createDataRoom({
      variables: {
        input: {
          dataRoom,
        },
      },
    });
  };

  return (
    <Card
      onClick={onCardClick}
      style={{ height: "100%", cursor: "pointer" }}
      elevation={12}
      classes={cardClasses}
    >
      <CardContent
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isCreateDataRoomLoading ? (
          <CircularProgress
            color="inherit"
            thickness={2}
            size="2rem"
            classes={circularProgressClasses}
          />
        ) : (
          <>
            <FontAwesomeIcon
              size="2x"
              fixedWidth
              icon={faCirclePlus}
            ></FontAwesomeIcon>
            <Typography variant="h6" component="h2">
              Create a new data room
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default NewDataRoomCard;
