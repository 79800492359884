import { Button, Menu, MenuItem } from "@material-ui/core";
import { useState } from "react";

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

const PageSizeMenu = ({ pageSize, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = (event) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);
  const optionClick = (pageSizeOption) => {
    onChange(pageSizeOption);
    setAnchorEl(null);
  };
  return (
    <>
      <Button onClick={openMenu}>{pageSize}</Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
        {PAGE_SIZE_OPTIONS.map((pageSizeOption) => (
          <MenuItem
            key={pageSizeOption}
            selected={pageSize === pageSizeOption}
            onClick={optionClick.bind(null, pageSizeOption)}
          >
            {pageSizeOption}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default PageSizeMenu;
