import { dataRoomMode } from "constants/index";
import { useMutation, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  DisWaConnector,
  Key,
} from "@decentriq/safequery/lib/safequery-client/src/api";
import {
  gqlToSafeQuery,
  SafeQuery,
} from "@decentriq/safequery/lib/safequery-client/src/safequery";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Backdrop,
  CircularProgress,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { useDebounceFn } from "ahooks";
import { isBefore, parseISO } from "date-fns";
import omit from "lodash/omit";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DataRoomFormik } from "components";
import { DATA_ROOM, UPDATE_DATA_ROOM } from "gqls";
import { dataRoomValidationSchema } from "./DataRoomValidationSchemas";

const getClient = async (email, token, encryptionKey, password) => {
  const client = DisWaConnector.withUserToken(
    token,
    email,
    encryptionKey,
    process.env["REACT_APP_BACKEND_HOST"],
    parseInt(process.env["REACT_APP_BACKEND_PORT"]),
    process.env["REACT_APP_USE_SSL"] === "true"
  );
  // TODO: remove verificationOptions in production
  await client.connect(
    {
      verificationOptions: {
        acceptDebug: true,
        acceptGroupOutOfDate: true,
        acceptConfigurationNeeded: true,
      },
    },
    password
  );
  return client;
};

const prettifyPassword = (password) => {
  if (!password) {
    return null;
  }
  const trimmedPassword = password.trim();
  if (trimmedPassword) {
    return trimmedPassword;
  }
  return null;
};

const useBackdropStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.tooltip + 1,
  },
}));

const closeSnackbarAction = (close) => (key) => (
  <IconButton onClick={() => close(key)}>
    <FontAwesomeIcon fixedWidth icon={faTimes} color="white"></FontAwesomeIcon>
  </IconButton>
);

const DataRoom = () => {
  const backdropClasses = useBackdropStyles();
  const navigate = useNavigate();
  const { dataRoomId } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user, getAccessTokenSilently } = useAuth0();
  const { email: currentUserEmail } = user;
  // Fetch dataRoom from the database
  const { data, loading: isDataRoomLoading, error } = useQuery(DATA_ROOM, {
    variables: {
      dataRoomId,
    },
    onError: (error) => {
      console.error("Something went wrong", error);
      enqueueSnackbar("Can't fetch dataRoom from the database", {
        variant: "error",
      });
    },
  });
  const { dataRoom } = data || {};
  const {
    submissionEnd,
    dataRoomShares = [],
    isPublished = false,
    isOwner,
    isParticipant,
  } = dataRoom || {};
  // Navigate away if dataRoom wasn't found
  if (!isDataRoomLoading && !dataRoom) {
    navigate("/");
  }
  const dataRoomShare = dataRoomShares?.nodes?.find(
    ({ userEmail }) => userEmail === currentUserEmail
  );
  const { hasSubmitted = false } = dataRoomShare || {};
  const submissionEndDate = parseISO(submissionEnd);
  const currentDate = new Date();
  // Figure out dataRoom mode
  const mode = isPublished
    ? isParticipant
      ? isBefore(currentDate, submissionEndDate)
        ? dataRoomMode.SUBMIT
        : dataRoomMode.RESULTS
      : isOwner
      ? dataRoomMode.RESULTS
      : undefined // So it's published, but you are not a participant or an owner? Nah, can't be
    : isOwner
    ? dataRoomMode.UPDATE
    : undefined; // So it's not yet published, but you are not an owner? Nah, can't be
  // Navigate away if tries to edit published dataRoom or not an owner or any other unrecognized situation
  useEffect(() => {
    if (!isDataRoomLoading && mode === undefined) navigate("/");
  }, [isDataRoomLoading, mode, navigate]);
  // How do I get this safeQuery instance again?
  const setSafeQueryInstance = useState()[1];
  // Update dataRoom mutation
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [updateDataRoomMutation] = useMutation(UPDATE_DATA_ROOM, {
    onError: (error) => {
      console.error("Something went wrong", error);
      enqueueSnackbar("Can't update dataRoom", { variant: "error" });
    },
    refetchQueries: ["dataRooms"],
  });
  // Update data room
  const updateDataRoom = useCallback(
    (values) => {
      const patch = {
        ...omit(values, "password"),
      };
      const dataRoomShares = [
        ...new Set(
          [
            (patch.tables || []).reduce(
              (memo, { dataProviders }) => [...memo, ...(dataProviders || [])],
              []
            ),
            (patch.queries || []).reduce(
              (memo, { dataAnalysts }) => [...memo, ...(dataAnalysts || [])],
              []
            ),
          ].flat()
        ),
      ];
      if (dataRoomShares.length > 0) {
        patch.dataRoomShares = {
          deleteOthers: true,
          create: dataRoomShares.map((userEmail) => ({
            userEmail,
          })),
        };
      }
      if (patch.answers) {
        delete patch.answers;
      }
      return updateDataRoomMutation({
        variables: {
          input: {
            patch,
            dataRoomId,
          },
        },
      });
    },
    [dataRoomId, updateDataRoomMutation]
  );
  // Debounced updateDataRoom
  const { run: debouncedUpdateDataRoom } = useDebounceFn(updateDataRoom, {
    wait: 2000,
    leading: true,
    trailing: true,
  });
  // Publish data room
  const publishDataRoom = useCallback(
    async (values) => {
      const {
        name,
        description,
        queries,
        tables,
        password: rawPassword,
      } = values;
      setIsPublishLoading(true);
      const password = prettifyPassword(rawPassword);
      try {
        const safeQuery = await SafeQuery.create(
          {
            id: dataRoomId,
            title: name,
            description,
            tables,
            queries,
          },
          await getClient(
            user.email,
            await getAccessTokenSilently(),
            await Key.create(),
            password
          ),
          false,
          password
        );
        if (safeQuery.dataRoomHash !== undefined) {
          await updateDataRoomMutation({
            variables: {
              input: {
                patch: {
                  dataRoomHash: safeQuery.dataRoomHash.toString(),
                  hasPassword: !!password,
                },
                dataRoomId,
              },
            },
          }).then(() => setIsPublishLoading(false));
        }
        setSafeQueryInstance(safeQuery);
        return safeQuery;
      } catch (error) {
        console.error("Something went wrong", error);
        enqueueSnackbar(
          [
            error.message,
            error.tableIndex !== null && error.tableIndex !== undefined
              ? `Table index: ${error.tableIndex}`
              : null,
            error.columnIndex !== null && error.columnIndex !== undefined
              ? `Column index: ${error.columnIndex}`
              : null,
            error.queryIndex !== null && error.queryIndex !== undefined
              ? `Query index: ${error.queryIndex}`
              : null,
            error.roleIndex !== null && error.roleIndex !== undefined
              ? `Role index: ${error.roleIndex}`
              : null,
            error.permissionIndex !== null &&
            error.permissionIndex !== undefined
              ? `Permission index: ${error.permissionIndex}`
              : null,
            error.constraintIndex !== null &&
            error.constraintIndex !== undefined
              ? `Contsraint index: ${error.constraintIndex}`
              : null,
          ]
            .filter(Boolean)
            .join(", "),
          {
            variant: "error",
            persist: true,
            action: closeSnackbarAction(closeSnackbar),
          }
        );
      } finally {
        setIsPublishLoading(false);
      }
    },
    [
      dataRoomId,
      enqueueSnackbar,
      closeSnackbar,
      getAccessTokenSilently,
      updateDataRoomMutation,
      user.email,
      setSafeQueryInstance,
    ]
  );
  // Import CSV
  const [ingestions, setIngestions] = useState([]);
  const onImportCsvClick = async (
    tableDefinition,
    tableIndex,
    password,
    event
  ) => {
    const input = document.createElement("input");
    input.type = "file";
    input.onchange = (event) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const content = event.target.result;
        try {
          setIngestions((ingestions) => {
            const newIngestions = [...ingestions];
            newIngestions[tableIndex] = {
              loading: true,
              error: null,
            };
            return newIngestions;
          });
          const safeQueryInstance = gqlToSafeQuery(
            dataRoom,
            await getClient(
              user.email,
              await getAccessTokenSilently(),
              await Key.create(),
              prettifyPassword(password)
            )
          );
          await safeQueryInstance.ingestData(tableDefinition, content);
          setIngestions((ingestions) => {
            const newIngestions = [...ingestions];
            newIngestions[tableIndex] = {
              loading: false,
              error: null,
            };
            return newIngestions;
          });
          enqueueSnackbar("Data ingested successfully");
        } catch (error) {
          setIngestions((ingestions) => {
            const newIngestions = [...ingestions];
            newIngestions[tableIndex] = {
              loading: false,
              error: error.message,
            };
            return newIngestions;
          });
          console.error(error);
          enqueueSnackbar(`Can't ingest data: ${error.message}`, {
            variant: "error",
            persist: true,
            action: closeSnackbarAction(closeSnackbar),
          });
        }
      };
      const file = event.target.files[0];
      reader.readAsText(file);
      event.target.value = "";
    };
    input.click();
  };
  // Run query
  const [results, setResults] = useState([]);
  const onRunQueryClick = async (
    queryDefinition,
    queryIndex,
    password,
    event
  ) => {
    setResults((results) => {
      const newResults = [...results];
      newResults[queryIndex] = {
        loading: true,
        data: null,
        error: null,
      };
      return newResults;
    });
    try {
      const safeQueryInstance = gqlToSafeQuery(
        dataRoom,
        await getClient(
          user.email,
          await getAccessTokenSilently(),
          await Key.create(),
          prettifyPassword(password)
        )
      );
      const result = await safeQueryInstance.runQuery(queryDefinition.name);
      setResults((results) => {
        const newResults = [...results];
        newResults[queryIndex] = {
          loading: false,
          data: result,
          error: null,
        };
        return newResults;
      });
    } catch (error) {
      setResults((results) => {
        const newResults = [...results];
        newResults[queryIndex] = {
          loading: false,
          data: null,
          error: error.message,
        };
        return newResults;
      });
      console.error(error);
      enqueueSnackbar(`Can't run query: ${error.message}`, {
        variant: "error",
        persist: true,
        action: closeSnackbarAction(closeSnackbar),
      });
    }
  };
  useEffect(() => {
    setResults((results) => (results?.length ? [] : results));
    setIngestions((ingestions) => (ingestions?.length ? [] : ingestions));
  }, [dataRoomId]);
  const onChange =
    mode === dataRoomMode.UPDATE ? debouncedUpdateDataRoom : undefined;
  // Figure out validation schema based on mode
  const validationSchema = dataRoomMode.UPDATE
    ? dataRoomValidationSchema
    : mode === dataRoomMode.SUBMIT
    ? dataRoomValidationSchema
    : undefined;
  // Figure out form actions based on mode
  const onSubmit =
    mode === dataRoomMode.UPDATE
      ? publishDataRoom
      : mode === dataRoomMode.SUBMIT
      ? publishDataRoom
      : undefined;
  // Render
  const loading = isDataRoomLoading || isPublishLoading;
  return loading ? (
    <Backdrop classes={backdropClasses} open={loading} invisible>
      <CircularProgress color="inherit" thickness={1} size="2.5rem" />
    </Backdrop>
  ) : error ? null : (
    <DataRoomFormik
      mode={mode}
      dataRoom={dataRoom}
      ingestions={ingestions}
      results={results}
      isOwner={isOwner}
      hasSubmitted={hasSubmitted}
      validationSchema={validationSchema}
      onChange={onChange}
      onSubmit={onSubmit}
      // 😏
      onImportCsvClick={onImportCsvClick}
      onRunQueryClick={onRunQueryClick}
    />
  );
};

export default DataRoom;
