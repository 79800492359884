import { gql } from "@apollo/client";

export const EVENTS = gql`
  query events {
    events {
      nodes {
        timestamp
        eventLevel
        eventType
        eventMessage
      }
    }
  }
`;
