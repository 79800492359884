import { gql } from "@apollo/client";

export const EVENTS_SUBSCRIPTION = gql`
  subscription events {
    events {
      nodes {
        timestamp
        eventLevel
        eventType
        eventMessage
      }
    }
  }
`;
