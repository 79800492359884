import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "components";
import {
  AboutPage,
  DataRoomPage,
  DataRoomsPage,
  FourOFourPage,
  HomePage,
} from "pages";

const PagesWrapper = ({ children }) => {
  return (
    <Routes>
      <PrivateRoute path="/datarooms/:dataRoomId" element={<DataRoomPage />} />
      <PrivateRoute path="/datarooms" element={<DataRoomsPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/" element={<HomePage />} />
      <Route path="*" element={<FourOFourPage />} />
    </Routes>
  );
};

export default PagesWrapper;
