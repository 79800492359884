import { makeStyles } from "@material-ui/core/styles";

export const useMetaGroupStyles = makeStyles((theme) => ({
  metaGroup: {
    display: "inline-flex",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    height: "auto",
  },
}));
