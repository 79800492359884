import { Queries } from "legacy/components";
import { Data } from "legacy/containers";
import { QUERIES } from "gqls";

const QueriesContainer = ({
  filterBy = ["name"],
  dataIdKey = "queryId",
  dataKey = "queries",
  query = QUERIES,
  variables = {
    orderBy: ["NATURAL"],
  },
  ...rest
}) => (
  <Data
    DataView={Queries}
    filterBy={filterBy}
    dataIdKey={dataIdKey}
    dataKey={dataKey}
    query={query}
    variables={variables}
    {...rest}
  />
);

const QueriesContainerList = (props) => (
  <QueriesContainer {...props} DataView={Queries.List} />
);

const QueriesContainerSidebar = (props) => (
  <QueriesContainer
    {...props}
    DataView={Queries.List}
    views={[
      {
        name: "compact",
        label: "Compact",
        isDisabled: true,
        isSelected: true,
      },
      {
        name: "detailed",
        label: "Detailed",
        info: "Includes meta info and tags",
        isDisabled: true,
        isSelected: false,
      },
    ]}
    columns={[
      {
        name: "dataRoom",
        label: "DataRoom",
        isDisabled: true,
        isSelected: true,
      },
      {
        name: "owner",
        label: "Owner",
        isDisabled: true,
        isSelected: false,
      },
      {
        name: "participants",
        label: "Participants",
        isDisabled: true,
        isSelected: false,
      },
    ]}
    withSelections={true}
    withMultiselections={true}
    withActions={false}
  />
);

QueriesContainer.List = QueriesContainerList;
QueriesContainer.Sidebar = QueriesContainerSidebar;

export default QueriesContainer;
