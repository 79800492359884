import { queryKind } from "constants/index";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, Grid, makeStyles, Skeleton } from "@material-ui/core";
import { memo } from "react";

const useSkeletonStyles = makeStyles((theme) => ({
  rectangular: {
    borderRadius: theme.shape.borderRadius,
  },
}));

const QueryResultLoading = memo(({ kind }) => {
  const skeletonClasses = useSkeletonStyles();
  return (
    <Box>
      {kind === queryKind.CATEGORICAL && (
        <Box
          sx={{
            mt: 2,
            mb: 2,
            height: 320,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Skeleton
            classes={skeletonClasses}
            variant="circular"
            height={243}
            width={243}
          />
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {[...Array(Math.floor(Math.random() * (6 - 2 + 1) + 2)).keys()].map(
              (index) => (
                <Box key={index} sx={{ pl: 1, pr: 1, pt: 1 }}>
                  <Skeleton
                    classes={skeletonClasses}
                    variant="rectangular"
                    height={15}
                    width={Math.floor(Math.random() * (100 - 30 + 1) + 30)}
                  />
                </Box>
              )
            )}
          </Box>
        </Box>
      )}
      {kind === queryKind.NUMERICAL && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Grid container justifyContent="center" spacing={4}>
            {[...Array(Math.floor(Math.random() * (5 - 2 + 1) + 2)).keys()].map(
              (index) => (
                <Grid item key={index}>
                  <Box>
                    <Skeleton
                      classes={skeletonClasses}
                      variant="rectangular"
                      height={32}
                      width={Math.floor(Math.random() * (100 - 60 + 1) + 60)}
                    />
                  </Box>
                  <Box sx={{ marginTop: "2px" }}>
                    <Skeleton
                      classes={skeletonClasses}
                      variant="rectangular"
                      height={14}
                      width={Math.floor(Math.random() * (100 - 30 + 1) + 30)}
                    />
                  </Box>
                </Grid>
              )
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
});

const QueryResult = memo(({ queryIndex, kind, result }) => {
  const { loading = false, data = null, error = null } = result || {};
  return loading ? (
    <QueryResultLoading kind={kind} />
  ) : error ? (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Alert
        icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
        severity="warning"
      >
        {error}
      </Alert>
    </Box>
  ) : data ? (
    <Box sx={{ mt: 2, mb: 2 }}>
      <textarea defaultValue={result?.data} />
    </Box>
  ) : null;
});

export default QueryResult;
