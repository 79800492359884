import { makeStyles } from "@material-ui/core/styles";

export const useMetaStyles = makeStyles((theme) => ({
  meta: {
    textDecoration: "none",
    color: "inherit",
    display: "inline-flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    "&:hover > $text": {
      opacity: 1,
    },
    "&:not(:last-child)": {
      marginRight: theme.spacing(0.5),
    },
    "&:not(:last-child)::after": {
      content: "'•'",
      marginLeft: theme.spacing(0.5),
      fontSize: "2em",
      lineHeight: 0,
      opacity: 0.25,
    },
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.125em",
    marginRight: theme.spacing(0.5),
  },
  text: {
    cursor: "default",
    display: "inline-flex",
    transition: "all 0.2s linear",
    opacity: 0.33,
    padding: theme.spacing(0.25, 0),
  },
  pill: {
    cursor: "pointer",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.25, 0.5),
    background: "rgba(0,0,0,0.05)",
    transition: "all 0.2s linear",
    "&:hover": {
      background: "white",
    },
  },
  value: {
    marginLeft: theme.spacing(0.25),
    fontWeight: "bold",
  },
}));
